import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/system/Stack";
//import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

import {
  Section,
  Card,
  LoadingIndicator,
  //StatusDueDiligenceTable,
  StatusBaseInfoTable,
} from "../../elements/frontend/src/components";

//import { TableWrapper } from "./styled.status";
import TConfig from "../../config";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { useParams } from "react-router-dom";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";
//import { SupervisorNotices } from "../../elements/frontend/src/components/SupervisorNotices/SupervisorNotices";
import { Messenger } from "../../elements/frontend/src/components/Messenger/Messenger";
import { ContactTable } from "../../elements/frontend/src/components/ContactTable/ContactTable";

const Status = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();
  const params = useParams();
  const transactionId = params.transactionId;
  const transactionByIdQuery = useGetTransactionByIdQuery({
    transactionId: transactionId,
  });
  const transaction = transactionByIdQuery.data;
  const isTransactionLoading = transactionByIdQuery.isLoading;

  const [updateTransaction] = useUpdateTransactionMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateTransactionState = async (newTransaction) => {
    await updateTransaction({
      transactionId: newTransaction.transaction_id,
      body: newTransaction,
    });
    dispatch(setCurrentUser({ loading: false }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
        margin: "auto",
      }}
    >
      <Section title={t("platform/common:content.status.headline")}>
        <Card>
          {isTransactionLoading && <LoadingIndicator type={"COMPONENT"} />}
          {!isTransactionLoading && (
            <>
              {/* 1st Box */}
              <Box
                sx={{
                  boxShadow: `2px 2px 4px ${theme.palette.grey[300]}`,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "5px",
                  padding: "15px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    variant="h5"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "1rem", md: "1.3rem" },
                      color: theme.palette.info.dark,
                    }}
                  >
                    {transaction?.client_name}
                  </Typography>
                  <Button
                    variant="contained"
                    color="success"
                    href={
                      TConfig.shared_links.PIPEDRIVE_URL +
                      transaction?.client_contact?.pipedrive_deal_id
                    }
                    target="_blank"
                    rel="noreferrer"
                    disabled={!transaction?.client_contact?.pipedrive_deal_id}
                    endIcon={<SendIcon fontSize="small" />}
                    sx={{ fontWeight: "bold", fontSize: "13px", padding: "5px 10px" }}
                  >
                    {t("transaction_tables.pipedrive")}
                  </Button>
                </Stack>
                <Grid container>
                  <Grid item xs={12} lg={6} maxWidth={"70%"}>
                    <Stack
                      sx={{
                        margin: { xs: "0 0 10px 0", lg: "0 10px 0 0" },
                      }}
                    >
                      {/* Company Contact Table */}
                      <ContactTable contactData={transaction?.client_contact} />
                      {/* Due Diligence Table */}
                      <StatusBaseInfoTable
                        statusOffer={true}
                        transactionId={transaction.transaction_id}
                        updateTransactionState={updateTransactionState}
                      />
                      {/*  <StatusDueDiligenceTable /> */}
                      {/* Stammdaten Table */}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6} maxWidth={"20rem"}>
                    <Stack
                      sx={{
                        paddingTop: "10px",
                        flex: 1,
                        margin: { xs: "0 0 0 0", lg: "0 0 0 10px" },
                        height: "100%",
                      }}
                      alignItems="stretch"
                    >
                      <Messenger
                        statusOffer={true}
                        transactionId={transactionId}
                        productId="advisor"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              {/* 2nd Box */}

              {/*  <Box
                sx={{
                  boxShadow: `2px 2px 4px ${theme.palette.grey[300]}`,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "5px",
                  padding: "15px",
                  marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid red",
                  }}
                >
                  <TableWrapper>
                    <thead>
                      <tr>
                        <th>Investor</th>
                        <th>Quelle</th>
                        <th>NDA</th>
                        <th>Factbook erhalten</th>
                        <th>Verträge erhalten</th>
                        <th>Signing</th>
                        <th>Closing</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ABC Private Equity LLP</td>
                        <td>BB / Berater</td>
                        <td>ja - 01.02.2023</td>
                        <td>ja - 05.02.2023</td>
                        <td>ja - 08.02.2023</td>
                        <td>ja - 15.02.2023</td>
                        <td>ja - 20.02.2023</td>
                      </tr>
                    </tbody>
                  </TableWrapper>
                </Box>
              </Box> */}
            </>
          )}
        </Card>
      </Section>
    </Box>
  );
};

export default Status;
