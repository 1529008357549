import { Card, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { CountriesProps, OnboardingFormValues } from "../interfaces";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";
import {
  CategoryOption,
  CategoryType,
} from "../../../elements/frontend/src/components/CategoriesDropdown/interfaces";
import { useGetCategoriesQuery } from "../../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import {
  getCategoryOptions,
  getCategoryValues,
  hasValidData,
} from "../../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";
import { DashbordContext } from "../../../views/Private/Home";

interface ProfileProps {
  handleAutoCompleteSelect: (value: any[], field: string) => void;
  initial: boolean;
  setInitial: (value: boolean) => void;
  countries: CountriesProps;
  handleCategoriesSelect: (value: any[], field: string) => void;
}
export const Profile: React.FC<ProfileProps> = ({
  handleAutoCompleteSelect,
  initial,
  setInitial,
  countries,
  handleCategoriesSelect,
}) => {
  const { t } = useTranslation(["company/common"]);
  const { control } = useFormContext<OnboardingFormValues>();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const dashboardContext: any = useContext(DashbordContext);
  const selectedCategoryIds = dashboardContext.advisor.categories;

  const categoriesQuery = useGetCategoriesQuery();

  const categories = categoriesQuery.data;
  const categoryOptions = getCategoryOptions(
    categories,
    hasValidData(selectedCategoryIds) ? selectedCategoryIds : []
  );

  const categoryValues =
    hasValidData(selectedCategoryIds) && categories
      ? getCategoryValues(selectedCategoryIds, categories)
      : [];

  const countriesArr = Object.keys(countries).map((code) => ({
    code,
    name: countries[code],
  }));

  const convertToObjValue = useCallback(
    (value: any, field: string) => {
      if (value.length === 0) return [];
      return value.map((item: any) => {
        if (field === "region") {
          return countriesArr.find((obj: { code: any }) => obj.code === item);
        } else {
          return currentUser.datastore[field].find(
            (obj: { value: any; summary: any }) => obj.value === item
          );
        }
      });
    },
    [countriesArr, currentUser.datastore]
  );

  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("advisor/common:content.advisor.headline")}>
        <Card>
          {/*** CATEGORIES ***/}
          <Controller
            name="categories"
            control={control}
            render={({ fieldState: { error } }) => (
              <Autocomplete
                multiple
                id="categories"
                freeSolo
                size="small"
                options={categoryOptions}
                //getOptionLabel={(option) => option.label}
                value={categoryValues || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <Typography style={{ whiteSpace: "normal" }} sx={{ fontSize: 14 }}>
                          {option.label}
                        </Typography>
                      }
                      {...getTagProps({ index })}
                      style={{ height: "100%" }}
                    />
                  ))
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      required
                      label={t("categories_select.categories")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      error={!initial && categoryValues.length === 0}
                      helperText={
                        !initial && categoryValues.length === 0 && t("misc.required_field")
                      }
                    />
                  );
                }}
                onChange={(__e, newValue) => {
                  setInitial(false);
                  const selectedOptions = newValue as CategoryOption[];
                  handleCategoriesSelect(selectedOptions, CategoryType.CATEGORY);
                }}
              />
            )}
          />
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
            {/*** PHASES ***/}
            <FormControl fullWidth size="small">
              <Autocomplete
                multiple
                id="advisor_initial_phases"
                freeSolo
                options={currentUser.datastore.phases}
                getOptionLabel={(option) => option.summary}
                value={
                  dashboardContext?.advisor?.phases
                    ? convertToObjValue(dashboardContext?.advisor?.phases, "phases")
                    : {
                        value: "",
                        summary: "",
                      }
                }
                size="small"
                renderInput={(params) => (
                  <Controller
                    name="phases"
                    control={control}
                    render={({ fieldState: { error }, field: { value, onChange } }) => (
                      <TextField
                        {...params}
                        required
                        error={dashboardContext?.advisor?.phases.length === 0}
                        helperText={
                          dashboardContext?.advisor?.phases.length === 0 && t("misc.required_field")
                        }
                        label={t("advisor/common:content.advisor.phases")}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                  />
                )}
                onChange={(_e, value) => {
                  handleAutoCompleteSelect(value, "phases");
                }}
                isOptionEqualToValue={(option, value) => option.summary === value.summary}
              />
              <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                {t("misc.multi_select")}
              </Typography>
            </FormControl>

            {/*** REGION ***/}
            <FormControl fullWidth size="small">
              <Autocomplete
                multiple
                id="advisor_initial_region"
                freeSolo
                options={countriesArr}
                getOptionLabel={(option) => countries[option.code]}
                value={convertToObjValue(dashboardContext?.advisor?.region, "region")}
                size="small"
                renderInput={(params) => (
                  <Controller
                    name="region"
                    control={control}
                    render={({ fieldState: { error } }) => (
                      <TextField
                        {...params}
                        required
                        error={dashboardContext?.advisor?.region.length === 0}
                        helperText={
                          dashboardContext?.advisor?.region.length === 0 && t("misc.required_field")
                        }
                        label={t("advisor/common:content.advisor.region")}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                  />
                )}
                onChange={(e, value) => handleAutoCompleteSelect(value, "region")}
                isOptionEqualToValue={(option, value) => option.code === value.code}
              />
              <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                {t("misc.multi_select")}
              </Typography>
            </FormControl>
          </Stack>
        </Card>
      </Section>
    </>
  );
};
