import React, {
  forwardRef,
  useRef,
  useEffect,
  useState,
  ReactElement,
  Ref,
  ReactNode,
} from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogActions, DialogContent, TextField } from "@mui/material";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { lighten } from "@mui/system";
import { useMediaQuery } from "@mui/material";

interface TransitionProps {
  children: ReactElement;
  ref?: Ref<unknown>;
}

interface SecondaryDialogProps {
  type: string;
  dialogTitle: string;
  contentText: ReactNode;
  secondaryDialogOpen: boolean;
  secondaryDialogClose: () => void;
  eventHandler: () => void;
  actionButtonText: string;
  isMenuVisible: boolean;
}

const Transition = forwardRef(function Transition(props: TransitionProps, ref: Ref<unknown>) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const SecondaryDialog = ({
  type = "warning",
  dialogTitle,
  contentText,
  secondaryDialogOpen,
  secondaryDialogClose,
  eventHandler,
  actionButtonText,
  isMenuVisible,
}: SecondaryDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const dialogRef = useRef<HTMLDivElement | null>(null);

  const [cancelMessage, setCancelMessage] = useState<string>("");

  useEffect(() => {
    const handleCloseMenu = (e: MouseEvent) => {
      if (isMenuVisible && dialogRef.current && !dialogRef.current.contains(e.target as Node)) {
        secondaryDialogClose();
      }
    };

    const handleButtonClicked = (e: MouseEvent) => {
      e.stopPropagation(); // Prevent propagation to the document click event
    };

    if (isMenuVisible) {
      document.addEventListener("mousedown", handleCloseMenu);
      const buttons = document.querySelectorAll("button");
      buttons.forEach((button) => {
        button.addEventListener("mousedown", handleButtonClicked);
      });
    }

    return () => {
      document.removeEventListener("mousedown", handleCloseMenu);
      const buttons = document.querySelectorAll("button");
      buttons.forEach((button) => {
        button.removeEventListener("mousedown", handleButtonClicked);
      });
    };
  }, [isMenuVisible, secondaryDialogClose]);

  return (
    <div ref={dialogRef}>
      {
        <Dialog
          open={secondaryDialogOpen}
          onClose={secondaryDialogClose}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby={type}
          sx={{
            "& .MuiPaper-root": {
              width: "sx",
              borderRadius: "10px",
              "& .MuiDialogTitle-root": {
                backgroundColor: type === "warning" ? lighten(theme.palette.error.main, 0.8) : null,
              },
              "& .MuiDialogContent-root": {
                padding: "15px",
                [theme.breakpoints.up("md")]: {
                  padding: "24px",
                },
              },
              "& .MuiDialogActions-root": {
                padding: "15px",
                [theme.breakpoints.up("md")]: {
                  padding: "24px",
                },
              },
            },
          }}
        >
          <DialogTitle
            align="center"
            color="primary"
            sx={{
              padding: "15px",
              [theme.breakpoints.up("md")]: {
                padding: "24px",
              },
              fontWeight: "bold",
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent>{contentText}</DialogContent>
          <TextField
            label={t("company/common:personal_talk.cancel.text_field.caption")}
            placeholder={t("company/common:personal_talk.cancel.text_field.placeholder")}
            value={cancelMessage}
            onChange={(e) => setCancelMessage(e.target.value)}
            inputProps={{
              maxLength: 1000,
            }}
            variant={"filled"}
            multiline
            minRows={5}
            maxRows={5}
            sx={{
              margin: "15px",
              [theme.breakpoints.up("md")]: {
                margin: "24px",
              },
              fontWeight: "bold",
            }}
          />
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              size={isMobile ? "small" : "medium"}
              sx={{
                mr: { sx: 1, md: 3 },
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
              onClick={secondaryDialogClose}
            >
              {t("misc.cancel")}
            </Button>
            <Button
              variant="contained"
              color={type === "warning" ? "error" : "primary"}
              size={isMobile ? "small" : "medium"}
              sx={{
                px: 3,
                fontWeight: "bold",
                color: type === "warning" ? "white" : "secondary",
              }}
              onClick={eventHandler}
            >
              {actionButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};
