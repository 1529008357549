import { styled } from "@mui/material/styles";
import { rgba } from "polished";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    position: "relative",
  };
});

export const ListWrapper = styled("div")(({ theme }) => {
  return {
    backgroundColor: theme.palette.grey[50],
    borderRadius: "5px",
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(0px, 50px)",
    padding: "10px",
    boxShadow: `0.1rem 0.1rem 0.6rem ${rgba(theme.palette.greys.fourth.main, 0.4)}`,
  };
});

export const List = styled("ul")(({ theme }) => {
  return {
    listStyle: "none",
    margin: 0,
    width: "200px",
    padding: "0 5px",
  };
});

export const ListItem = styled("li")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    listStyle: "none",
    padding: "5px 0",
    margin: 0,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  };
});
