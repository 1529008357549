import React from "react";

import { SidebarSecondary } from "../../../../elements/frontend/src/components";

import ContentAdvisor from "./ContentAdvisor/ContentAdvisor";
import { Wrapper } from "./styled.advisor";

const AdvisorContent = () => {
  return (
    <Wrapper>
      <SidebarSecondary />
      <ContentAdvisor />
    </Wrapper>
  );
};

export default AdvisorContent;
