import React from "react";
import { useTranslation } from "react-i18next";

import MenuItem from "@mui/material/MenuItem";

import { StyledTextField, StyledSelect } from "./styled.tableComp";

export const TextFieldComponent = ({ val, multiline = false }) => {
  return (
    <StyledTextField
      name={val.name}
      size="small"
      value={val.value || ""}
      variant="standard"
      sx={{ width: "100%" }}
      multiline={multiline}
      maxRows={multiline ? 4 : 1}
    ></StyledTextField>
  );
};

export const EditableSelect = ({ val, options, handlerChange, disabled }) => {
  const { t } = useTranslation(["platform/common"]);

  return (
    <StyledSelect
      name={val.name}
      disableUnderline
      size="small"
      variant="standard"
      value={val.value || t("transaction_tables.none")}
      onChange={handlerChange}
      sx={{ width: "100%" }}
      disabled={disabled ? disabled : false}
    >
      {options
        .map((item) => item.name)
        .map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
    </StyledSelect>
  );
};
