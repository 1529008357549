import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100%",
  display: "grid",
  gridTemplateAreas: `
      "sidebarCommon dashboardAdvisor"
    `,
  gridTemplateColumns: "60px 1fr",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "250px 1fr",
  },
}));
