import * as yup from "yup";
import { TFunction } from "i18next";
import { parsePhoneNumber } from "libphonenumber-js";

export const OnboardingSchema = (t: TFunction) => {

  //const phoneMsg = t("misc.required_field");

  return yup.object({
    company_name: yup.string().required(t("misc.required_field")!),
    street: yup.string().required(t("misc.required_field")!),
    postal: yup
      .string()
      .required(t("misc.required_field")!)
      .matches(new RegExp("^[0-9]+$", "gi"), {
        message: t("error.invalid_postal_code"),
      }),
    city: yup.string().required(t("misc.required_field")!),
    country: yup.string().required(t("misc.required_field")!),

    first_name: yup.string().required(t("misc.required_field")!),
    last_name: yup.string().required(t("misc.required_field")!),
   // telephone: yup.string().required(t("misc.required_field")!).min(5, phoneMsg),
    telephone: yup
      .string()
      .required(t("misc.required_field")!)
      .test({
        name: "isValid",
        test(value: string, ctx: { createError: Function }) {
          if(value !== undefined && value.trim() !== ""){
          const phone = parsePhoneNumber(value);
          if (!phone?.isValid()) {
            return ctx.createError({ message: t("error.wrong_phone_number_format")! });
          } else {
            return true;
          }}
        },
      }),
    // categories: yup.string().required(t("misc.required_field")!),
    // categories: yup.array().of(
    //   yup.object().shape({
    //     value: yup.string(), 
    //     summary: yup.string(), 
    //   })
    // ).min(1, "Required field"),
    //phases: yup.array().length(1).required(t("misc.required_field")!),
    // region: yup.array().of(
    //   yup.object().shape({
    //     value: yup.string(), 
    //     summary: yup.string(), 
    //   })
    //).min(1, "Required field"),
    foundation: yup.string().required(t("misc.required_field")!),
    locations: yup.string().required(t("misc.required_field")!),
    consultant: yup.string().required(t("misc.required_field")!),
    trans_number: yup.string().required(t("misc.required_field")!),
  });
};
