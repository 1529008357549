import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import { FormHelperText, useMediaQuery } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { LoadingIndicator, DialogComponent } from "../../../../elements/frontend/src/components";
import { isValidLengthString } from "../../../../elements/frontend/src/common";
import { useAdvisors } from "../../../../elements/frontend/src/hooks";

import { Wrapper } from "./styled.createNewClientDialog";
import { setCurrentUser } from "../../../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppSelector } from "../../../../elements/frontend/src/Store/hooks/useAppSelector";

interface CreateNewClientDialogProps {
  dialogOpen: boolean;
  handleDialogClose: () => void;
}

interface CountriesProps {
  [code: string]: string;
}

const Content = ({ handleDialogClose, dialogOpen }: CreateNewClientDialogProps) => {
  const { t } = useTranslation(["advisor/common"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const { createClientAdvisor } = useAdvisors();

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true); // New state for button disabling

  const handlePostalCode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    const postNum = val.replace(/[^0-9]/g, "");
    e.target.value = postNum;
  };

  const countries: CountriesProps = t("advisor/common:content.countries", {
    returnObjects: true,
  });

  const countriesArr = Object.keys(countries).map((code) => ({
    code,
    name: countries[code],
  }));

  const [clientValues, setClientValues] = useState({
    customer_id: currentUser.customer.customer_id,
    name: "",
    address: {
      line_1: "",
      line_2: "",
      city: "",
      postal: "",
      country: "DEU",
    },
  });

  const [formValidation, setFormValidation] = useState({
    form_state: {
      initial: true,
      valid: false,
    },
  });

  const handleDataChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    if (name === "name") {
      setClientValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setClientValues((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  const isValid = (name: string, initial = true) => {
    if (initial && formValidation.form_state.initial) return true;
    switch (name) {
      case "name":
        return isValidLengthString(clientValues[name], 1);
      case "line_1":
        return isValidLengthString(clientValues.address[name], 1);
      case "postal":
        return isValidLengthString(clientValues.address[name], 1);
      case "city":
        return isValidLengthString(clientValues.address[name], 1);
      case "country":
        return isValidLengthString(clientValues.address[name], 1);
      default:
        return false;
    }
  };

  // New useEffect to check if all required fields are filled and update the isSaveDisabled state
  useEffect(() => {
    const allFieldsFilled =
      clientValues.name &&
      clientValues.address.line_1 &&
      clientValues.address.postal &&
      clientValues.address.city &&
      clientValues.address.country;

    setIsSaveDisabled(!allFieldsFilled);
  }, [clientValues]); // Dependency array on clientValues

  const handleUploadClientData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (formValidation.form_state.initial) {
        setFormValidation({
          form_state: {
            initial: false,
            valid: formValidation.form_state.valid,
          },
        });
      }

      const allFieldsAreValid =
        isValid("name", false) &&
        isValid("line_1", false) &&
        isValid("postal", false) &&
        isValid("city", false) &&
        isValid("country", false);

      if (!allFieldsAreValid) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      if (allFieldsAreValid !== formValidation.form_state.valid) {
        setFormValidation({
          form_state: {
            initial: allFieldsAreValid,
            valid: false,
          },
        });
      }

      if (allFieldsAreValid) {
        const objToCreate = {
          ...clientValues,
          contact: {
            first_name: currentUser.user.first_name,
            last_name: currentUser.user.last_name,
            telephone: currentUser.user.telephone,
          },
        };
        const response = await createClientAdvisor(objToCreate);
        if (response) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (err) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  return (
    <div>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <DialogContent
        sx={{
          padding: "0 10px 10px",
          height: "100%",
          maxHeight: "55vh",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 20px",
          },
        }}
      >
        <Wrapper>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 2, md: 4 }}
            sx={{ mb: 2, pt: 1 }}
          >
            <TextField
              label={t("advisor/common:content.company.company_name")}
              name="name"
              id="advisor_client_company_name"
              value={clientValues.name || ""}
              size="small"
              fullWidth
              required
              onChange={handleDataChange}
              error={!isValid("name")}
              helperText={!isValid("name") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-company",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <TextField
              label={t("advisor/common:content.company.street")}
              name="line_1"
              id="line_1"
              value={clientValues.address.line_1 || ""}
              size="small"
              required
              fullWidth
              onChange={handleDataChange}
              onBlur={() => {
                const containNumAndText =
                  /[a-zA-Z]/.test(clientValues.address.line_1) &&
                  /\d/.test(clientValues.address.line_1);
                if (!containNumAndText) {
                  setTrigger(true);
                } else {
                  setTrigger(false);
                }
              }}
              error={!isValid("line_1") || trigger}
              helperText={!isValid("line_1") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-street",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <TextField
              label={t("advisor/common:content.company.postal_code")}
              name="postal"
              id="postal"
              value={
                clientValues?.address?.country === "DEU"
                  ? (clientValues?.address?.postal || "").substring(0, 5)
                  : clientValues?.address?.postal || ""
              }
              size="small"
              required
              fullWidth
              onInput={(e) => handlePostalCode(e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)}
              onChange={handleDataChange}
              error={!isValid("postal")}
              helperText={!isValid("postal") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-postal",
                form: {
                  autoComplete: "off",
                },
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <TextField
              label={t("advisor/common:content.company.city")}
              name="city"
              id="city"
              value={clientValues?.address?.city}
              size="small"
              required
              fullWidth
              onChange={handleDataChange}
              error={!isValid("city")}
              helperText={!isValid("city") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-city",
                form: {
                  autoComplete: "off",
                },
                onInput: (e) => {
                  e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z]/g, "");
                },
              }}
            />

            <FormControl fullWidth size="small">
              <InputLabel id="country" required>
                {t("advisor/common:content.company.country")}
              </InputLabel>
              <Select
                labelId="country"
                id="country"
                name="country"
                value={clientValues.address.country || ""}
                label={t("company/common:onboarding.company.country")}
                onClick={(e) => e.stopPropagation}
                onChange={handleDataChange}
                error={!isValid("country")}
              >
                {countriesArr.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {!isValid("country") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
            </FormControl>
          </Stack>
        </Wrapper>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{
          width: "100%",
          padding: "0 10px 10px",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 24px",
          },
        }}
      >
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size={isMobile ? "small" : "medium"}
            sx={{
              mr: { sx: 1, md: 3 },
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
            onClick={handleDialogClose}
          >
            {t("misc.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            disabled={isSaveDisabled} // Disable button if not all fields are filled
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
            onClick={handleUploadClientData}
          >
            {t("misc.save")}
          </Button>
        </DialogActions>
      </Stack>
    </div>
  );
};

export const CreateNewClientDialog = ({ dialogOpen, handleDialogClose }: CreateNewClientDialogProps) => {
  const { t } = useTranslation(["advisor/common"]);

  return (
    <div>
      <DialogComponent
        dialogKey={"create-new-client-dialog"}
        title={t("advisor/common:content.client_selection.create_client.title")}
        content={<Content handleDialogClose={handleDialogClose} dialogOpen={dialogOpen} />}
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        maxWidth={"md"}
      />
    </div>
  );
};
