import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Section } from "../../elements/frontend/src/components/Section/Section";
import { Card, LoadingIndicator } from "../../elements/frontend/src/components";
import { SelectColumnFilter } from "../../elements/frontend/src/components/TableFilterHelpers/TableFilterHelpers";
import { useAccounts } from "../../elements/frontend/src/hooks";
import { DefaultColumnFilter } from "../../elements/frontend/src/components/TableFilterHelpers/TableFilterHelpers";
import { OverviewAdvisorTable } from "../OverviewAdvisorTable/OverviewAdvisorTable";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Transaction } from "../../elements/frontend/src/Store/api";
import { ExpandAcceptTransaction, ExpandTransactionOffers } from "./interface";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { ProductId } from "./interface";

const OverviewAdvisor: React.FC = () => {
  const { t } = useTranslation(["advisor/common"]);
  const theme = useTheme();
  const { getAccount } = useAccounts();
  const navigate = useNavigate();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const [newRegister, setNewRegister] = useState(false);
  const [expandedTransactionOffers, setExpandedTransactionOffers] = useState([]);
  const [expandedAcceptedTransactions, setExpandedAcceptedTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  // stepsObject from locales
  const stepsObject = t("transaction_tables.process_flow.steps", {
    returnObjects: true,
  });
  const productId: ProductId = currentUser.customer.subscription.product_id;

  // to get all account transactions
  useEffect(() => {
    setLoadingTransactions(true);
    getAccount()
      .then((response) => {
        // transaction offers, that transaction belongs to a company not adviser client
        const newTransactionOffers = response.transactions.filter(
          (item: any) =>
            item?.state?.steps?.contract_company_signed?.completed === true &&
            item?.state?.steps?.advisor_matching?.completed === true &&
            item?.state?.steps?.advisor_accepted?.completed === false
        );

        const expandedTransactionOffers = newTransactionOffers.map(
          (item: ExpandTransactionOffers) => {
            const lastActionKey = String(item.state.status.last_step) as keyof typeof stepsObject;

            return {
              client_name: item.client_name,
              last_action: stepsObject[lastActionKey],
              date: new Date(item.state.status.last_step_timestamp).toLocaleDateString(),
              next_action: (stepsObject as any)[`${productId}_accepted`],
              transaction_id: item.transaction_id,
              [productId]: item[productId],
            };
          }
        );

        // transaction belongs to company not adviser client
        // transactions, which are accepted and adviser_matching completed
        const acceptedTransactions = response.transactions.filter((item: any) => {
          if (productId === "advisor") {
            return productId in item && "user_id" in item[productId] && item;
          } else if (productId === "lawyer") {
            return item?.state?.steps?.lawyer_matching?.completed === true;
          }
          return false;
        });

        // transaction belongs to adviser client
        // if advisor has clients, client's transaction will be added to acceptedTransactions
        if (response.clients.length) {
          response?.clients?.forEach((client: { client_id: string }) => {
            response.transactions.forEach((transaction: Transaction) => {
              if (
                transaction.client_id === client.client_id &&
                !transaction?.state?.stages?.adviser_matching?.completed
              ) {
                acceptedTransactions.push(transaction);
              }
            });
          });
        }

        const expandedAcceptedTransactions = acceptedTransactions.map(
          (item: ExpandAcceptTransaction) => {
            const lastActionKey = String(item.state.status.last_step) as keyof typeof stepsObject;
            const nextActionKey = String(
              item.state.status.next_steps[0]
            ) as keyof typeof stepsObject;

            return {
              client_name: item.client_name,
              last_action: stepsObject[lastActionKey],
              date: new Date(item.state.status.last_step_timestamp).toLocaleDateString(),
              next_action: stepsObject[nextActionKey],
              assigned_supervisor: item.supervisor.user_id
                ? item.supervisor.user_name
                : t("transaction_tables.none"),
              [`assigned_${productId}_user`]: item[productId].user_id
                ? item[productId].user_name
                : t("transaction_tables.none"),
              transaction_id: item.transaction_id,
              [productId]: item[productId],
            };
          }
        );

        setExpandedTransactionOffers(expandedTransactionOffers);
        setExpandedAcceptedTransactions(expandedAcceptedTransactions);
        setLoadingTransactions(false);
      })
      .catch((err) => {
        err && setLoadingTransactions(false);
        // console.log("err ", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table_1_columns = useMemo(
    () => [
      {
        Header: t("transaction_tables.columns.company_name"),
        accessor: "client_name",
        disableSortBy: false,
        Filter: ({ column }: { column: any }) => (
          <DefaultColumnFilter
            column={column}
            placeholder={t("de/common:document_table.search_by_name")}
          />
        ),
      },
      {
        Header: t("transaction_tables.columns.last_action"),
        accessor: "last_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.date"),
        accessor: "date",
        Filter: ({ column }: { column: any }) => (
          <DefaultColumnFilter column={column} placeholder={t("de/common:document_table.format")} />
        ),
      },
      {
        Header: t("transaction_tables.columns.accept_reject"),
        accessor: "accept",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [t]
  );

  const table_2_columns = useMemo(
    () => [
      {
        Header: t("transaction_tables.columns.company_name"),
        accessor: "client_name",
        Filter: ({ column }: { column: any }) => (
          <DefaultColumnFilter
            column={column}
            placeholder={t("de/common:document_table.search_by_name")}
          />
        ),
      },
      {
        Header: t("transaction_tables.columns.last_action"),
        accessor: "last_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.date"),
        accessor: "date",
        Filter: ({ column }) => (
          <DefaultColumnFilter column={column} placeholder={t("de/common:document_table.format")} />
        ),
      },
      {
        Header: t("transaction_tables.columns.next_action"),
        accessor: "next_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.supervisor"),
        accessor: "assigned_supervisor",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.advisor"),
        accessor: "assigned_advisor_user",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.lawyer"),
        accessor: "assigned_lawyer_user",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    [t]
  );

  // to manage the sidebar STATUS step, which one will be visible or hided
  const handleStatus = (id: string) => {
    navigate(`${id}/status`);
    setNewRegister(!newRegister);
  };
  // to manage the sidebar DETAILS step, which one will be visible or hided
  const handleDetails = (id: string) => {
    navigate(`${id}/details`);
  };

  const handleDueDiligence = (id: string) => {
    navigate(`${id}/due-diligence-legal`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        maxWidth: theme.breakpoints.values.xl,
      }}
    >
      {/* Table - 1 */}
      {productId === "advisor" && (
        <Section
          title={t("advisor/common:content.advisor_view.overview.transaction_offers.headline")}
        >
          <Card>
            {loadingTransactions && <LoadingIndicator type={"COMPONENT"} />}
            {!loadingTransactions && (
              <>
                {expandedTransactionOffers?.length > 0 ? (
                  <OverviewAdvisorTable
                    columns={table_1_columns}
                    data={expandedTransactionOffers}
                    handleShowStatus={handleStatus}
                    loading={loadingTransactions}
                    onlyDetails={false}
                  />
                ) : (
                  <Typography>
                    {t("advisor/common:content.advisor_view.overview.transaction_offers.no_offer")}
                  </Typography>
                )}
              </>
            )}
          </Card>
        </Section>
      )}

      {/* Table - 2 */}
      <Section title={t("advisor/common:content.advisor_view.overview.all_transactions.headline")}>
        <Card>
          {loadingTransactions && <LoadingIndicator type={"COMPONENT"} />}
          {!loadingTransactions && (
            <>
              {expandedAcceptedTransactions?.length > 0 ? (
                <OverviewAdvisorTable
                  columns={table_2_columns}
                  data={expandedAcceptedTransactions}
                  handleShowStatus={handleStatus as (id: any) => void}
                  handleShowDetails={handleDetails}
                  handelShowDueDiligence={handleDueDiligence}
                  loading={loadingTransactions}
                  onlyDetails={false}
                />
              ) : (
                <Typography>
                  {t(
                    "advisor/common:content.advisor_view.overview.all_transactions.no_transaction"
                  )}
                </Typography>
              )}
            </>
          )}
        </Card>
      </Section>
    </Box>
  );
};

export default OverviewAdvisor;
