import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";
import { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useMediaQuery } from "@mui/material";

import { Card, DialogComponent, Section, LoadingIndicator } from "../..";
import { SecondaryDialog } from "../../SecondaryDialog/SecondaryDialog";

import { Phone } from "../../Phone/Phone";

import { useAccounts, useValidateEmail } from "../../../hooks";
import { isValidLengthString } from "../../../common";
import { useAppSelector } from "../../../Store/hooks/useAppSelector";
import { useAppDispatch } from "../../../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../../../Store/currentUser/currentUserSlice";

import { ContentProps, CustomerProfile, CustomerProfileDialogProbs } from "./interface";

const Content = ({ handleDialogClose, product }: ContentProps) => {
  const { t } = useTranslation(["company/common"]);
  const theme = useTheme();
  const { isEmail } = validator; // it will validate email from validator npm package
  const navigate = useNavigate();
  const validateEmail = useValidateEmail(); // it will validate email from DB

  const { updateAccount } = useAccounts();

  const currentUser: any = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  // Country options for select "Land"
  const countries: any = t("company/common:onboarding.countries", {
    returnObjects: true,
  });

  // for the trade registration number saved in scope "profile/customer"
  const trade_registration_value = useMemo(() => {
    if (!currentUser.customer.metadata) return "";
    const scopeProfile = currentUser.customer.metadata.find(
      (item: { [key: string]: string }) => item.scope === "profile"
    );
    if (scopeProfile && scopeProfile.data.trade_registration) {
      return scopeProfile.data.trade_registration;
    }
    return "";
  }, [currentUser.customer.metadata]);

  // for the types saved in scope "profile/customer"
  const investor_type_value = useMemo(() => {
    if (!currentUser.customer.metadata) return [];
    const scopeProfile = currentUser.customer.metadata.find(
      (item: { [key: string]: string }) => item.scope === "profile"
    );
    if (scopeProfile && scopeProfile.data.types) {
      return scopeProfile.data.types;
    }
    return [];
  }, [currentUser.customer.metadata]);

  // for the sources saved in scope "signup/customer"
  const sources_value = useMemo(() => {
    if (!currentUser?.customer.metadata) return [];
    const scopeSignup = currentUser?.customer.metadata.find(
      (item: { [key: string]: string }) => item.scope === "signup"
    );
    if (scopeSignup && scopeSignup.data.sources) {
      return scopeSignup.data.sources;
    }
    return [];
  }, [currentUser?.customer.metadata]);

  // to keep customer data as state
  const [customerProfile, setCustomerProfile] = useState<CustomerProfile>({
    name: currentUser?.customer.name || "",
    email: currentUser?.customer.email || "",
    address: {
      line_1: currentUser?.customer.address?.line_1 || "",
      postal: currentUser?.customer.address?.postal || "",
      city: currentUser?.customer.address?.city || "",
      country: currentUser?.customer.address?.country || "",
    },
    contact: {
      first_name: currentUser?.customer.contact?.first_name || "",
      last_name: currentUser?.customer.contact?.last_name || "",
      telephone: currentUser?.customer.contact?.telephone || "+49",
    },
    trade_registration: trade_registration_value || "",
    types:
      investor_type_value.length > 0
        ? investor_type_value
        : [...currentUser?.datastore.investor.map((item: any) => item.value)],
    sources:
      sources_value.length > 0
        ? sources_value
        : [...currentUser?.datastore.sources.map((item: any) => item.value)],

    form_state: {
      initial: true,
      valid: false,
    },
  });

  // to check whether customer new email is exist in DB, or not
  const [isEmailNotExistInDB, setIsEmailNotExistInDB] = useState(true);

  // to show/hide secondary dialog for customer email change event
  const [customerEmailChangeConfirmDialogOpen, setCustomerEmailChangeConfirmDialogOpen] =
    useState(false);

  // to check whether input fields are valid or not
  const isValid = (name: string, initial = true) => {
    if (initial && customerProfile.form_state.initial) return true;
    switch (name) {
      case "name":
      case "email":
      case "trade_registration":
        return isValidLengthString(customerProfile[name], 1);
      case "line_1":
      case "postal":
      case "city":
      case "country":
        return isValidLengthString(customerProfile.address[name], 1);
      case "first_name":
      case "last_name":
        return isValidLengthString(customerProfile.contact[name], 1);
      case "telephone":
        return isValidLengthString(customerProfile.contact[name], 5);
      case "types":
      case "sources":
        return customerProfile[name].length > 0;
      default:
        return false;
    }
  };

  // to check customer email is valid or not according to validator npm package
  const isEmailValid = (email: string) => isEmail(email);

  // to convert Autocomplete array values to objects. like {value, summary}
  const convertToObjValue = useCallback(
    (value: string[], field: string) => {
      if (value.length === 0) return [];
      return value.map((item: any) => {
        if (field === "region") {
          return currentUser.datastore[field].find((obj: { code: string }) => obj.code === item);
        } else {
          return currentUser.datastore[field].find((obj: { value: string }) => obj.value === item);
        }
      });
    },
    [currentUser.datastore]
  );

  // handle change event in input fields
  const handleDataChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    mainField: keyof CustomerProfile
  ) => {
    const field = e.target.name;
    const value = e.target.value;

    setCustomerProfile((prevCustomerProfile) => {
      if (mainField === "name" || mainField === "email") {
        return { ...prevCustomerProfile, [field]: value };
      } else {
        return {
          ...prevCustomerProfile,
          [mainField]: {
            ...(prevCustomerProfile[mainField] as object),
            [field]: value,
          },
        };
      }
    });
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value: string, initial = true) => {
    if (initial && customerProfile.form_state.initial) return true;
    const phone = parsePhoneNumber(value);
    return phone?.isValid();
  };

  // handle change event in contact telephone
  const handlePhoneChange = (value: string) => {
    setCustomerProfile({
      ...customerProfile,
      contact: {
        ...customerProfile.contact,
        telephone: value,
      },
    });
  };

  // handle focus event for trade_registration, in order to create trade register number in pattern "HR[A/B]-[Numbers]"
  const handleTradeRegistrationFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    if (customerProfile.address.country === "DEU") {
      if (!value) {
        setCustomerProfile({
          ...customerProfile,
          [field]: "HR",
        });
      }
    }
  };

  // handle change event for trade_registration
  const handleTradeRegistrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name;
    const value = e.target.value;

    // if country is "DEU", the pattern of trade register number is "HR(A|B)-[Numbers]"
    if (customerProfile.address.country === "DEU") {
      if (value.length === 3) {
        if (value.toLowerCase().endsWith("a") || value.toLowerCase().endsWith("b")) {
          setCustomerProfile({
            ...customerProfile,
            [field]: value.toUpperCase() + "-",
          });
        }
      } else if (value.length === 4) {
        setCustomerProfile({
          ...customerProfile,
          [field]: value,
        });
      } else {
        const lastValue = value.slice(-1);
        if (!isNaN(+lastValue)) {
          setCustomerProfile({
            ...customerProfile,
            [field]: value,
          });
        }
      }
    }
  };

  // handle change event in Autocomplete elements
  const handleAutoCompleteSelect = (
    e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>,
    value: { value: string; summart: string }[],
    field: string
  ) => {
    if (value) {
      const newValue = value.map((item) => item.value);
      setCustomerProfile({
        ...customerProfile,
        [field]: newValue,
      });
    }
  };

  // to show secondary dialog for customer email change confirmation
  const handleCustomerEmailChangeConfirmDialogOpen = () => {
    setCustomerEmailChangeConfirmDialogOpen(true);
  };

  // to close secondary dialog for customer email change confirmation
  const handleCustomerEmailChangeConfirmDialogClose = () => {
    setCustomerEmailChangeConfirmDialogOpen(false);
  };

  // to update customer data
  const handleCustomerProfile = () => {
    // if the customer email changed, then need confirmation dialog
    if (customerProfile.email !== currentUser.customer.email) {
      handleCustomerEmailChangeConfirmDialogOpen();
      return;
    }

    // if customer email did not change, then continue to update changes
    handleCustomerProfileUpdate();
  };

  // to update customer data changes
  async function handleCustomerProfileUpdate() {
    try {
      // if secondary confirmation dialog is open, then close it
      setCustomerEmailChangeConfirmDialogOpen(false);

      // run loading indicator
      dispatch(setCurrentUser({ loading: true }));

      // if customer changes his email, then need revalidation, whether email already exist in DB
      if (customerProfile.email !== currentUser.customer.email) {
        // if email has not been used yet, then it returns true
        const isEmailNotExist =
          isEmailValid(customerProfile.email) && (await validateEmail(customerProfile.email));
        // if email already used, means it is exist
        if (!isEmailNotExist) {
          setIsEmailNotExistInDB(false);
          dispatch(setCurrentUser({ loading: false }));
          return;
        }
      }

      if (customerProfile.form_state.initial) {
        setCustomerProfile({
          ...customerProfile,
          form_state: {
            ...customerProfile.form_state,
            initial: false,
          },
        });
      }

      const allCommonFieldsAreValid =
        isValid("name", false) &&
        isValid("email", false) &&
        isValid("line_1", false) &&
        isValid("postal", false) &&
        isValid("city", false) &&
        isValid("country", false) &&
        isValid("first_name", false) &&
        isValid("last_name", false) &&
        isValid("telephone", false) &&
        isValid("sources", false);

      // trade_registration is only in company prduct
      const allCompanyFieldsAreValid = isValid("trade_registration", false);

      // types is only in investor prduct
      const allInvestorFieldsAreValid = isValid("types", false);

      // check fields' validation according to product
      const allFieldsAreValid =
        product === "company"
          ? allCommonFieldsAreValid && allCompanyFieldsAreValid
          : product === "investor"
          ? allCommonFieldsAreValid && allInvestorFieldsAreValid
          : allCommonFieldsAreValid;

      if (!allFieldsAreValid) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      if (allFieldsAreValid !== customerProfile.form_state.valid) {
        setCustomerProfile({
          ...customerProfile,
          form_state: {
            initial: false,
            valid: allFieldsAreValid,
          },
        });
      }

      if (customerProfile.contact.telephone) {
        const phone = parsePhoneNumber(customerProfile.contact.telephone);
        const isValidPhoneNumber = phone?.isValid();
        const formattedNumber = phone?.formatInternational();

        if (isValidPhoneNumber) {
          setCustomerProfile({
            ...customerProfile,
            contact: {
              ...customerProfile.contact,
              telephone: formattedNumber ?? "",
            },
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      const objToUpdate = {
        name: customerProfile.name,
        email: customerProfile.email,
        address: {
          line_1: customerProfile.address.line_1,
          postal: customerProfile.address.postal,
          city: customerProfile.address.city,
          country: customerProfile.address.country,
        },
        contact: {
          first_name: customerProfile.contact?.first_name,
          last_name: customerProfile.contact.last_name,
          telephone: customerProfile.contact.telephone,
        },
        metadata:
          product === "company"
            ? [
                {
                  scope: "profile",
                  data: {
                    trade_registration: customerProfile.trade_registration,
                  },
                },
                {
                  scope: "signup",
                  data: {
                    sources: customerProfile.sources,
                  },
                },
              ]
            : product === "investor"
            ? [
                {
                  scope: "profile",
                  data: {
                    types: customerProfile.types,
                  },
                },
                {
                  scope: "signup",
                  data: {
                    sources: customerProfile.sources,
                  },
                },
              ]
            : [
                {
                  scope: "signup",
                  data: {
                    sources: customerProfile.sources,
                  },
                },
              ],
      };

      // if customer changes email, then need to logout, in order top ligin with new email and with temporary password
      const shouldLogout = customerProfile.email !== currentUser.customer.email;

      const response = await updateAccount(objToUpdate);

      if (response) {
        if (shouldLogout) navigate("/logout");

        window.location.reload();
      }
    } catch (e) {
      dispatch(setCurrentUser({ loading: false }));
    }
  }

  return (
    <>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <DialogContent
        sx={{
          padding: "0 10px 10px",
          height: "100%",
          maxHeight: "55vh",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 20px",
          },
        }}
      >
        <Box>
          {/** **** COMPANY DATA  ************/}
          {/* @ts-ignore */}
          <Section
            title={t("top_bar.customer_profile_dialog.company.headline")}
            headlineColor={"dark"}
            isDialog={true}
          >
            <Card>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{}}>
                <TextField
                  label={t("top_bar.customer_profile_dialog.company.company_name")}
                  name="name"
                  id="customer_profile_name"
                  value={customerProfile.name || ""}
                  size="small"
                  fullWidth
                  required
                  onChange={(e) => handleDataChange(e, "name")}
                  error={!isValid("name")}
                  helperText={!isValid("name") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-company",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                {product === "company" && (
                  <TextField
                    label={t("top_bar.customer_profile_dialog.profile.trade_registration")}
                    name="trade_registration"
                    id="customer_profile_trade_registration"
                    value={customerProfile.trade_registration || ""}
                    size="small"
                    required
                    fullWidth
                    onChange={handleTradeRegistrationChange}
                    onFocus={handleTradeRegistrationFocus}
                    error={!isValid("trade_registration")}
                    helperText={!isValid("trade_registration") && t("misc.required_field")}
                    inputProps={{
                      autoComplete: "new-trade_registration",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ my: 2 }}
              >
                <TextField
                  label={t("top_bar.customer_profile_dialog.company.street")}
                  name="line_1"
                  id="customer_profile_line_1"
                  value={customerProfile.address.line_1 || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => handleDataChange(e, "address")}
                  error={!isValid("line_1")}
                  helperText={!isValid("line_1") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-street",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("top_bar.customer_profile_dialog.company.postal_code")}
                  name="postal"
                  id="customer_profile_postal"
                  value={customerProfile.address.postal || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => handleDataChange(e, "address")}
                  error={!isValid("postal")}
                  helperText={!isValid("postal") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-postal",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <TextField
                  label={t("top_bar.customer_profile_dialog.company.city")}
                  name="city"
                  id="customer_profile_city"
                  value={customerProfile.address.city || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => handleDataChange(e, "address")}
                  error={!isValid("city")}
                  helperText={!isValid("city") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-city",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <FormControl fullWidth size="small">
                  <InputLabel id="country" required>
                    {t("top_bar.customer_profile_dialog.company.country")}
                  </InputLabel>
                  <Select
                    labelId="country"
                    id="customer_profile_country"
                    name="country"
                    value={customerProfile.address.country || ""}
                    label={t("top_bar.customer_profile_dialog.company.country")}
                    onChange={(e) => handleDataChange(e, "address")}
                    error={!isValid("country")}
                  >
                    <MenuItem value={"DEU"}>{countries[customerProfile.address.country]}</MenuItem>
                  </Select>
                  {!isValid("country") && (
                    <FormHelperText>{t("misc.required_field")}</FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Card>
          </Section>

          {/** **** CONTACT ************/}
          {/* @ts-ignore */}
          <Section
            title={t("top_bar.customer_profile_dialog.contact.headline")}
            headlineColor={"dark"}
            isDialog={true}
          >
            <Card>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <TextField
                  label={t("top_bar.customer_profile_dialog.contact.first_name")}
                  name="first_name"
                  id="customer_profile_first_name"
                  value={customerProfile.contact.first_name || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => handleDataChange(e, "contact")}
                  error={!isValid("first_name")}
                  helperText={!isValid("first_name") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-firstname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("top_bar.customer_profile_dialog.contact.last_name")}
                  name="last_name"
                  id="customer_profile_last_name"
                  value={customerProfile.contact.last_name || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => handleDataChange(e, "contact")}
                  error={!isValid("last_name")}
                  helperText={!isValid("last_name") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-lastname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mt: 2 }}
              >
                <TextField
                  label={t("top_bar.customer_profile_dialog.contact.email")}
                  id="customer_profile_email"
                  name="email"
                  type="email"
                  size="small"
                  fullWidth
                  value={customerProfile.email}
                  onChange={(e) => handleDataChange(e, "email")}
                  error={!isValid("email") || !isEmailNotExistInDB}
                  helperText={
                    !isValid("email")
                      ? t("misc.required_field")
                      : !isEmailValid(customerProfile.email) || !isEmailNotExistInDB
                      ? t("error.invalid_email")
                      : ""
                  }
                />
                <Phone
                  telephone={customerProfile.contact.telephone || "+49"}
                  label={t("top_bar.customer_profile_dialog.contact.phone")}
                  phoneChange={handlePhoneChange}
                  mobile={true}
                  error={
                    !isValid("telephone") || !checkPhoneNumber(customerProfile.contact.telephone)
                  }
                  errorText={
                    !isValid("telephone")
                      ? t("misc.required_field")
                      : !checkPhoneNumber(customerProfile.contact.telephone)
                      ? t("error.invalid_phoneNumber")
                      : ""
                  }
                  registering={false}
                  idLabel={"customer_profile_dialog"}
                />
              </Stack>
            </Card>
          </Section>

          {/** **** OTHER INFORMATION ************/}
          {/* @ts-ignore */}
          <Section
            title={t("top_bar.customer_profile_dialog.additional_info.headline")}
            headlineColor={"dark"}
            isDialog={true}
          >
            <Card>
              {product === "investor" && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ mb: 2 }}
                >
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="customer_profile_investorType"
                      freeSolo
                      options={currentUser.datastore.investor}
                      getOptionLabel={(option) => option.summary}
                      value={convertToObjValue(customerProfile.types, "investor") || []}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={t("top_bar.customer_profile_dialog.additional_info.investor_type")}
                          error={!isValid("phases")}
                        />
                      )}
                      onChange={(e, value) => handleAutoCompleteSelect(e, value, "types")}
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    {!isValid("types") && (
                      <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                    )}
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>
                </Stack>
              )}
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    multiple
                    id="customer_profile_sources"
                    freeSolo
                    options={currentUser.datastore.sources}
                    getOptionLabel={(option) => option.summary}
                    value={convertToObjValue(customerProfile.sources, "sources") || []}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={t("top_bar.customer_profile_dialog.additional_info.source")}
                        error={!isValid("sources")}
                      />
                    )}
                    onChange={(e, value) => handleAutoCompleteSelect(e, value, "sources")}
                    isOptionEqualToValue={(option, value) => option.summary === value.summary}
                  />
                  {!isValid("sources") && (
                    <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                  )}
                  <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                    {t("misc.multi_select")}
                  </Typography>
                </FormControl>
              </Stack>
            </Card>
          </Section>
        </Box>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{
          width: "100%",
          padding: "0 10px 10px",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 24px",
          },
        }}
      >
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size={isMobile ? "small" : "medium"}
            sx={{
              mr: { sx: 1, md: 3 },
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
            onClick={handleDialogClose}
          >
            {t("misc.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            // disabled={!isValid(newUserEmail) || !addNewUserForm || emailExist}
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
            onClick={handleCustomerProfile}
          >
            {t("misc.save")}
          </Button>
        </DialogActions>
      </Stack>
      {/* Secondary Dialog for customer email change confirmation */}
      <SecondaryDialog
        type="warning"
        dialogTitle={t("company/common:top_bar.user_management_dialog.change_email")}
        contentText={t("company/common:top_bar.user_management_dialog.change_email_info")}
        secondaryDialogOpen={customerEmailChangeConfirmDialogOpen}
        secondaryDialogClose={handleCustomerEmailChangeConfirmDialogClose}
        eventHandler={handleCustomerProfileUpdate}
        actionButtonText={t("company/common:top_bar.user_management_dialog.change_email")}
        isMenuVisible={false}
      />
    </>
  );
};

export const CustomerProfileDialog = ({
  dialogOpen,
  handleDialogClose,
  product,
}: CustomerProfileDialogProbs) => {
  const { t } = useTranslation();
  return (
    <DialogComponent
      dialogKey={"customer-profile-dialog"}
      title={t("top_bar.customer_profile_dialog.headline")}
      content={<Content handleDialogClose={handleDialogClose} product={product} />}
      dialogOpen={dialogOpen}
      handleDialogClose={handleDialogClose}
      maxWidth={"lg"}
    />
  );
};
