import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../Store/api/transactions/transactionsApi";
import {
  Header,
  Hour,
  MessageBottomContainer,
  MessageBubble,
  MessageContainer,
  MessageHeader,
  MessengerContainer,
  Name,
  StyledForm,
} from "./Messenger.styles";
import { Comment, CommentsMetadata } from "../../Store/api";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import PersonRemoveOutlinedIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { MessengerFormValues } from "./interfaces";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { canBeDeleted } from "./MessengerUtils";
import { useTranslation } from "react-i18next";

interface MessengerProps {
  transactionId: string;
  productId: "platform" | "advisor";
  statusOffer: boolean;
}

const initialFormValues: MessengerFormValues = {
  message: "",
  messages: [],
};

export const Messenger = ({ transactionId, productId, statusOffer }: MessengerProps) => {
  const { t } = useTranslation(["common"]);

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updateTransaction] = useUpdateTransactionMutation();
  const transactionByIdQuery = useGetTransactionByIdQuery({ transactionId });
  const transaction = transactionByIdQuery.data;
  const methods = useForm<MessengerFormValues>({ mode: "all", defaultValues: initialFormValues });
  const { handleSubmit, setValue, watch, control, formState } = methods;
  const { isSubmitting } = formState;
  const messages = watch("messages");

  const handleSendMessage = async (formValues: MessengerFormValues) => {
    const result = await transactionByIdQuery.refetch();
    const comments: Comment[] =
      (result.data?.metadata.find((data) => data.scope === "comments")?.data as Comment[]) ?? [];
    const comment: Comment = {
      id: uuidv4(),
      timestamp: new Date().getTime(),
      user: currentUser?.attributes ? currentUser.attributes.user_id : "",
      userName: `${currentUser?.user?.first_name} ${currentUser?.user?.last_name}`,
      role: productId,
      private: true,
      msg: formValues.message,
    };

    if (result.data) {
      const metadataIndex = result.data?.metadata.findIndex((data) => data.scope === "comments");

      if (metadataIndex === -1) {
        const updatedTransaction = {
          ...result.data,
          metadata: [
            ...result.data.metadata,
            { scope: "comments", data: [...comments, comment] } as CommentsMetadata,
          ],
        };

        await updateTransaction({ transactionId: transactionId, body: updatedTransaction });
      } else {
        const updatedMetadata = [...result.data.metadata];
        updatedMetadata[metadataIndex] = {
          scope: "comments",
          data: Array.isArray(comments) ? [...comments, comment] : [comment],
        };
        const updatedTransaction = {
          ...result.data,
          metadata: updatedMetadata,
        };

        await updateTransaction({ transactionId: transactionId, body: updatedTransaction });
      }
    }

    setValue("messages", Array.isArray(comments) ? [...comments, comment] : [comment]);
    setValue("message", "");
  };

  const handleDeleteMessage = async (messageId: string) => {
    setIsDeleting(true);
    const result = await transactionByIdQuery.refetch();
    const comments: Comment[] =
      (result.data?.metadata.find((data) => data.scope === "comments")?.data as Comment[]) ?? [];
    const newMessages = comments.filter((message) => message.id !== messageId);

    if (result.data) {
      const metadataIndex = result.data?.metadata.findIndex((data) => data.scope === "comments");
      if (metadataIndex !== -1) {
        const updatedMetadata = [...result.data.metadata];
        updatedMetadata[metadataIndex] = {
          scope: "comments",
          data: [...newMessages],
        };
        const updatedTransaction = {
          ...result.data,
          metadata: updatedMetadata,
        };

        await updateTransaction({ transactionId: transactionId, body: updatedTransaction });
        setValue("messages", newMessages);
      }
    }

    setIsDeleting(false);
  };

  useEffect(() => {
    const possibleComments = transaction?.metadata.find((data) => data.scope === "comments")?.data;

    if (possibleComments && Array.isArray(possibleComments)) {
      setValue("messages", possibleComments);
    } else {
      setValue("messages", []);
    }
  }, [setValue, transaction]);

  return (
    <>
      {transaction && (
        <FormProvider {...methods}>
          <Header>{t("elements.messenger.header")}</Header>
          <StyledForm
            className={statusOffer ? "vertical" : ""}
            name="messenger"
            onSubmit={handleSubmit(handleSendMessage)}
          >
            <MessengerContainer className={statusOffer ? "vertical" : ""}>
              {messages.map((message) => (
                <MessageContainer
                  key={message.id}
                  align={productId === message.role ? "flex-start" : "flex-end"}
                >
                  <MessageBubble>
                    <MessageHeader>
                      <Name>{message.userName}</Name>
                      <Hour>{moment(message.timestamp).format("DD.MM.YY - HH:mm")}</Hour>
                    </MessageHeader>
                    <MessageBottomContainer>
                      <p>{message.msg}</p>
                      {canBeDeleted(productId, message.timestamp) && (
                        <IconButton
                          color={"primary"}
                          disabled={isDeleting || isSubmitting}
                          size="small"
                          onClick={() => handleDeleteMessage(message.id)}
                        >
                          <PersonRemoveOutlinedIcon />
                        </IconButton>
                      )}
                    </MessageBottomContainer>
                  </MessageBubble>
                </MessageContainer>
              ))}
            </MessengerContainer>
            <>
              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ marginBottom: statusOffer ? 0 : 1, marginTop: 3 }}
                    disabled={isSubmitting || isDeleting}
                    size="small"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    label={t("elements.messenger.placeholder")}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={isSubmitting || isDeleting}
                            edge="end"
                            onClick={handleSubmit(handleSendMessage)}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </>
          </StyledForm>
        </FormProvider>
      )}
    </>
  );
};
