import React, { useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { matchSorter } from "match-sorter"; // A great library for fuzzy filtering/sorting items

import MenuItem from "@mui/material/MenuItem";

import { StyledTextField, StyledSelect } from "./styled.tableFilterHelpers";
import { useTranslation } from "react-i18next";

interface GlobalFiletProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

// Define a default UI for global filtering
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: GlobalFiletProps) => {
  const { t } = useTranslation();
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  let text = "";
  if (count === 0) {
    text = t("de/common:document_table.result0");
  } else if (count === 1) {
    text = `${count} ${t("de/common:document_table.result")}`;
  } else {
    text = `${count} ${t("de/common:document_table.results")}`;
  }

  return (
    <span>
      {t("de/common:document_table.search")}:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={text}
        style={{
          fontSize: "15px",
          border: "0",
          padding: "10px",
        }}
      />
    </span>
  );
};

interface DefaultColumnFilterProps {
  column: {
    filterValue: any;
    preFilteredRows: any;
    setFilter: any;
  };
  placeholder: any;
}
// Define a default UI for column filtering
export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
  placeholder,
}: DefaultColumnFilterProps) => {
  //const datie = t("de/common:document_table.data_name");

  return (
    <StyledTextField
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={placeholder}
      sx={{ width: "100%", marginTop: "10px" }}
    />
  );
};

interface SelectColumnFilterProps {
  column: {
    filterValue: any;
    setFilter: any;
    preFilteredRows: any;
    id: string;
  };
}
// This is a custom filter UI for selecting a unique option from a list
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: SelectColumnFilterProps) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set<string>();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <StyledSelect
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      sx={{ width: "100%", marginTop: "10px" }}
    >
      <MenuItem value="">Alle</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

interface Row {
  values: {
    [key: string]: any;
  };
}

export const fuzzyTextFilterFn = (rows: Row[], id: string, filterValue: string): string[] => {
  return matchSorter(rows, filterValue, { keys: [(row) => String(row.values[id])] }).map((row) =>
    String(row.values[id])
  );
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;
