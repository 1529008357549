import { styled } from "@mui/material/styles";
import { lighten } from "@mui/system";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100%",

  display: "flex",
  justifyContent: "center",
}));

export const CatHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.7),
}));

export const IndItems = styled("ul")({
  padding: 0,
});
