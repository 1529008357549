import React from "react";
import { useParams } from "react-router-dom";

import { FadeIn } from "../../../../../elements/frontend/src/components";

import DetailsAdvisor from "../../../../../components/DetailsAdvisor/DetailsAdvisor";
import OverviewAdvisor from "../../../../../components/OverviewAdvisor/OverviewAdvisor";
import StatusAdvisor from "../../../../../components/StatusAdvisor/StatusAdvisor";
import StatusOffer from "../../../../../components/StatusOffer";
import { Wrapper } from "./styled.contentAdvisor";
import { useAppSelector } from "../../../../../elements/frontend/src/Store/hooks/useAppSelector";
import { DueDiligence } from "../../../../../elements/frontend/src/components/DueDiligence/DueDiligence";
import { Commercial } from "../../../../../elements/frontend/src/components/DueDiligence/components/Commercial/Commercial";
import InvestorMatching from "../../../../../components/InvestorMatching/InvestorMatching";
import { InvestorStatusTable } from "../../../../../elements/frontend/src/components/InvestorStatusTable/InvestorStatusTable";

const ContentAdvisor = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const params = useParams();
  const step = params.step;
  const hasParams = Object.keys(params).length > 0;
  const productId = currentUser.customer.subscription.product_id;

  const newTransactionOffers = currentUser.transactions.filter(
    (item: any) =>
      // item?.state?.status?.current_stage === matching &&
      // item?.state?.status?.next_steps[0] === accepted

      productId in item && !("user_id" in item[productId]) && item
  );

  const offer = newTransactionOffers.filter(
    (item: { transaction_id: string | undefined }) => item.transaction_id === params.transactionId
  );

  return (
    <Wrapper>
      <FadeIn>
        {!hasParams && <OverviewAdvisor />}
        {hasParams && step === "status" && offer.length > 0 && <StatusOffer />}
        {hasParams && step === "status" && offer.length === 0 && <StatusAdvisor />}
        {hasParams && step === "details" && <DetailsAdvisor />}
        {hasParams && step === "due-diligence-legal" && <DueDiligence />}
        {hasParams && step === "due-diligence-commercial" && <Commercial />}
        {hasParams && step === "investor-matching" && <InvestorMatching />}
        {hasParams && step === "investor-status" && <InvestorStatusTable />}
      </FadeIn>
    </Wrapper>
  );
};

export default ContentAdvisor;