import React, { useState } from "react";
import { Card, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
//import { DashbordContext } from "../../../views/Private/Home";
import { Controller, useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "../interfaces";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { CurrencyFormatter } from "../../../elements/frontend/src/formatter/CurrencyFormatter/CurrencyFormatter";

interface AdditionalInfoProps {
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>, mainField: string) => void;
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = ({ handleDataChange }) => {
  const { t } = useTranslation(["company/common"]);
  //const dashboardContext: any = useContext(DashbordContext);

  const { control } = useFormContext<OnboardingFormValues>();

  //checking if the Founder year is valid with only positive numbers not later than the current year
  const [invalidFounderYear, setInvalidFounderYear] = useState(false);
  const handleFounderYearInput = (e: any) => {
    const val = e.target.value;
    const yearVal = val.replace(/[^0-9]/g, "");

    const currentDate = new Date();
    const recentYear = currentDate.getFullYear();
    if (Number(yearVal) > recentYear) {
      setInvalidFounderYear(true);
    } else setInvalidFounderYear(false);

    e.target.value = yearVal;
  };

  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("advisor/common:content.advisor.second_headline")}>
        <Card>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            {/*** FOUNDATION ***/}
            <FormControl fullWidth size="small">
              <Controller
                name="foundation"
                control={control}
                render={({ fieldState: { error }, field: { value, onChange } }) => (
                  <TextField
                    label={t("advisor/common:content.advisor.foundation")}
                    name="foundation"
                    id="advisor_initial_foundation"
                    //value={dashboardContext.profile.foundation || ""}
                    value={value}
                    size="small"
                    required
                    fullWidth
                    error={!!error?.message || invalidFounderYear}
                    helperText={error?.message}
                    onChange={(e) =>
                      handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "profile")
                    }
                    inputProps={{
                      autoComplete: "new-foundation",
                      form: {
                        autoComplete: "off",
                      },
                      maxLength: 4,
                      onInput: (e) => {
                        handleFounderYearInput(e);
                        const target = e.target as HTMLInputElement;
                        const yearVal = target.value.replace(/[^0-9]/g, "");
                        onChange(yearVal);
                        target.value = yearVal;
                      },
                    }}
                  />
                )}
              />
              {invalidFounderYear && (
                <FormHelperText color="error">
                  {t("details_a3.company_card.founding_year_invalid")}
                </FormHelperText>
              )}
            </FormControl>

            {/*** LOCATIONS ***/}
            <Controller
              name="locations"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  label={t("advisor/common:content.advisor.locations")}
                  name="locations"
                  id="advisor_initial_locations"
                  // value={dashboardContext.profile.locations || ""}
                  value={value}
                  size="small"
                  required
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                  onChange={(e) =>
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "profile")
                  }
                  inputProps={{
                    autoComplete: "new-locations",
                    form: {
                      autoComplete: "off",
                    },
                    onInput: (e) => {
                      const target = e.target as HTMLInputElement;
                      const onlyString = target.value.replace(/[^A-Za-z\s]/g, "");
                      onChange(onlyString);
                      target.value = onlyString;
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
            {/*** CONSULTANT ***/}
            <Controller
              name="consultant"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  label={t("advisor/common:content.advisor.consultant")}
                  name="consultant"
                  id="advisor_initial_consultant"
                  //value={dashboardContext.profile.consultant || ""}
                  value={value}
                  size="small"
                  required
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                  onChange={(e) =>
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "profile")
                  }
                  InputProps={{ inputComponent: CurrencyFormatter as any }}
                  inputProps={{
                    currency: "",
                    autoComplete: "new-consultant",
                    form: {
                      autoComplete: "off",
                    },
                    maxLength: 7,
                    onInput: (e) => {
                      const target = e.target as HTMLInputElement;
                      const onlyNum = target.value.replace(/[^0-9]/g, "");
                      onChange(onlyNum);
                      target.value = onlyNum;
                    },
                  }}
                />
              )}
            />

            {/*** TRANS_NUMBER ***/}
            <Controller
              name="trans_number"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  label={t("advisor/common:content.advisor.trans_number")}
                  name="trans_number"
                  id="advisor_initial_trans_number"
                  //value={dashboardContext.profile.trans_number || ""}
                  value={value}
                  size="small"
                  required
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                  onChange={(e) =>
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "profile")
                  }
                  InputProps={{ inputComponent: CurrencyFormatter as any }}
                  inputProps={{
                    currency: "",
                    autoComplete: "new-trans_number",
                    form: {
                      autoComplete: "off",
                    },
                    onInput: (e) => {
                      const target = e.target as HTMLInputElement;
                      const onlyNum = target.value.replace(/[^0-9]/g, "");
                      onChange(onlyNum);
                      target.value = onlyNum;
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Card>
      </Section>
    </>
  );
};
