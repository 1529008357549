import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    gridArea: "dashboardInitial",
    overflowY: "scroll",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
});
