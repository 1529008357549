import React from "react";
import { Logo } from "../../elements/frontend/src/components";

import UserMenu from "./UserMenu/UserMenu";
import ContentSelect from "./ContentSelect/ContentSelect";
import { Wrapper } from "./styled.topbar";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";

const TopBar = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  return (
    <Wrapper>
      <Logo />
      {currentUser.advisor_approved && <ContentSelect />}
      <UserMenu />
    </Wrapper>
  );
};

export default TopBar;
