import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";

import { useTheme } from "@mui/material/styles";

import { LoadingIndicator } from "../";
import { thousandSeparator } from "../../common";

import { DashbordContext } from "../../../../../views/Private/Home";
import { BaseInfoTable } from "./BaseInfoTable.js";
import { TableWrapper } from "./styled.tableComp";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { ProfileMetadata, RegistrationMetadata, Transaction } from "../../Store/api";
import { CategoryOption, CategoryType } from "../CategoriesDropdown/interfaces";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../CategoriesDropdown/CategoriesDropdown.utils";
import { useGetClientByIdQuery, useUpdateClientMutation } from "../../Store/api/clients/clientsApi";
import { useGetCategoryTreesQuery } from "../../Store/api/datastore/categories/categoriesApi";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../Store/api/transactions/transactionsApi";

interface StatusBaseInfoTableProps {
  statusOffer: boolean;
  transactionId: string;
  updateTransactionState: (transaction: Transaction) => void;
}

export const StatusBaseInfoTable = ({
  statusOffer,
  transactionId,
  updateTransactionState,
}: StatusBaseInfoTableProps) => {
  const transactionByIdQuery = useGetTransactionByIdQuery({
    transactionId,
  });
  const transaction = transactionByIdQuery.data;
  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const clientByIdQuery = useGetClientByIdQuery(
    {
      clientId: transaction?.client_id!,
    },
    { skip: !transaction }
  );
  const client = clientByIdQuery.currentData;

  const isClientLoading = clientByIdQuery.isLoading;
  const [updateClient] = useUpdateClientMutation();

  const [updateTransaction] = useUpdateTransactionMutation();

  const { t } = useTranslation();
  const theme = useTheme();

  const dashboardContext = useContext(DashbordContext);

  const dispatch = useAppDispatch();

  // to prevent advisor change in select, if the transaction belongs to advisor client
  const isAdvisorClient = !transaction?.state?.stages?.hasOwnProperty("adviser_matching"); // Advisor client transaction does not have "advisor_matching" stage

  const selectedCategoryIds = useMemo(() => {
    const scope_profile = client?.metadata?.find((item) => item.scope === "profile") as
      | ProfileMetadata
      | undefined;

    if (scope_profile) {
      return scope_profile.data.categories;
    }
    return [];
  }, [client?.metadata]);

  const selectedSubCategoryIds = useMemo(() => {
    const scope_profile = client?.metadata?.find((item) => item.scope === "profile") as
      | ProfileMetadata
      | undefined;
    if (scope_profile && scope_profile.data.sub_categories) {
      return scope_profile.data.sub_categories;
    }
    return [];
  }, [client?.metadata]);

  const handleCategoriesSelect = async (categories: CategoryOption[], type: CategoryType) => {
    try {
      const categoryIds = categories.map((category) => category.value);
      const hasProfileScope = client?.metadata.find((data: any) => data.scope === "profile");
      let metadata;

      if (hasProfileScope) {
        metadata = client?.metadata.map((metaData: any) => {
          if (metaData.scope === "profile") {
            if (type === "category") {
              return {
                ...metaData,
                data: {
                  ...metaData,
                  categories: categoryIds,
                  sub_categories: categoryTrees
                    ? removeAllChildSubCategoriesWithoutSelectedRootCategory(
                        categoryIds,
                        selectedSubCategoryIds,
                        categoryTrees
                      )
                    : selectedSubCategoryIds,
                },
              } as ProfileMetadata;
            }

            if (type === "subCategory") {
              return { ...metaData, data: { ...metaData, sub_categories: categoryIds } };
            }
          }

          return metaData;
        });
      } else {
        metadata = [
          ...(client?.metadata ? client?.metadata : []),
          { scope: "profile", data: { categories: categoryIds, sub_categories: [] } },
        ];
      }

      const updatedClientData = { ...client, metadata };

      if (client) {
        dispatch(setCurrentUser({ loading: true }));
        await updateClient({ clientId: client.client_id, body: updatedClientData });
        dispatch(setCurrentUser({ loading: false }));
      }
    } catch (error) {}
  };

  // to calculate the days from company client registration up to now
  const daysFromRegister = useCallback(() => {
    const scope_register = client?.metadata?.find((item) => item.scope === "registration") as
      | RegistrationMetadata
      | undefined;
    if (scope_register) {
      const register_date = scope_register.data.created_at;
      const day_delta = new Date().getTime() - register_date;
      const result = Math.round(day_delta / 86400000);
      return result === 0 || result === 1 ? "1 Tag" : `${result} Tage`; // need to fix this assignment later !!!!!!!!!
    }
  }, [client?.metadata]);

  // data for table rows
  const baseInformation = useMemo(() => {
    return [
      {
        value: transaction?.supervisor?.user_name,
        name: "supervisor",
        field: t("transaction_tables.base_info_table.fields.supervisor"),
      },
      {
        value: transaction?.advisor?.customer_name,
        name: "advisor",
        field: t("transaction_tables.base_info_table.fields.advisor"),
      },
      {
        value: transaction?.advisor?.user_name || "-",
        name: "advisor_contact",
        field: t("transaction_tables.base_info_table.fields.advisor_contact"),
      },
      {
        value: transaction?.advisor?.user_telephone || "-",
        name: "advisor_telephone",
        field: t("transaction_tables.base_info_table.fields.advisor_telephone"),
      },
      {
        value: selectedCategoryIds,
        name: "branch",
        field: t("transaction_tables.base_info_table.fields.branch"),
      },
      {
        value: "-",
        name: "transaction_profile",
        field: t("transaction_tables.base_info_table.fields.transaction_profile"),
      },
      {
        value:
          `${thousandSeparator(transaction?.invest?.min_cap)}  -  ${thousandSeparator(
            transaction?.invest?.max_cap
          )}` || "-",
        name: "transaction_volume",
        field: t("transaction_tables.base_info_table.fields.transaction_volume"),
      },
      {
        value: `${new Date(
          (
            client?.metadata?.find((item) => item.scope === "registration") as RegistrationMetadata
          )?.data.created_at
        ).toLocaleDateString()}   -   ${daysFromRegister()}`,
        name: "register_date",
        field: t("transaction_tables.base_info_table.fields.register_date"),
      },
      !statusOffer
        ? {
            value:
              client?.subscription?.product_id === "company"
                ? t("transaction_tables.base_info_table.self_registration")
                : t("transaction_tables.base_info_table.advisor_registration"),
            name: "register_source",
            field: t("transaction_tables.base_info_table.fields.register_source"),
          }
        : {},
      {
        value:
          (client?.metadata?.find((item) => item.scope === "profile") as ProfileMetadata)?.data
            ?.website || "-",
        name: "website",
        field: t("transaction_tables.base_info_table.fields.website"),
      },
    ];
  }, [
    transaction?.supervisor?.user_name,
    transaction?.advisor?.customer_name,
    transaction?.advisor?.user_name,
    transaction?.advisor?.user_telephone,
    transaction?.invest?.min_cap,
    transaction?.invest?.max_cap,
    t,
    selectedCategoryIds,
    client?.metadata,
    client?.subscription?.product_id,
    daysFromRegister,
    statusOffer,
  ]);

  // to convert supervisor and advisor's name to id
  const convertNameToId = useCallback(
    (name: string, user: string) => {
      let id;

      if (name === t("transaction_tables.none")) {
        id = "undefined";
        return id;
      }

      if (user === "supervisor") {
        const supervisor: any = (dashboardContext as any).supervisorOptions.find(
          (item: any) => item.name === name
        );
        if (supervisor) {
          id = supervisor.user_id;
        } else {
          id = "undefined";
        }
      } else if (user === "advisor") {
        const advisor: any = (dashboardContext as any).advisorOptions.find(
          (item: any) => item.name === name
        );
        if (advisor) {
          id = advisor.customer_id;
        } else {
          id = "undefined";
        }
      }
      return id;
    },
    [dashboardContext, t]
  );

  // handle change event in EditableSelect
  const handleSelectChangeEvent = async (e: any) => {
    try {
      const value = e.target.value;
      const field = e.target.name;

      dispatch(setCurrentUser({ loading: true }));

      // it is either user_id or customer_id, according to columnId
      const id = convertNameToId(value, field);

      const objectToUpdate: Partial<Transaction> =
        field === "supervisor"
          ? {
              supervisor: {
                ...transaction?.supervisor,
                user_id: id,
              },
              metadata: [
                {
                  scope: "state",
                  data: {
                    supervisor: id !== "undefined",
                  },
                },
              ],
            }
          : {
              advisor: {
                ...transaction?.advisor,
                customer_id: id,
                user_id: "undefined",
              },
              metadata: [
                {
                  scope: "state",
                  data: {
                    advisor_matching: id !== "undefined",
                  },
                },
              ],
            };

      const response = await updateTransaction({ transactionId, body: objectToUpdate }).unwrap();

      if (response) {
        updateTransactionState(response);
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return (
    <>
      {isClientLoading && <LoadingIndicator type={"COMPONENT"} />}
      {!isClientLoading && (
        <Box
          sx={{
            //border: "2px solid red",
            marginTop: "20px",
          }}
        >
          <TableWrapper style={{ width: "auto" }}>
            <caption>{t("transaction_tables.base_info_table.caption")}</caption>
            <tbody>
              {baseInformation &&
                // eslint-disable-next-line array-callback-return
                baseInformation.map((item, index) => {
                  if (item.value) {
                    return (
                      <tr key={index}>
                        <th style={{ maxWidth: "30%" }}>{item.field}</th>
                        <td style={{ maxWidth: "15rem" }}>
                          <BaseInfoTable
                            value={{
                              transaction,
                              item,
                              isAdvisorClient,
                              dashboardContext,
                              handleSelectChangeEvent,
                              theme,
                              selectedCategoryIds,
                              selectedSubCategoryIds,
                              handleCategoriesSelect,
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </TableWrapper>
        </Box>
      )}
    </>
  );
};
