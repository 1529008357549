import React from "react";
import { FadeIn } from "../../../elements/frontend/src/components";

import InitialContent from "./Initial/InitialContent";
import AdvisorContent from "./Advisor/AdvisorContent";
import { Wrapper } from "./styled.content";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";

const Content = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId = currentUser.customer.subscription.product_id;

  return (
    <Wrapper>
      <FadeIn>
        {productId !== "lawyer" &&
          !currentUser.advisor_approved &&
          currentUser.current_main_content === "initial_content" && <InitialContent />}
        {productId === "lawyer" ||
        (currentUser.advisor_approved && currentUser.current_main_content === "advisor_content") ? (
          <AdvisorContent />
        ) : (
          ""
        )}
        {/* {currentUser.advisor_approved && currentUser.current_main_content === "client_content" && ( */}
        {/*   <ClientContent /> */}
        {/* )} */}
      </FadeIn>
    </Wrapper>
  );
};

export default Content;
