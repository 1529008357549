import { Card, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingFormValues } from "../interfaces";
import { useGetCountriesQuery } from "../../../elements/frontend/src/Store/api/datastore/countries/countriesApi";
import { countriesDataArrProps } from "../interfaces";
interface CompanyProps {
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>, mainField: string) => void;
  dashboardContext: any;
}
export const Company: React.FC<CompanyProps> = ({ handleDataChange, dashboardContext }) => {
  const { t } = useTranslation(["advisor/common"]);
  const { control } = useFormContext<OnboardingFormValues>();
  const countriesQuery = useGetCountriesQuery();
  const countries = countriesQuery.data;
  const [trigger, setTrigger] = useState(false);

  // Country options for select "Land"
  const countriesData: { [key: string]: string } = t("advisor/common:content.countries", {
    returnObjects: true,
  });

  const countriesDataArr: countriesDataArrProps[] = Object.keys(countriesData).map((code) => ({
    code,
    name: countriesData[code],
  }));

  const sortCountries = countriesDataArr.slice().sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("advisor/common:content.company.headline")}>
        <Card>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
            <Controller
              name="company_name"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <TextField
                  name="company_name"
                  label={t("advisor/common:content.company.company_name")}
                  value={dashboardContext.company_name || ""}
                  size="small"
                  fullWidth
                  required
                  onChange={(e) => {
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "company_name");
                  }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-company",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <Controller
              name="street"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="street"
                  label={t("advisor/common:content.company.street")}
                  value={dashboardContext?.address?.street || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => {
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "address");
                  }}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    const inputValue = target.value;
                    const containNumAndText = /[a-zA-Z]/.test(inputValue) && /\d/.test(inputValue);
                    if (!containNumAndText) {
                      setTrigger(true);
                    } else {
                      setTrigger(false);
                    }
                    onChange(inputValue);
                  }}
                  error={!!error || trigger}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-street",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />

            <Controller
              name="postal"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="postal"
                  label={t("advisor/common:content.company.postal_code")}
                  value={
                    dashboardContext.address.country === "DEU"
                      ? dashboardContext.address.postal.substring(0, 5)
                      : dashboardContext.address.postal || ""
                  }
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => {
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "address");
                  }}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    const onlyNums = target.value.replace(/[^0-9]/g, "");
                    onChange(onlyNums);
                    target.value = onlyNums;
                  }}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-postal",
                    form: {
                      autoComplete: "off",
                    },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: dashboardContext.address.country === "DEU" ? 5 : undefined,
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <Controller
              name="city"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="city"
                  label={t("advisor/common:content.company.city")}
                  value={dashboardContext?.address?.city || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => {
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "address");
                  }}
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-city",
                    form: {
                      autoComplete: "off",
                    },
                    onInput: (e) => {
                      const target = e.target as HTMLInputElement;
                      const onlyString = target.value.replace(/[^A-Za-z]/g, "");
                      onChange(onlyString);
                      target.value = onlyString;
                    },
                  }}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <>
                  <FormControl fullWidth size="small">
                    <InputLabel id="country" required>
                      {t("advisor/common:content.company.country")}
                    </InputLabel>
                    <Select
                      name="country"
                      labelId="country"
                      value={dashboardContext.address.country || ""}
                      label={t("advisor/common:content.company.country")}
                      onChange={(e) => {
                        handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "address");
                      }}
                      error={!!error}
                      MenuProps={{
                        PaperProps: {
                          style: { marginTop: "0.1%", maxHeight: "85vh" },
                        },
                      }}
                    >
                      {sortCountries.map((sortCountry) => (
                        <MenuItem key={sortCountry.code} value={sortCountry.code}>
                          {countries
                            ?.filter((country) => country.value === sortCountry.code)
                            .map((country) => (
                              <div key={country.value} style={{ width: "20rem" }}>
                                {sortCountry.name}
                              </div>
                            ))}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!error?.message && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          </Stack>
        </Card>
      </Section>
    </>
  );
};
