import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { SecondaryDialog } from "../../../elements/frontend/src/components/SecondaryDialog/SecondaryDialog";
import {
  UserManagementDialog,
  LoadingIndicator,
  FadeIn,
} from "../../../elements/frontend/src/components";
import { UserProfileDialog } from "../../../elements/frontend/src/components/UserProfileDialog/UserProfileDialog";
import { useAccounts } from "../../../elements/frontend/src/hooks";
import { CustomerProfileDialog } from "../../../elements/frontend/src/components/ContactTable/CustomerProfileDialog/CustomerProfileDialog";

import {
  Wrapper,
  User,
  CompanyName,
  UserName,
  ListWrapper,
  List,
  ListItem,
} from "./styled.usermenu";

import TConfig from "../../../config";
import { setCurrentUser } from "../../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";

const UserMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const { deleteAccount } = useAccounts();

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isCustomerProfileDialogOpen, setIsCustomerProfileDialogOpen] = useState(false);
  const [isUserManagementDialogOpen, setIsUserManagementDialogOpen] = useState(false);
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] = useState(false);
  const [isConfirmDeleteAccountDialogOpen, setIsConfirmDeleteAccountDialogOpen] = useState(false);

  const companyName = currentUser.customer.name;
  const first_name = currentUser.user.first_name ? currentUser.user.first_name : "";
  const last_name = currentUser.user.last_name ? currentUser.user.last_name : "";
  const userName = first_name && last_name ? `${first_name} ${last_name}` : currentUser.user.alias;

  const dispatch = useDispatch();

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isMenuVisible) {
      let handler = (e: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
          setIsMenuVisible(false);
        }
      };
      document.addEventListener("mousedown", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  });

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleLogOut = () => {
    navigate("/logout");
  };

  //to close delete dialog
  const handleDeleteDialogClose = () => {
    setIsConfirmDeleteAccountDialogOpen(false);
  };
  // for delete account with logout
  const handleDeleteAccount = () => {
    setIsConfirmDeleteAccountDialogOpen(false);
    dispatch(setCurrentUser({ loading: true }));
    deleteAccount()
      .then(() => {
        handleLogOut();
      })
      .catch(() => {
        dispatch(setCurrentUser({ loading: false }));
      });
  };

  const handleAccountDeleteDialog = () => {
    setIsMenuVisible(false);
    setIsConfirmDeleteAccountDialogOpen(true);
    setIsUserManagementDialogOpen(false);
    setIsCustomerProfileDialogOpen(false);
  };
  // for customerProfile Dialog
  const handleCustomerProfileDialogClose = () => {
    setIsCustomerProfileDialogOpen(false);
  };
  const handleCustomerProfileDialog = () => {
    setIsMenuVisible(false);
    setIsCustomerProfileDialogOpen(true);
    setIsConfirmDeleteAccountDialogOpen(false);
    setIsUserManagementDialogOpen(false);
  };

  // for userManagement Dialog
  const handleUserManagementDialogClose = () => {
    setIsUserManagementDialogOpen(false);
  };
  const handleUserManagementDialog = () => {
    setIsMenuVisible(false);
    setIsUserManagementDialogOpen(true);
    setIsConfirmDeleteAccountDialogOpen(false);
    setIsCustomerProfileDialogOpen(false);
  };

  // for userProfile Dialog
  const handleUserProfileDialogClose = () => {
    setIsUserProfileDialogOpen(false);
  };
  const handleUserProfileDialog = () => {
    setIsMenuVisible(false);
    setIsUserProfileDialogOpen(true);
  };

  return (
    <div>
      <Wrapper>
        <User>
          <CompanyName onClick={handleToggleMenu}>
            <span className="company">
              {companyName ? companyName : <LoadingIndicator type={"TEXT"} length={10} />}
            </span>

            <ExpandMoreRoundedIcon fontSize={isMobile ? "small" : "medium"} />
          </CompanyName>
          <UserName>
            {userName ? userName : <LoadingIndicator type={"TEXT"} length={10} />}
          </UserName>

          {isMenuVisible && (
            <FadeIn>
              <ListWrapper ref={menuRef}>
                <List>
                  {currentUser.user.root && (
                    <ListItem onClick={handleCustomerProfileDialog}>
                      {t("top_bar.customer_profile_dialog.headline")}
                    </ListItem>
                  )}
                  {currentUser.user.root && (
                    <ListItem onClick={handleUserManagementDialog}>
                      {t("top_bar.user_management_dialog.headline")}
                    </ListItem>
                  )}
                  {currentUser.user.root && (
                    <ListItem onClick={handleAccountDeleteDialog}>
                      {t("top_bar.account_dialog.delete_account", {})}
                    </ListItem>
                  )}
                  {!currentUser.user.root && (
                    <ListItem onClick={handleUserProfileDialog}>
                      {t("top_bar.user_profile_dialog.headline")}
                    </ListItem>
                  )}
                  <ListItem onClick={handleLogOut}>{t("top_bar.logout")}</ListItem>
                </List>
              </ListWrapper>
            </FadeIn>
          )}
        </User>
      </Wrapper>

      {/* Dialog for Customer Profile */}
      <CustomerProfileDialog
        dialogOpen={isCustomerProfileDialogOpen}
        handleDialogClose={handleCustomerProfileDialogClose}
        product={TConfig.defaults.PRODUCT_ID}
      />
      {/* Dialog for User Management */}
      <UserManagementDialog
        dialogOpen={isUserManagementDialogOpen}
        handleDialogClose={handleUserManagementDialogClose}
      />
      <SecondaryDialog
        type="warning"
        dialogTitle={t("top_bar.account_dialog.delete_account")}
        contentText={
          <Trans
            i18nKey="top_bar.account_dialog.deleteDialog_content_Text"
            values={{ companyName }}
          />
        }
        secondaryDialogOpen={isConfirmDeleteAccountDialogOpen}
        secondaryDialogClose={handleDeleteDialogClose}
        eventHandler={handleDeleteAccount}
        actionButtonText={t("platform/common:misc.delete")}
        isMenuVisible={isMenuVisible}
      />
      {/* Dialog for User Profile */}
      <UserProfileDialog
        dialogOpen={isUserProfileDialogOpen}
        handleDialogClose={handleUserProfileDialogClose}
      />
    </div>
  );
};

export default UserMenu;
