import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//import parsePhoneNumber from "libphonenumber-js";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAccounts } from "../../elements/frontend/src/hooks";

import { DashbordContext } from "../../views/Private/Home";
import { DashboardDispatch } from "../../views/Private/Home";
import { Wrapper } from "./styled.onboarding";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";

import { Profile } from "./components/Profile";
import { AdditionalInfo } from "./components/AdditionalInfo";
import { CountriesProps, OnboardingFormValues } from "./interfaces";
import { Company } from "./components/Company";
import { Contact } from "./components/Contact";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OnboardingSchema } from "./OnboardingSchema";

const getInitialFormValues = (context: any): OnboardingFormValues => {
  return {
    company_name: context.company_name,
    postal: context.address.postal,
    street: context.address.street,
    city: context.address.city,
    country: context.address.country,

    first_name: context.contact.first_name,
    last_name: context.contact.last_name,
    telephone: context.contact.telephone,

    phases: context.advisor.phases ? context.advisor.phases : [],
    subCategories: context.profile.sub_categories ? context.profile.sub_categories : [],
    categories: context.profile.categories ? context.profile.categories : [],
    foundation: context.profile.foundation,
    locations: context.profile.locations,
    consultant: context.profile.consultant,
    trans_number: context.profile.trans_number,
    region: context.profile.region,
  };
};


const OnBoarding = () => {
  const { t } = useTranslation(["advisor/common"]);
  // For the moment account data will be updated, later it will be converted with client functions
  const { updateAccount } = useAccounts();

  const [initialLoad, setInitialLoad] = useState(true);
  const [profileError, setProfileError] = useState(false);

  const dashboardContext: any = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const dispatch = useDispatch();

  const countries: CountriesProps = t("advisor/common:content.countries", {
    returnObjects: true,
  });

  const methods = useForm({
    mode: "all",
    defaultValues: getInitialFormValues(dashboardContext),
    resolver: yupResolver(OnboardingSchema(t)),
  });

  const { handleSubmit, control } = methods;
  

  //because dashboardContext is empty first time load, we need to refill methods initial values once again
  useEffect(() => {
    initialLoad && methods.reset(getInitialFormValues(dashboardContext));
  }, [dashboardContext, methods, initialLoad]);

  const { isValid } = methods.formState;
  const allFieldsAreValid = isValid && !profileError;

  const handelErrorFocus = (control: any): void => {
    setTimeout(() => {
      const componentName = Object.keys(control._formState.errors)[0];
      if (componentName) {
        const fieldElement = document.getElementsByName(componentName)[0];
        if (fieldElement) {
          fieldElement.focus();
        }
      }
    }, 20);
  };

  // handle change event
  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>,  mainField: string) => {
    const field = e.target.name;
    const val = e.target.value;

    if (mainField === "company_name") {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          [field]: val,
        },
      });
    } else {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          [mainField]: {
            ...dashboardContext[mainField],
            [field]: val,
          },
        },
      });
    }
  };
  //handle change event in contact telephone
  // const handlePhoneChange = (value: string) => {
  //   dashboardDispatch({
  //     type: "UPDATE_DATA",
  //     payload: {
  //       contact: {
  //         ...dashboardContext.contact,
  //         telephone: value,
  //       },
  //     },
  //   });
  // };

  // handle change event in Autocomplete elements
  const handleAutoCompleteSelect = ( value: any[], field: string) => {
    if (value) {
      // if (value && value.length > 0) {
      //   const formField = field as keyof OnboardingFormValues;
      //   if (formField === "phases") {
      //     const typedValue = value[0].value;
      //     setValue(formField, typedValue || "");
      //   }
      // }
      const newValue = value.map((item: any) => (field === "region" ? item.code : item.value));
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          advisor: {
            ...dashboardContext.advisor,
            [field]: newValue,
          },
        },
      });
    }
  };

  // handle change event in advisor categories
  const handleCategoriesSelect = (value: any[], type: string) => {
    // handle change for first Autocomplete , which is "categories"
    if (type === "category") {
      const newValue = value.map((item) => item.value);
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      const uniqueNewValue: any = [...new Set(newValue?.map((item) => item))];
      const uniqueContext: any = [...new Set(dashboardContext.advisor.categories)];

      // if item is removed from categories. It is for old customers
      if (uniqueContext.length > uniqueNewValue.length) {
        const filteredNewValues = dashboardContext.advisor.categories.filter((item: any) =>
          uniqueNewValue.includes(item)
        );

        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            advisor: {
              ...dashboardContext.advisor,
              categories: filteredNewValues,
            },
          },
        });

        // if new item is added to categories. It is for old customers
      } else if (uniqueContext.length < uniqueNewValue.length) {
        let difference = uniqueNewValue.filter((x: any) => !uniqueContext.includes(x));
        const newValueArray = [...dashboardContext.advisor.categories, ...difference];

        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            advisor: {
              ...dashboardContext.advisor,
              categories: newValueArray,
            },
          },
        });

        // It is for new customers
      } else {
        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            advisor: {
              ...dashboardContext.advisor,
              categories: [...uniqueContext, ...uniqueNewValue],
            },
          },
        });
      }
    }
  };

  const handleUpdateAccountData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (dashboardContext.form_state.initial) {
        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            form_state: {
              initial: false,
              valid: dashboardContext.form_state.valid,
            },
          },
        });
      }

      if (!allFieldsAreValid) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      if (allFieldsAreValid !== dashboardContext.form_state.valid) {
        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            form_state: {
              valid: allFieldsAreValid,
              initial: false,
            },
          },
        });
      }

      if (allFieldsAreValid) {
        // to handle dashboard sidebar steps
        const lastStep =
          dashboardContext.inital_content.lastStep === "ONBOARDING"
            ? "PERSONAL_TALK"
            : dashboardContext.inital_content.lastStep;
        const currentStep =
          dashboardContext.inital_content.lastStep === "ONBOARDING"
            ? "PERSONAL_TALK"
            : dashboardContext.inital_content.lastStep;
        const completedSteps =
          dashboardContext.inital_content.completedSteps.length === 0
            ? ["ONBOARDING"]
            : dashboardContext.inital_content.completedSteps;

        const objToSave = {
          name: dashboardContext.company_name,
          address: {
            line_1: dashboardContext.address.street || "",
            postal: dashboardContext.address.postal || "",
            city: dashboardContext.address.city || "",
            country: dashboardContext.address.country || "",
          },
          contact: {
            first_name: dashboardContext.contact.first_name || "",
            last_name: dashboardContext.contact.last_name || "",
            //telephone: dashboardContext.contact.telephone || "",
            telephone: control._formValues.telephone
          },
          metadata: [
            {
              scope: "advisor",
              data: {
                categories: dashboardContext.advisor.categories || [],
                sub_categories: dashboardContext.advisor.sub_categories || [],
                phases: dashboardContext.advisor.phases || [],
                //phases: control._formValues.phases,
                region: dashboardContext.advisor.region || [],
              },
            },
            {
              scope: "profile",
              data: {
                foundation: dashboardContext.profile.foundation || "",
                locations: dashboardContext.profile.locations || "",
                consultant: dashboardContext.profile.consultant || "",
                trans_number: dashboardContext.profile.trans_number || "",
                lastStep,
                currentStep,
                completedSteps,
              },
            },
            {
              scope: "state",
              data: {
                data_provided: true,
              },
            },
          ],
        };

        const responseAccount = await updateAccount(objToSave);

        if (responseAccount) {
          window.location.reload();
        }
      }
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  const checkProfileError = () => {
    dashboardContext?.advisor?.phases.length === 0 || dashboardContext?.advisor?.region.length === 0 || dashboardContext?.advisor?.categories.length === 0 ? setProfileError(true): setProfileError(false);
  }

  return (
    <Wrapper>
      <Box
        component="form"
        sx={{
          px: { xs: 2, md: 4 },
          py: { xs: 1, md: 3 },
          width: "100%",
          maxWidth: theme.breakpoints.values.xl,
        }}
        onSubmit={handleSubmit(handleUpdateAccountData)}
      >
        <FormProvider {...methods}>
          {/****** COMPANY DATA  ************/}
          <Company handleDataChange={handleDataChange} dashboardContext={dashboardContext} />
          {/****** CONTACT ************/}
          <Contact
            handleDataChange={handleDataChange}
            //handlePhoneChange={handlePhoneChange}
            dashboardContext={dashboardContext}
          />
          {/********** ADVISOR PROFILE ************/}
          <Profile
            handleAutoCompleteSelect={handleAutoCompleteSelect}
            initial={initialLoad}
            setInitial={setInitialLoad}
            countries={countries}
            handleCategoriesSelect={handleCategoriesSelect}
          />

          {/********** OTHER INFORMATION ************/}
          <AdditionalInfo handleDataChange={handleDataChange} />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size={isMobile ? "small" : "medium"}
              sx={{
                px: { xs: 3, md: 6 },
                py: 1,
                mr: 1,
                mb: 5,
                mt: 2,
                fontWeight: "bold",
                color: theme.palette.primary.main,
                borderRadius: "4px",
              }}
              onClick={() => {
                //handleUpdateAccountData();
                handelErrorFocus(control);
                setInitialLoad(false);
                checkProfileError();
              }}
            >
              {t("misc.save")}
            </Button>
          </Stack>
        </FormProvider>
      </Box>
    </Wrapper>
  );
};

export default OnBoarding;
