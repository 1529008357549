import React, { useContext } from "react";

import OnBoarding from "../../../../../components/OnBoarding/Onboarding";
import PersonalTalk from "../../../../../components/PersonalTalk/PersonalTalk";

import { DashbordContext } from "../../../../../views/Private/Home";

import { Wrapper } from "./styled.contentInitial";

const ContentInitial = () => {
  const dashboardContext = useContext(DashbordContext);

  return (
    <Wrapper>
      {dashboardContext.inital_content.currentStep === "ONBOARDING" && <OnBoarding />}
      {dashboardContext.inital_content.currentStep === "PERSONAL_TALK" && <PersonalTalk />}
    </Wrapper>
  );
};

export default ContentInitial;
