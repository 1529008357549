import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateAreas: `
      "sidebar dashboardInitial"
    `,
    gridTemplateColumns: "55px 1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "280px 1fr",
    },
  };
});
