import { styled } from "@mui/material/styles";

export const ContentWrapper = styled("div")(({ theme }) => {
  return {
    margin: "20px 0",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(3, calc((100% - 40px) / 3))",
    gap: "20px",
  };
});
export const ContentItem = styled("div")(({ theme, selected }) => {
  return {
    width: "100%",
    padding: "15px",
    border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.grey[300]}`,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: selected ? theme.palette.primary.main : theme.palette.grey[400],
    "&:hover": {
      cursor: "pointer",
    },
  };
});

export const Counter = styled("div")(({ theme, selected }) => {
  return {
    width: "fit-content",
    padding: "5px",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    top: 0,
    right: "50%",
    transform: "translate(50%, -50%)",
    color: selected ? theme.palette.primary.main : theme.palette.grey[300],
    fontSize: "14px",
  };
});
export const DeleteContainer = styled("div")(({ theme, selected }) => {
  return {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "translate(50%, 30%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: selected ? theme.palette.primary.main : theme.palette.grey[300],
  };
});
