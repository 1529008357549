import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import axios from "axios";
import { rgba } from "polished";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import { SecondaryDialog } from "../../elements/frontend/src/components/SecondaryDialog/SecondaryDialog";

import { Section } from "../../elements/frontend/src/components/Section/Section";
import { Card } from "../../elements/frontend/src/components";
import { useAccounts } from "../../elements/frontend/src/hooks";

import TAwsConfig from "../../awsConfig";
import TConfig from "../../config";
import { StyledTableCell, StyledTableRow } from "./styled.personalTalk";

import { DashbordContext } from "../../views/Private/Home";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import moment from "moment";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";

interface AppointmentDataProps{
    event_url: string;
    invitee_url: string;
    event: any;
    startTimeOriginal: any;
    startTime: string;
    endTime: string;
    year: number;
    month: number;
    day: number;
    date: number;
    status: any;
    cancelUrl: any;
    rescheduleUrl: any;
    rescheduled: any;
    dateString?: string;
  }

const PersonalTalk = () => {
  const { t } = useTranslation(["company/common"]);
  const theme = useTheme();
  const { updateAccount } = useAccounts();

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const dashboardContext = useContext(DashbordContext);
  // const dashboardDispatch = useContext(DashboardDispatch);

  const [appointments, setAppointments] = useState<AppointmentDataProps[]>([]);
  const [fetchCompleted, setFetchCompleted] = useState(false);

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [appointmentUrlToDelete, setAppointmentUrlToDelete] = useState("");
  const [appointmentIndexToDelete, setAppointmentIndexToDelete] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");

  const userEmail = currentUser.user.root ? currentUser.user.email : "";
  const name = `${dashboardContext.contact.first_name} ${dashboardContext.contact.last_name}`;

  const phone_number = TConfig.communication.phone_number;

  // Months from locales
  const months : string[] = t("misc.months.long", {
    returnObjects: true,
  });

  // Days from locales
  const days : string[]= t("misc.days", {
    returnObjects: true,
  });

  // status_level from locales
  const status_level : Record<"active" | "completed" | "pending", string> = t("company/common:personal_talk.status_level", {
    returnObjects: true,
  });

  // Token for Calendly API
  const token = TAwsConfig.integrations.CALENDLY_TOKEN;

  const config = useMemo(() => {
    return {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
  }, [token]);

  const checkDate = (startTime: any) => {
    if (startTime) {
      const active = moment(startTime, "YYYYMMDD, h:mm").fromNow();
      const timeArray = active.split(" ");
      let bad = ["second", "seconds", "minute", "minutes", "hour", "hours", "day"];
      const dayCheck = bad.includes(timeArray[1]);
      if (timeArray[0] === "in") {
        return "active";
      } else if (Number(timeArray[0]) >= 2 && !dayCheck) {
        return "delete";
      } else if (timeArray[2] === "ago") {
        return "expired";
      }
    }
  };

  // in order to fetch events, which status is active
  const fetchData = useCallback(async () => {
    // call to calendly API, to get events with latest data
    const responses = await Promise.all(
      dashboardContext.calendly.appointments?.map((item : any) => {
        return axios.get(item?.event_url, config);
      })
    );

    // to filter events url from Calendly API, which status is active
    const filteredEventUrls = responses
      .map((item) => item.data.resource)
      .filter((item) => item.status === "active" && checkDate(item.start_time) !== "delete")
      .map((item) => item.uri);

    // to filter events from client scope=calendly, by comparing with the latest from Calendly API
    // filteredEvents contains only status=active events
    const filteredEvents =
      dashboardContext.calendly.appointments.filter((item : any) =>
        filteredEventUrls.includes(item?.event_url)
      ) || [];

    // update state with events, which status is active
    setAppointments(filteredEvents);
    setFetchCompleted(true);
  }, [config, dashboardContext.calendly.appointments]);

  useEffect(() => {
    fetchData();
  }, [dispatch, fetchData]);

  // if dashboardContext.calendly.appointments !== appointments state
  // if client deletes any appointment, then need to update client scope=calendly. That's why we need this useEffect.

  useEffect(() => {
    if (!fetchCompleted) return;
    if (JSON.stringify(dashboardContext.calendly.appointments) === JSON.stringify(appointments))
      return;

    if (JSON.stringify(dashboardContext.calendly.appointments) !== JSON.stringify(appointments)) {
      const objToUpdate = {
        metadata: [
          {
            scope: "calendly",
            data: {
              appointments: [...appointments],
            },
          },
        ],
      };
      updateAccount(objToUpdate);
    }
  }, [
    appointments,
    dashboardContext.calendly.appointments,
    dashboardContext,
    dispatch,
    fetchCompleted,
    fetchData,
    updateAccount,
  ]);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const event_url = e.data.payload.event.uri;
      const invitee_url = e.data.payload.invitee.uri;

      dispatch(setCurrentUser({ loading: true }));
      axios.all([axios.get(event_url, config), axios.get(invitee_url, config)]).then(
        axios.spread((event, invitee) => {
          const appointmentData: AppointmentDataProps = {
            event_url,
            invitee_url,
            event: event.data.resource.name,
            startTimeOriginal: event.data.resource.start_time,
            startTime: new Date(event.data.resource.start_time)
              .toLocaleTimeString()
              .split(":")
              .splice(0, 2)
              .join(":"),
            endTime: new Date(event.data.resource.end_time)
              .toLocaleTimeString()
              .split(":")
              .splice(0, 2)
              .join(":"),
            year: new Date(event.data.resource.start_time).getFullYear(),
            month: new Date(event.data.resource.start_time).getMonth(),
            day: new Date(event.data.resource.start_time).getDay(),
            date: new Date(event.data.resource.start_time).getDate(),
            status: event.data.resource.status,
            cancelUrl: invitee.data.resource.cancel_url,
            rescheduleUrl: invitee.data.resource.reschedule_url,
            rescheduled: invitee.data.resource.rescheduled,
          };

          appointmentData.dateString = `${appointmentData.startTime} - ${
            appointmentData.endTime
          }, ${days[appointmentData.day - 1]}, ${months[appointmentData.month]} ${
            appointmentData.date
          }, ${appointmentData.year}`;

          const objToUpdate = {
            metadata: [
              {
                scope: "calendly",
                data: {
                  appointments: [...appointments, appointmentData],
                },
              },
              {
                scope: "state",
                data: {
                  onboard_call_scheduled: true,
                },
              },
            ],
          };

          updateAccount(objToUpdate).then((response) => {
            if (response) {
              setAppointments([...appointments, appointmentData]);
              dispatch(setCurrentUser({ loading: false }));
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          });
        })
      );
    },
  });


  const handleCancelDialogOpen = (url: any, index: any) => {
    setCancelDialogOpen(true);
    setAppointmentUrlToDelete(url);
    setAppointmentIndexToDelete(index);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
    setCancelMessage("");
  };

  async function handleCancel(event_url: string, _deleted_idx: boolean) {
    try {
      dispatch(setCurrentUser({ loading: true }));
      const config = {
        method: "POST",
        url: `${event_url}/cancellation`,
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        data: { reason: cancelMessage ? cancelMessage : "Appointment was canceled" },
      };
      const cancelledAppointments = await axios.request(config);

      if (cancelledAppointments.data) {
        const newAppointments =
          appointments.length > 1
            ? appointments.filter((item : any) => item?.event_url !== event_url)
            : [];
        setAppointments(newAppointments);
        setCancelDialogOpen(false);
        setCancelMessage("");
      }
      dispatch(setCurrentUser({ loading: false }));
    } catch (e) {
      dispatch(setCurrentUser({ loading: false }));
    }
  }

  return (
    <Box sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}>
      <Section title={t("company/common:personal_talk.headline")}>
        <Card>
          <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("company/common:personal_talk.caption")}
            </Typography>

            {appointments?.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{}} size="small" aria-label="appointment list table">
                  <TableHead sx={{ height: "50px" }}>
                    <TableRow>
                      <StyledTableCell>{t("company/common:personal_talk.event")}</StyledTableCell>
                      <StyledTableCell align="left">
                        {t("company/common:personal_talk.date_time")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {t("company/common:personal_talk.status")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {t("company/common:personal_talk.action")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointments.map((row : any, index) => {
                      const dateCheck = checkDate(row?.startTimeOriginal);
                      const onboardingCall =
                        currentUser?.transactions[0]?.state?.stages?.onboarding_call?.completed;
                      const statusLevel =
                        dateCheck === "expired" && onboardingCall
                          ? "completed"
                          : dateCheck === "expired"
                          ? "pending"
                          : "active";

                      return (
                        <StyledTableRow
                          key={index}
                          sx={{ textDecoration: "underline" }}
                          className={dateCheck === "expired" ? "expired" : ""}
                        >
                          <StyledTableCell component="th" scope="row">
                            {row.event}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.dateString}</StyledTableCell>
                          <StyledTableCell align="left">
                            {status_level[statusLevel]}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <IconButton
                              aria-label="delete"
                              disabled={dateCheck === "expired"}
                              onClick={() => handleCancelDialogOpen(row.event_url, index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" sx={{}}>
                {t("company/common:personal_talk.no_appointment")}
              </Typography>
            )}
          </Stack>
          <Stack
            justifyContent="space-between"
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 2, md: 2 }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "33.3%" },
                backgroundColor: rgba(theme.palette.primary.main, 0.8),
                color: theme.palette.common.white,
                transition: "opacity 0.5s",
                padding: "10px",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              <CalendarMonthOutlinedIcon sx={{ fontSize: { xs: 30, md: 40 }, my: 1 }} />
              <Typography variant="body1" sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}>
                {t("company/common:personal_talk.calendly_appointment")}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "33.3%" },
                backgroundColor: rgba(theme.palette.primary.main, 0.8),
                color: theme.palette.common.white,
                transition: "opacity 0.5s",
                padding: "10px",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              <AddIcCallOutlinedIcon sx={{ fontSize: { xs: 30, md: 40 }, my: 1 }} />
              <Typography variant="body1" sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}>
                <Trans
                  i18nKey="company/common:personal_talk.phone_call"
                  values={{ phone_number }}
                />
              </Typography>
            </Box>
            <Box
              component="a"
              href={`mailto:${t("company/common:personal_talk.mail_to")}?subject=${t(
                "company/common:personal_talk.mail_subject"
              )}`}
              rel="noreferrer"
              target="_blank"
              sx={{
                width: { xs: "100%", md: "33.3%" },
                backgroundColor: rgba(theme.palette.primary.main, 0.8),
                color: theme.palette.common.white,
                transition: "opacity 0.5s",
                padding: "10px",
                textAlign: "center",
                borderRadius: "5px",
                "&:hover": {
                  cursor: "pointer",
                  opacity: [0.9, 0.9, 0.8],
                },
              }}
            >
              <ForwardToInboxOutlinedIcon sx={{ fontSize: { xs: 30, md: 40 }, my: 1 }} />
              <Typography variant="body1" sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}>
                {t("company/common:personal_talk.send_email")}
              </Typography>
            </Box>
          </Stack>
        </Card>
      </Section>

      <Section title={""}>
        <InlineWidget
          url={TAwsConfig.integrations.CALENDLY_URL}
          styles={{
            minWidth: "320px",
            width: "100%",
            height: "730px",
          }}
          pageSettings={
            {
              // backgroundColor: theme.palette.common.white,
              // hideEventTypeDetails: false,
              // hideLandingPageDetails: false,
              // primaryColor: theme.palette.primary.main,
              // textColor: theme.palette.common.black,
            }
          }
          prefill={{
            email: userEmail,
            name: name,
          }}
          utm={
            {
              // utmCampaign: "Spring Sale 2019",
              // utmContent: "Shoe and Shirts",
              // utmMedium: "Ad",
              // utmSource: "Facebook",
              // utmTerm: "Spring",
            }
          }
        />
      </Section>
      {/* Secondary Dialog for user delete confirmation */}
      <SecondaryDialog
              type="warning"
              dialogTitle={t("company/common:personal_talk.cancel.headline")}
              contentText={t("company/common:personal_talk.cancel.dialog_text")}
              secondaryDialogOpen={cancelDialogOpen}
              secondaryDialogClose={handleCancelDialogClose}
              eventHandler={() => handleCancel(appointmentUrlToDelete, appointmentIndexToDelete)}
              actionButtonText={t("misc.approve")}
              isMenuVisible= {false} />
    </Box>
  );
};

export default PersonalTalk;
