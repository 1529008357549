import { useTranslation } from "react-i18next";
import { useCustomers, useAccounts, useInvestors } from "../../elements/frontend/src/hooks";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";
import { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { AdvisorMetadata } from "../../elements/frontend/src/Store/api";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";

export function useAllInvestors({ transaction, advisor, setAdvisor }: any) {
  const { t } = useTranslation(["investor/common"]);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const { getInvestors, addInvestor } = useInvestors();
  const { getCustomerById } = useCustomers();
  const { updateAccount } = useAccounts();

  const [investors, setInvestors] = useState<InvestorProps[] | undefined>(undefined);
  const [loadingInvestors, setLoadingInvestors] = useState<boolean>(true);
  const categoriesQuery = useGetCategoriesQuery();
  const categories = categoriesQuery.data;


  useEffect(() => {
    if (investors === undefined && categories?.length && transaction !== undefined && advisor !== undefined) {
      setLoadingInvestors(true);
      const meta = advisor.metadata
      const advisorMeta = meta.find((item: any) => item.scope === "advisor") as AdvisorMetadata
      const whitelist = advisorMeta?.data?.investor_whitelist

      if (whitelist !== undefined) {
        Promise.all(
          whitelist.map(async (investorId: string) => {
            return await getCustomerById(investorId).then((data) => {
              const customer = data.customer;

              const investment = customer.metadata.find(
                (meta: any) => meta.scope === "investment"
              )?.data;

              return {
                id: customer.customer_id,
                name: customer.name,
                email: customer.email,
                ebitda: investment?.ebitda || "",
                invest: parseInt(investment?.invest) || "",
                revenue: investment?.revenue || "",
                categories: investment?.categories || [],
                sub_categories: investment?.sub_categories || [],
                period: investment?.period || [],
                phases: investment?.phases || [],
                types: investment?.types || [],
                stake: investment?.stake || [],
                contribution: investment.contribution || {
                  financial_investor: false,
                  strategic_investor: false,
                  wachstums_investor: false,
                },
              };
            });
          })
        ).then((data: any) => {
          setInvestors(data);
          setLoadingInvestors(false);
        });
      } else {
        setInvestors([])
        setLoadingInvestors(false)
      }
    }
  }, [
    investors,
    categories,
    advisor,
    transaction,
    currentUser.datastore,
    t,
    setInvestors,
    setLoadingInvestors,
    getInvestors,
    getCustomerById,
  ]);

  const addCustomInvestor = (values: any) => {
    if (advisor !== undefined) {

      const newInvestor = {
        name: values.name,
        email: values.email,
        address: {
          line_1: values.line_1,
          line_2: "",
          city: values.city,
          postal: values.postal,
          country: values.country,
        },
        contact: {
          first_name: "",
          last_name: "",
          telephone: "",
          website: values.website,
        },
        metadata: [
          {
            scope: "investment",
            data: {
              categories: values.categories,
              sub_categories: values.sub_categories,
              phases: values.phases,
              stake: values.stake,
              period: values.period,
              types: values.types,
              invest: values.invest,
              revenue: values.revenue,
              ebitda: values.ebitda,
              contribution: values.contribution,
            },
          },
        ],
      };
      const createInvestor = async (newInvestor: any) => {
        const resp: any = await addInvestor(newInvestor);
        if (resp) {
          const investorId = resp.investor.customer_id
          const advisorMeta = advisor?.metadata.find((item: any) => item.scope === "advisor")?.data
          const whiteList = advisorMeta && "investor_whitelist" in advisorMeta ? advisorMeta?.investor_whitelist : []
          const body = {
            metadata: [
              {
                scope: "advisor",
                data: {
                  ...advisorMeta,
                  investor_whitelist: whiteList?.length ? [...whiteList, investorId] : [investorId]
                }
              }
            ]
          }
          const updatedAdvisor = await updateAccount(body)
          // RESET STATE TO FETCH NEW INVESTOR
          if (updatedAdvisor) {
            setAdvisor(undefined)
            setInvestors(undefined)
          }
        }
      }
      createInvestor(newInvestor)
    }

  };

  return { investors, loadingInvestors, addCustomInvestor };
}
