import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import { Section } from "../../elements/frontend/src/components/Section/Section";
import { DetailsCompanyCard } from "../../elements/frontend/src/components/DetailsCompanyCard/DetailsCompanyCard";
import { DetailsContactCard } from "../../elements/frontend/src/components/DetailsContactCard/DetailsContactCard";
import { DetailsCompanyInformationCard } from "../../elements/frontend/src/components/DetailsCompanyInformationCard/DetailsCompanyInfoCard";
import { DetailsMarketAnalysisCard } from "../../elements/frontend/src/components/DetailsMarketAnalysisCard/DetailsMarketAnalysisCard";
import {
  Card,
  LoadingIndicator,
  DocumentsTable,
  DownloadFileCard,
  PrimaryButton,
} from "../../elements/frontend/src/components";

import { useClients, useTransactions, useDocuments } from "../../elements/frontend/src/hooks";
import { getValueFromMetadata } from "../../elements/frontend/src/common";

import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";
import { useGetCategoryTreesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { useParams } from "react-router-dom";
import { DetailsInterestCard } from "../../elements/frontend/src/components/DetailsInterestCard/DetailsInterestCard";
import { AdditionalCompanyInformation } from "../../elements/frontend/src/components/AdditionalCompanyInformation/AdditionalCompanyInformation";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { InvestStructure, Transaction } from "../../elements/frontend/src/Store/api";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SelectChangeEvent, Typography } from "@mui/material";
import {
  initialCustomersData,
  initialProductsData,
} from "../../elements/frontend/src/components/DetailsCompanyInformationCard/DetailsCompanyInformationCard.utils";
import { DashboardDispatch, DashbordContext } from "../../views/Private/Home";
import { DocumentType, TypeName } from "../../elements/frontend/src/Store/api/documents/interfaces";

const Details = () => {
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();
  const params = useParams();
  const transactionId = params.transactionId;

  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const { getClient, updateClient } = useClients();
  const { getTransactionById, updateTransaction } = useTransactions();
  const { getDocumentAdvisor } = useDocuments();

  const dashboardContext: any = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const [initialLoad, setInitialLoad] = useState(true);
  const [detailsTransaction, setDetailsTransaction] = useState<any>();
  const [clientData, setClientData] = useState<any>({});
  const [customerProducts, setCustomerProducts] = useState<any[]>();
  const [customersData, setCustomersData] = useState<any[]>();
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const [loadingDetails, setLoadingDetails] = useState(false);

  const ContentRef = useRef();
  const CompanyRef = useRef();
  const ContactRef = useRef();
  const CompanyInformationRef = useRef();
  const MarketAnalysisRef = useRef<HTMLDivElement | null>(null);
  const DocumentDownloadRef = useRef();
  const DocumentsManagementRef = useRef();
  const AdditionalCompanyInformationRef = useRef();
  const DetailsInterestCardRef = useRef();

  useEffect(() => {
    if (dashboardContext.scrollTo) return;
    window.scrollTo(0, 0);
  }, [dashboardContext.scrollTo]);

  useEffect(() => {
    if (!dashboardContext.scrollTo) return;

    const refs = {
      ContentRef,
      CompanyRef,
      ContactRef,
      CompanyInformationRef,
      MarketAnalysisRef,
      DocumentDownloadRef,
      DocumentsManagementRef,
      AdditionalCompanyInformationRef,
      DetailsInterestCardRef,
    };

    // @ts-ignore
    const top = refs[dashboardContext.scrollTo].current?.offsetTop;
    const SCROLL_THRESHOLD = 135;

    // @ts-ignore
    const contentTop = ContentRef.current?.scrollTop;

    if (contentTop !== undefined && top !== undefined) {
      if (contentTop + SCROLL_THRESHOLD === top) {
        window.scrollBy({
          top: -1 * SCROLL_THRESHOLD,
          behavior: "smooth",
        });
        setTimeout(() => {
          window.scrollTo({
            top: top - SCROLL_THRESHOLD,
            behavior: "smooth",
          });
          return;
        }, 100);
      }
    }
    if (top !== undefined) {
      ContentRef.current &&
        window.scrollTo({
          top: top - SCROLL_THRESHOLD,
          behavior: "smooth",
        });
    }
  }, [dashboardContext.scrollTo]);

  const susaData = useMemo(() => {
    const initialData = {
      scope: "susa",
      data: {
        sales_prev_year: "",
        sales_last_year: "",
        ebitda_prev_year: "",
        ebitda_last_year: "",
        ebit_prev_year: "",
        ebit_last_year: "",
        number_employees_prev_year: "",
        number_employees_last_year: "",
        ownerships: [],
      },
    };

    if (Object.keys(clientData).length > 0) {
      const susaScope = clientData?.metadata?.find((item: any) => item.scope === "susa");

      return susaScope || initialData;
    }

    return initialData;
  }, [clientData]);

  useEffect(() => {
    setLoadingDetails(true);
    getTransactionById(transactionId)
      .then((response) => {
        // to assign the values of period, phase, stake and type fields initially
        let transactionObj = response.transaction;

        getClient(transactionObj.client_id).then((clientResponse) => {
          let clientObj = clientResponse.client;
          const scope_susa = clientResponse.client?.metadata?.find(
            (item: any) => item.scope === "susa"
          );

          const scope_profile = clientResponse.client?.metadata?.find(
            (item: any) => item.scope === "profile"
          );
          const products = scope_profile?.data?.products_data || initialProductsData;
          const customers = scope_profile?.data?.customers_data || initialCustomersData;

          // it will add period, phase, stake and type as investor profile data in transaction invest field.
          if (
            !transactionObj?.invest?.period &&
            !transactionObj?.invest?.phase &&
            !transactionObj?.invest?.stake &&
            !transactionObj?.invest?.type
          ) {
            transactionObj = {
              ...transactionObj,
              invest: {
                ...transactionObj.invest,
                period: "",
                phase: "",
                stake: currentUser?.datastore?.companyStake?.map((item: any) => item.value || ""),
                type: currentUser?.datastore?.investorType?.map((item: any) => item.value || ""),
              },
            };
          }

          if (scope_susa === undefined) {
            clientObj = {
              ...clientObj,
              metadata: [...clientObj.metadata, susaData],
            };
          }
          setClientData(clientObj);
          transactionObj && setDetailsTransaction(transactionObj);
          setCustomerProducts(products);
          setCustomersData(customers);
          setLoadingDetails(false);
          dashboardDispatch({
            type: "UPDATE_DATA",
            payload: {
              detailsContentLoading: false,
            },
          });
        });
      })
      .catch((err) => {
        setLoadingDetails(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subCategoryIds = useMemo(() => {
    const value = getValueFromMetadata("profile", "sub_categories", clientData?.metadata);
    if (!value) {
      return [];
    }
    return value;
  }, [clientData?.metadata]);

  // to update clientData state
  const handleClientDataChange = (
    e: React.ChangeEvent<{ value: string; name?: string | undefined }> | SelectChangeEvent<string>,
    field: string,
    isMetadata?: boolean
  ) => {
    const value = e.target.value;
    const name = e.target.name?.toString() || "";
    setClientData((prevState: any) => {
      if (isMetadata) {
        const isScopeExist = clientData?.metadata?.find((item: any) => item.scope === field);

        if (!isScopeExist) {
          if (!prevState?.metadata) {
            prevState.metadata = [];
          }

          prevState.metadata.push({ scope: field, data: { [name]: value } });
        }

        const metaData = prevState.metadata.map((item: any) => {
          // field is the scope in metadata
          if (item.scope === field) {
            if (field === "susa") {
              // to convert string value with thousandseperator to number value
              const numberValue = value.split(".").join("");
              return {
                ...item,
                data: {
                  ...item.data,
                  [name]: numberValue,
                },
              };
            } else {
              return {
                ...item,
                data: {
                  ...item.data,
                  [name]: value,
                },
              };
            }
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      } else if (field === "name") {
        return {
          ...prevState,
          [field]: value,
        };
      } else {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: value,
          },
        };
      }
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // to update detailsTransaction state
  const handleTransactionDataChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: any
  ) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;

    const isFieldExist = detailsTransaction?.hasOwnProperty(field);

    setDetailsTransaction((prevState: any) => {
      if (!isFieldExist) {
        prevState[field] = {
          [name]: value,
        };
      }
      if (field === "invest") {
        // to convert string value with thousandseperator to number value
        const numberValue = value.split(".").join("");
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: numberValue,
          },
        };
      } else {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: value,
          },
        };
      }
    });

    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // handle change event for trade_registration
  const handleTradeRegistrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let value = e.target.value;

    // if there is no metadata in clientData, then create scope "profile" with categories field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState: { metadata: any[] }) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { [name]: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find(
        (item: any) => item.scope === "profile"
      );
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState: { metadata: any[] }) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { [name]: [] } }],
          };
        });
      }
    }

    // pattern will be HRA-12345 / HRB-12345
    if (value.length === 0) {
      value = "HR";
    }
    if (value.length === 3) {
      if (value.toLowerCase().endsWith("a") || value.toLowerCase().endsWith("b")) {
        value = value.toUpperCase() + "-";
      }
    }

    setClientData((prevState: { metadata: any[] }) => {
      const metaData = prevState?.metadata?.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  const handlePhoneChange = (value: any) => {
    setClientData((prevState: { contact: any }) => {
      return {
        ...prevState,
        contact: {
          ...prevState.contact,
          telephone: value,
        },
      };
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value: string): boolean => {
    try {
      const phone = parsePhoneNumber(value);

      return phone !== undefined && phone.isValid();
    } catch (e) {
      return false;
    }
  };
  const handleCategoriesSelect = (categories: any[], type: string) => {
    const categoryIds = categories.map((category) => category.value);
    // if there is no metadata in clientData, then create scope "profile" with categories field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState: any) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { categories: [], sub_categories: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find(
        (item: { scope: string }) => item.scope === "profile"
      );
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState: { metadata: any }) => {
          return {
            ...prevState,
            metadata: [
              ...prevState.metadata,
              { scope: "profile", data: { categories: [], sub_categories: [] } },
            ],
          };
        });
      }
    }

    // handle change for first Autocomplete , which is "categories"
    if (type === "category") {
      setClientData((prevState: { metadata: any[] }) => {
        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === "profile") {
            return {
              ...item,
              data: {
                ...item.data,
                categories: categoryIds,
                sub_categories: categoryTrees
                  ? removeAllChildSubCategoriesWithoutSelectedRootCategory(
                      categoryIds,
                      subCategoryIds,
                      categoryTrees
                    )
                  : subCategoryIds,
              },
            };
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      });
    } else if (type === "subCategory") {
      setClientData((prevState: { metadata: any[] }) => {
        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === "profile") {
            return {
              ...item,
              data: {
                ...item.data,
                sub_categories: categoryIds,
              },
            };
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      });
    }
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // handle the changes on customers table
  const handleCustomersDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.split(".").join("");
    let newData: any[] = [];

    setCustomersData((prevState) => {
      newData = (prevState || []).map((item: any, ind: any) => {
        if (ind === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });
      return newData;
    }) as unknown as React.SetStateAction<any[]>;

    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }

    // if there is no metadata in clientData, then create scope "profile" with customers_data field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState: any) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { customers_data: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find(
        (item: { scope: string }) => item.scope === "profile"
      );
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState: { metadata: any }) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { customers_data: [] } }],
          };
        });
      }
    }

    setClientData((prevState: { metadata: any[] }) => {
      const metaData = prevState.metadata.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              customers_data: newData,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
  };
  // handle the changes on products table
  const handleProductDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.split(".").join("");
    let newData: any[] | undefined = [];

    setCustomerProducts((prevState) => {
      newData = prevState?.map((item: any, ind: any) => {
        if (ind === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });
      return newData;
    }) as unknown as React.SetStateAction<any[]>;

    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }

    // if there is no metadata in clientData, then create scope "profile" with products_data field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState: any) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { products_data: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find(
        (item: { scope: string }) => item.scope === "profile"
      );
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState: { metadata: any }) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { products_data: [] } }],
          };
        });
      }
    }

    setClientData((prevState: { metadata: { scope: string; data: any }[] }) => {
      const metaData = prevState.metadata.map((item: { scope: string; data: any }) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              products_data: newData,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
  };

  const handleInvestorProfileAutoCompleteSelect = (
    _e: React.ChangeEvent | React.SyntheticEvent<Element, Event>,
    value: { value: string; summary: string }[] | { value: string; summary: string },
    field: string
  ) => {
    if ((value && field === "type") || field === "stake") {
      const newValue = (value as { value: string; summary: string }[]).map((item) => item.value);

      setDetailsTransaction((prevState: Transaction | undefined) => {
        if (prevState) {
          return {
            ...prevState,
            invest: {
              ...prevState?.invest,
              [field]: newValue,
            },
          };
        }
      });
      // to make save button enable
      if (disableSaveButton) {
        setDisableSaveButton(false);
      }
    }

    if ((value && field === "phase") || field === "period") {
      const newValue = (value as { value: string; summary: string }).value;
      setDetailsTransaction((prevState: { invest: any }) => {
        if (!prevState) {
          return prevState;
        }
        return {
          ...prevState,
          invest: {
            ...prevState?.invest,
            [field]: newValue,
          },
        };
      });
      // to make save button enable
      if (disableSaveButton) {
        setDisableSaveButton(false);
      }
    }

    if (field === "structure") {
      const newValue = (value as { value: string; summary: string; cat: InvestStructure }).cat;

      setDetailsTransaction((prevState: { invest: any }) => {
        if (prevState) {
          return {
            ...prevState,
            invest: {
              ...prevState?.invest,
              [field]: newValue,
            },
          };
        }
      });
    }

    setDisableSaveButton(false);
  };

  // to update the client and transaction data in DB
  const handleUpdateClientTransactionData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (clientData.contact.telephone) {
        const phone = parsePhoneNumber(clientData.contact.telephone);
        const isValidPhoneNumber = phone?.isValid();
        const formattedNumber = phone?.formatInternational();

        if (isValidPhoneNumber) {
          setClientData((prevState: { contact: any }) => {
            return {
              ...prevState,
              contact: {
                ...prevState.contact,
                telephone: formattedNumber,
              },
            };
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      // update client data
      const responseClient = await updateClient(clientData.client_id, clientData);

      // update transaction data
      const responseTransaction = await updateTransaction(
        detailsTransaction?.transaction_id,
        detailsTransaction
      );

      if (responseClient || responseTransaction) {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  const [fileToDownload, setFileToDownload] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<DocumentType | null>(null);
  const companyName = currentUser.customer.name;

  


  const typeName: TypeName = {
    avv: "Auftragsverarbeitungsvertrag",
    nda_company: "Vertraulichkeitserklärung",
    contract_company: "Auftragsvereinbarung",
    contract_advisor: "Beratervertrag",
  };

  const handleDownloadDocument = async (type: DocumentType) => {
    setSelectedType(type)
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    const params = {
      type: type,
      customer_id: detailsTransaction?.advisor.customer_id,
      client_id: detailsTransaction?.client_id,
      transaction_id: detailsTransaction?.transaction_id,
    };

    getDocumentAdvisor(params)
      .then((response) => {
        setFileToDownload(response.download_url);

        // update transaction state "avv_received" and "nda_received" after downloading
        const transaction_id = detailsTransaction?.transaction_id;
        let objTransactionData = {};
        if (type === "avv") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  avv_received: true,
                },
              },
            ],
          };
        } else if (type === "nda_company") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  nda_received: true,
                },
              },
            ],
          };
        } else if (type === "contract_company") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  contract_company_received: true,
                },
              },
            ],
          };
        }

        updateTransaction(transaction_id, objTransactionData).then((responseTransaction) => {
          if (responseTransaction) {
            dispatch(
              setCurrentUser({
                loading: false,
              })
            );
          }
        });
      })
      .catch((err) => {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      });
  };

  const handleSusaDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setClientData((prevState: { metadata: any[] }) => {
      const metaData = prevState.metadata.map((item) => {
        if (item.scope === "susa") {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          };
        }
        return item;
      });

      return { ...prevState, metadata: [...metaData] };
    });

    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  const handleOwnershipChange = (ownerships: any) => {
    const eventData = {
      target: {
        name: "ownerships",
        value: ownerships,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleSusaDataChange(eventData);
  };

  

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
        margin: "auto",
      }}
      ref={ContentRef}
    >
      <DetailsCompanyCard
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
        handleTradeRegistrationChange={handleTradeRegistrationChange}
        loading={loadingDetails}
        ref={CompanyRef}
        initial={initialLoad}
        setInitial={setInitialLoad}
      />
      {
        <DetailsContactCard
          transaction={detailsTransaction}
          clientData={clientData}
          handleClientDataChange={handleClientDataChange}
          handlePhoneChange={handlePhoneChange}
          checkPhoneNumber={checkPhoneNumber}
          loading={loadingDetails}
          ref={ContactRef}
        />
      }
      <DetailsCompanyInformationCard
        clientData={clientData}
        productsData={customerProducts}
        customersData={customersData}
        handleClientDataChange={handleClientDataChange}
        handleCategoriesSelect={handleCategoriesSelect}
        handleProductDataChange={handleProductDataChange}
        handleCustomersDataChange={handleCustomersDataChange}
        loading={loadingDetails}
        ref={CompanyInformationRef}
        initial={initialLoad}
        setInitial={setInitialLoad}
      />
      <DetailsInterestCard
        transaction={detailsTransaction}
        handleTransactionDataChange={handleTransactionDataChange}
        handleInvestorProfileAutoCompleteSelect={handleInvestorProfileAutoCompleteSelect}
        loading={loadingDetails}
        ref={DetailsInterestCardRef}
      />
      <DetailsMarketAnalysisCard
        transaction={detailsTransaction}
        handleTransactionDataChange={handleTransactionDataChange}
        loading={loadingDetails}
        ref={MarketAnalysisRef}
      />
      <AdditionalCompanyInformation
        susa={susaData}
        handleSusaDataChange={handleSusaDataChange}
        handleOwnershipChange={handleOwnershipChange}
        ref={AdditionalCompanyInformationRef}
      />
      <Section title={t("company/common:onboarding.documents.headline")}>
        <Card>
          <Stack direction="row" spacing={2} ref={DocumentDownloadRef}>
            <DownloadFileCard
              //type={"avv"}
              type={DocumentType.AVV}
              fileName={t("company/common:onboarding.documents.avv")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              //type={"nda_company"}
              type={DocumentType.NDA_COMPANY}
              fileName={t("company/common:onboarding.documents.nda")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              //type={"contract_company"}
              type={DocumentType.CONTRACT_COMPANY}
              fileName={t("company/common:client_agreement.documents.client_agreement")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              type={DocumentType.CONTRACT_ADVISOR}
              fileName={t("advisor/common:advisor_agreement.documents.advisor_agreement")}
              handleDownloadDocument={handleDownloadDocument}
            />
          </Stack>
          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "70%",
                  maxWidth: "400px",
                  height: "300px",
                },
              },
            }}
            open={fileToDownload !== null}
            onClose={() => {
              setFileToDownload(null);
            }}
          >
            <DialogTitle sx={{ textAlign: "center" }}>
              {selectedType && (
                <>
                  <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
                    {companyName}
                  </Typography>
                  <Typography variant="inherit" style={{ fontSize: 16 }}>
                    {typeName[selectedType]}
                  </Typography>
                </>
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                  fontSize: 20,
                }}
                id="alert-dialog-slide-description"
              >
                {t("company/common:secondary_download_dialog.dialog_content_text")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                // size={isMobile ? 'small' : 'medium'}
                sx={{
                  px: { sx: 2, md: 4 },
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                }}
                onClick={() => {
                  if (fileToDownload) {
                    window.open(fileToDownload, "_blank");
                    setFileToDownload(null);
                  }
                }}
              >
                Hereunterladen
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </Section>
      <Section
        title={t("details_a3.documents_management_card.headline")}
        ref={DocumentsManagementRef}
      >
        <Card>
          {loadingDetails && <LoadingIndicator type={"COMPONENT"} />}
          {!loadingDetails && (
            <DocumentsTable
              //@ts-ignore
              originalFiles={detailsTransaction?.files}
              product={"advisor"}
              page={"details"}
            />
          )}
        </Card>
      </Section>
      <Stack direction="row" justifyContent="flex-end">
        <PrimaryButton
          //disabled={disableSaveButton}
          onClick={(): undefined => {
            handleUpdateClientTransactionData();
            setInitialLoad(false);
            return undefined;
          }}
          title={t("misc.save")}
        />
      </Stack>
    </Box>
  );
};

export default Details;
