import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from "react-table";

import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import { useTransactions } from "../../elements/frontend/src/hooks";

import {
  LoadingIndicator,
  GlobalFilter,
} from "../../elements/frontend/src/components";
import { fuzzyTextFilterFn } from "../../elements/frontend/src/components/TableFilterHelpers/TableFilterHelpers";

import { DashbordContext } from "../../views/Private/Home";
import {
  Wrapper,
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
  StyledSelect,
} from "./styled.tableComp";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { SelectChangeEvent } from "@mui/material";
import { Transaction } from "../../elements/frontend/src/Store/api";
import {
  CustomTableOptions,
  DashboardContextProps,
  DefaultColumnType,
  InitialStateType,
  OverviewAdvisorTableProps,
  TableInstanceProps,
} from "./interface";
import { ProductId } from "../OverviewAdvisor/interface";

export const OverviewAdvisorTable: React.FC<OverviewAdvisorTableProps> = ({
  columns,
  data,
  handleShowStatus = () => {},
  handleShowDetails = (transactionId: string) => {},
  handelShowDueDiligence = (transactionId: string) => {},
  onlyDetails,
  loading = false,
}) => {
  const { t } = useTranslation(["advisor/common"]);

  const { updateTransaction } = useTransactions();

  const dashboardContext = useContext(DashbordContext) as DashboardContextProps;

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const [transactionData, setTransactionData] = useState<any[]>([]);
  const [newRegister, setNewRegister] = useState(false);
  const productId: ProductId = currentUser.customer.subscription.product_id;

  // Update state transactionData with props data
  useEffect(() => {
    if (data) {
      setTransactionData(data);
    }
  }, [data]);

  // Filter out duplicates in transactionData
  const uniqueTransactions = useMemo(() => {
    const seen = new Set();
    return transactionData.filter((item) => {
      const duplicate = seen.has(item.transaction_id);
      seen.add(item.transaction_id);
      return !duplicate;
    });
  }, [transactionData]);

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows: any[], id: string | number, filterValue: any) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: uniqueTransactions,
      initialState: { pageIndex: 0, pageSize: 10 } as InitialStateType,
      defaultColumn: { filter: "text" } as DefaultColumnType,
      filterTypes,
      disableSortBy: true,
    } as CustomTableOptions,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as unknown as TableInstanceProps;

  // Convert advisor user's name to id
  const convertNameToId = (name: unknown) => {
    const advisor = dashboardContext?.advisor_content?.advisorUserOptions?.find(
      (item: { name: unknown }) => item.name === name
    );
    return advisor.user_id;
  };

  // AdvisorUsers select element change event handler
  const handleSelectChangeEvent = (
    e: SelectChangeEvent<unknown>,
    rowIndex: number,
    columnId: any,
    transactionId: any
  ) => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      const value = e.target.value;
      const transaction = transactionData.find(
        (item: { transaction_id: any }) => item.transaction_id === transactionId
      );

      // It is user_id of user
      const id = convertNameToId(value);

      const objectToUpdate = {
        [productId]: {
          ...transaction[productId],
          user_id: id,
        },
      };

      // Update transaction in DB
      updateTransaction(transactionId, objectToUpdate).then((response) => {
        if (response) {
          // Update state transactionData
          setTransactionData((old: any[]) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            })
          );
          dispatch(setCurrentUser({ loading: false }));
        }
      });
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  // To accept transaction offer
  const handleApproveTransactionOffer = (transactionId: string) => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (transactionId) {
        const transaction = transactionData.find(
          (item: Transaction) => item.transaction_id === transactionId
        );
        const userId = currentUser.user.user_id;

        const objectToUpdate = {
          [productId]: {
            ...transaction[productId],
            user_id: userId,
          },
          metadata: [
            {
              scope: "state",
              data: {
                [`${productId}_accepted`]: true,
              },
            },
          ],
        };

        // Update transaction in DB
        updateTransaction(transactionId, objectToUpdate).then((response) => {
          if (response) {
            window.location.reload();
          }
        });
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  // To reject transaction offer
  const handleRejectTransactionOffer = (transactionId: any) => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (transactionId) {
        const objectToUpdate = {
          [productId]: {
            customer_id: "",
            user_id: ""
          },
          metadata: [
            {
              scope: "state",
              data: {
                [`${productId}_matching`]: false,
              },
            },
          ],
        };

        // Update transaction in DB
        updateTransaction(transactionId, objectToUpdate).then((response) => {
          if (response) {
            window.location.reload();
          }
        });
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return (
    <>
      {transactionData && (
        <Wrapper>
          {loading && <LoadingIndicator type={"COMPONENT"} />}
          {!loading && (
            <>
              <Table {...getTableProps()} size="small">
                <TableHead>
                  {headerGroups.map((headerGroup: any) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => {
                        if (
                          (column.id === "assigned_advisor_user" && productId !== "advisor") ||
                          (column.id === "assigned_lawyer_user" && productId !== "lawyer")
                        ) {
                          return "";
                        } else if (column.id === "accept") {
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          return (
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted ? (column.isSortedDesc ? "   🔽" : "   🔼") : ""}
                              </span>
                              {/* Render the columns filter UI */}
                              <div>{column.canFilter ? column.render("Filter") : null}</div>
                            </th>
                          );
                        } else {
                          return (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted ? (column.isSortedDesc ? "   🔽" : "   🔼") : ""}
                              </span>
                              {/* Render the columns filter UI */}
                              <div>{column.canFilter ? column.render("Filter") : null}</div>
                            </th>
                          );
                        }
                      })}
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {t("transaction_tables.columns.action")}
                      </th>
                    </TableRow>
                  ))}
                  <TableRow>
                    <th
                      colSpan={visibleColumns.length + 1}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </th>
                  </TableRow>
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {page.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                      <StyledTableRow {...row.getRowProps()}>
                        {row.cells.map((cell: any, index: React.Key | null | undefined) => {
                          if (
                            (cell.column.id === "assigned_advisor_user" &&
                              productId !== "advisor") ||
                            (cell.column.id === "assigned_lawyer_user" && productId !== "lawyer")
                          ) {
                            return "";
                          } else {
                            if (
                              (cell.column.id === "assigned_advisor_user" &&
                                productId === "advisor") ||
                              (cell.column.id === "assigned_lawyer_user" && productId === "lawyer")
                            ) {
                              if (
                                dashboardContext?.advisor_content?.advisorUserOptions?.length === 1
                              ) {
                                return (
                                  <StyledTableCell {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </StyledTableCell>
                                );
                              }
                              if (
                                dashboardContext?.advisor_content?.advisorUserOptions &&
                                dashboardContext?.advisor_content?.advisorUserOptions?.length > 1
                              ) {
                                return (
                                  <StyledTableCell {...cell.getCellProps()} key={index}>
                                    <StyledSelect
                                      size="small"
                                      value={cell.value || ""}
                                      onChange={(e) =>
                                        handleSelectChangeEvent(
                                          e,
                                          cell.row.index,
                                          cell.column.id,
                                          row.original.transaction_id
                                        )
                                      }
                                      sx={{ minWidth: "100%" }}
                                    >
                                      {dashboardContext?.advisor_content?.advisorUserOptions?.map(
                                        (menuItem: any, ind: React.Key | null | undefined) => (
                                          <MenuItem key={ind} value={menuItem.name}>
                                            {menuItem.name}
                                          </MenuItem>
                                        )
                                      )}
                                    </StyledSelect>
                                  </StyledTableCell>
                                );
                              }
                            } else if (cell.column.id === "accept") {
                              return (
                                <StyledTableCell sx={{ textAlign: "center" }} key={index}>
                                  <Button
                                    variant="contained"
                                    color="success"
                                    startIcon={<DoneIcon fontSize="small" />}
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "5px 10px",
                                    }}
                                    onClick={() =>
                                      handleApproveTransactionOffer(row.original.transaction_id)
                                    }
                                  >
                                    {t("misc.approve")}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="warning"
                                    startIcon={<CancelIcon fontSize="small" />}
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "5px 10px",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() =>
                                      handleRejectTransactionOffer(row.original.transaction_id)
                                    }
                                  >
                                    {t("misc.reject")}
                                  </Button>
                                </StyledTableCell>
                              );
                            }
                            return (
                              <StyledTableCell {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </StyledTableCell>
                            );
                          }
                        })}
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {productId === "lawyer" ? (
                            !newRegister && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handelShowDueDiligence(row?.original?.transaction_id)
                                }
                              >
                                <ManageSearchIcon color="primary" fontSize="small" />
                              </IconButton>
                            )
                          ) : (
                            <>
                              {!onlyDetails && (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setNewRegister(!newRegister);
                                    const transactionId = row.original.transaction_id;
                                    handleShowStatus(transactionId);
                                  }}
                                >
                                  <SummarizeOutlinedIcon color="primary" fontSize="small" />
                                </IconButton>
                              )}
                              {!newRegister && (
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDetails(row?.original?.transaction_id)}
                                >
                                  <ManageSearchIcon color="primary" fontSize="small" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                sx={{ marginTop: "10px" }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    gotoPage(0);
                  }}
                  disabled={!canPreviousPage}
                  sx={{ minWidth: "35px", padding: "5px" }}
                >
                  {"<<"}
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    previousPage();
                  }}
                  disabled={!canPreviousPage}
                  sx={{ minWidth: "35px", padding: "5px" }}
                >
                  {"<"}
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                  }}
                  disabled={!canNextPage}
                  sx={{ minWidth: "35px", padding: "5px" }}
                >
                  {">"}
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    gotoPage(pageCount - 1);
                  }}
                  disabled={!canNextPage}
                  sx={{ minWidth: "35px", padding: "5px" }}
                >
                  {">>"}
                </Button>

                <span style={{ fontSize: "15px" }}>
                  {t("transaction_tables.pagination.page")}
                  <strong>
                    {pageIndex && pageIndex + 1} {t("transaction_tables.pagination.of")}{" "}
                    {pageOptions.length}
                  </strong>
                </span>
                <span style={{ fontSize: "15px" }}>
                  |  {t("transaction_tables.pagination.go_to_page")}
                </span>
                <StyledTextField
                  defaultValue={pageIndex && pageIndex + 1}
                  type="number"
                  onChange={(e) => {
                    e.preventDefault();
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
                  sx={{ width: "70px" }}
                />
                <StyledSelect
                  size="small"
                  value={pageSize}
                  onChange={(e) => {
                    e.preventDefault();
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize, i) => (
                    <MenuItem key={i} value={pageSize}>
                      {t("transaction_tables.pagination.show")}  {pageSize}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Stack>
            </>
          )}
        </Wrapper>
      )}
    </>
  );
}