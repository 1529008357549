import React, { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { Card, LoadingIndicator } from "../";
import { thousandSeparator, getValueFromMetadata } from "../../common";
import { Section } from "../Section/Section";

import {
  EditableSelect,
  TextFieldComponent,
  RevenueFieldComponent,
  SalesFieldComponent,
} from "./helpers";
import { TableWrapper } from "./styled.detailsCompanyInformationCard";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { CategoriesDropdown } from "../CategoriesDropdown/CategoriesDropdown";
import { Client, Customers, Product } from "../../Store/api";
import { CategoryOption, CategoryType } from "../CategoriesDropdown/interfaces";

interface DetailsCompanyInfoCardProps {
  clientData: Client;
  productsData?: Product[];
  customersData?: Customers[];
  handleClientDataChange: (
    event: React.ChangeEvent<{ value: string; name?: string | undefined }>,
    field: string,
    isMetadata?: boolean
  ) => void;
  handleCategoriesSelect: (categories: CategoryOption[], type: CategoryType) => void;
  handleProductDataChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleCustomersDataChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  loading: boolean;
  initial: boolean;
  setInitial: (value: boolean) => void;
}

type LegalFiscalDefine = { text: string; value: string; key: number };

export const DetailsCompanyInformationCard = React.forwardRef(
  (
    {
      clientData,
      productsData,
      customersData,
      handleClientDataChange,
      handleCategoriesSelect,
      handleProductDataChange,
      handleCustomersDataChange,
      loading,
      initial,
      setInitial,
    }: DetailsCompanyInfoCardProps,
    ref: React.ForwardedRef<unknown>
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const currentUser = useAppSelector(({ currentUser }) => currentUser);

    const revenue_options = [
      {
        value: "transactional",
        summary: "Transactional",
      },
      {
        value: "recurring",
        summary: "Recurring",
      },
      {
        value: "project",
        summary: "Project",
      },
    ];

    const categories = useMemo(() => {
      return getValueFromMetadata("profile", "categories", clientData?.metadata);
    }, [clientData?.metadata]);

    const subCategories = useMemo(() => {
      return getValueFromMetadata("profile", "sub_categories", clientData?.metadata);
    }, [clientData?.metadata]);

    return (
      <Section title={t("details_a3.company_information_card.headline")} ref={ref}>
        <Card>
          {loading && <LoadingIndicator type={"COMPONENT"} />}
          {!loading && (
            <>
              <CategoriesDropdown
                withSubcategories
                selectedCategoryIds={!!categories ? categories : []}
                selectedSubCategoryIds={!!subCategories ? subCategories : []}
                handleCategoriesSelect={handleCategoriesSelect}
                initial={initial}
                setInitial={setInitial}
              />
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mt: 2 }}
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="company_information_card_legal_form-label">
                    {t("details_a3.company_information_card.legal_form")}
                  </InputLabel>
                  <Select
                    labelId="legal_form-label"
                    id="company_information_card_legal_form"
                    name="legal_form"
                    value={
                      (currentUser.datastore.legal_form &&
                        getValueFromMetadata("profile", "legal_form", clientData?.metadata)) ||
                      ""
                    }
                    label={t("details_a3.company_information_card.legal_form")}
                    onChange={(e) =>
                      handleClientDataChange(
                        e as ChangeEvent<{ value: string; name?: string | undefined }>,
                        "profile",
                        true
                      )
                    }
                  >
                    {currentUser.datastore.legal_form &&
                      currentUser.datastore.legal_form.map(
                        ({ text, value, key }: LegalFiscalDefine) => (
                          <MenuItem value={value} key={key}>
                            {text}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="company_information_card_fiscal_year-label">
                    {t("details_a3.company_information_card.fiscal_year")}
                  </InputLabel>
                  <Select
                    labelId="fiscal_year-label"
                    id="company_information_card_fiscal_year"
                    name="fiscal_year"
                    value={
                      (currentUser.datastore.fiscal_year &&
                        getValueFromMetadata("profile", "fiscal_year", clientData?.metadata)) ||
                      ""
                    }
                    label={t("details_a3.company_information_card.fiscal_year")}
                    onChange={(e) =>
                      handleClientDataChange(
                        e as ChangeEvent<{ value: string; name?: string | undefined }>,
                        "profile",
                        true
                      )
                    }
                  >
                    {currentUser.datastore.fiscal_year &&
                      currentUser.datastore.fiscal_year.map(
                        ({ value, text, key }: LegalFiscalDefine) => (
                          <MenuItem value={value} key={key}>
                            {text}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="column" sx={{ mt: 3 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.info.dark,
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {t("details_a3.company_information_card.products_list")}
                </Typography>

                <TableWrapper>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}> </th>
                      <th>{t("details_a3.company_information_card.product_name")}</th>
                      <th>{t("details_a3.company_information_card.revenue_type")}</th>
                      <th>{t("details_a3.company_information_card.sales_volume")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsData &&
                      Array(3)
                        .fill(0)
                        .map((_item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <TextFieldComponent
                                  val={{
                                    name: "product_name",
                                    index,
                                    value: productsData[index]?.product_name || "",
                                  }}
                                  handleChange={handleProductDataChange}
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <EditableSelect
                                  val={{
                                    value: productsData[index]?.revenue_type || "",
                                    index,
                                    name: "revenue_type",
                                  }}
                                  options={revenue_options}
                                  handleChange={handleProductDataChange}
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <SalesFieldComponent
                                  val={{
                                    name: "sales_volume",
                                    index,
                                    value:
                                      thousandSeparator(productsData[index]?.sales_volume) || "",
                                  }}
                                  handleChange={handleProductDataChange}
                                />
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </TableWrapper>
              </Stack>

              <Stack direction="column" sx={{ mt: 3 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.info.dark,
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {t("details_a3.company_information_card.customer_list")}
                </Typography>

                <TableWrapper>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}> </th>
                      <th>{t("details_a3.company_information_card.revenue_customer_name")}</th>
                      <th>{t("details_a3.company_information_card.revenue_big_customer")}</th>
                      <th>{t("details_a3.company_information_card.revenue_volume")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customersData &&
                      Array(3)
                        .fill(0)
                        .map((_item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <TextFieldComponent
                                  val={{
                                    name: "customer_name",
                                    index,
                                    value: customersData[index]?.customer_name || "",
                                  }}
                                  handleChange={handleCustomersDataChange}
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <RevenueFieldComponent
                                  val={{
                                    name: "big_volume",
                                    index,
                                    value:
                                      thousandSeparator(customersData[index]?.big_volume) || "",
                                  }}
                                  handleChange={handleCustomersDataChange}
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <SalesFieldComponent
                                  val={{
                                    name: "revenue_volume",
                                    index,
                                    value: customersData[index]?.revenue_volume || "",
                                  }}
                                  handleChange={handleCustomersDataChange}
                                />
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </TableWrapper>
              </Stack>
            </>
          )}
        </Card>
      </Section>
    );
  }
);
