import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

import { Card, LoadingIndicator } from "../";
import { Section } from "../Section/Section";
import { Transaction } from "../../Store/api";

interface DetailsMarketAnalysisCardProps {
  transaction: Transaction;
  handleTransactionDataChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => void;
  loading: boolean;
}

export const DetailsMarketAnalysisCard = React.forwardRef<
  HTMLDivElement,
  DetailsMarketAnalysisCardProps
>(({ transaction, handleTransactionDataChange, loading }, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [active, setActive] = useState({
    uvp: false,
    comp: false,
    econ: false,
    notes: false,
  });

  const handleFocusEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    setActive((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleBlurEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    setActive((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const caretPosition = e.target.selectionStart;
      const bullet = e.shiftKey ? "" : "\u2022 ";
      const newValue =
        e.target.value.slice(0, caretPosition) +
        "\n" +
        bullet +
        e.target.value.slice(caretPosition);
      handleTransactionDataChange(
        { currentTarget: { value: newValue, name: e.target.name } } as React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement
        >,
        "market"
      );
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleTransactionDataChange(e, "market");
  };

  return (
    <Section title={t("details_a3.market_analysis_card.headline")} ref={ref}>
      <Card>
        {loading && <LoadingIndicator type={"COMPONENT"} />}
        {!loading && (
          <>
            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
              <TextField
                label={t("details_a3.market_analysis_card.uvp")}
                fullWidth
                name="uvp"
                id="details_a3.market_analysis_card_uvp"
                multiline
                rows={active.uvp ? 4 : 2}
                sx={{
                  "& label": {
                    color: theme.palette.info.dark,
                  },
                  "& fieldset": {
                    borderColor: theme.palette.grey[300],
                  },
                }}
                value={transaction?.market?.uvp}
                inputProps={{
                  style: { whiteSpace: "pre-wrap" },
                }}
                onKeyDown={handleEnter}
                onChange={handleChange}
                onFocus={handleFocusEvent}
                onBlur={handleBlurEvent}
              />
              <TextField
                label={t("details_a3.market_analysis_card.comp")}
                fullWidth
                name="comp"
                id="details_a3.market_analysis_card_comp"
                multiline
                rows={active.comp ? 4 : 2}
                sx={{
                  "& label": {
                    color: theme.palette.info.dark,
                  },
                  "& fieldset": {
                    borderColor: theme.palette.grey[300],
                  },
                }}
                value={transaction?.market?.comp}
                onKeyDown={handleEnter}
                onChange={handleChange}
                onFocus={handleFocusEvent}
                onBlur={handleBlurEvent}
              />
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ my: 2 }}
            >
              <TextField
                label={t("details_a3.market_analysis_card.econ")}
                fullWidth
                name="econ"
                id="details_a3.market_analysis_card_econ"
                multiline
                rows={active.econ ? 4 : 2}
                sx={{
                  "& label": {
                    color: theme.palette.info.dark,
                  },
                  "& fieldset": {
                    borderColor: theme.palette.grey[300],
                  },
                }}
                value={transaction?.market?.econ}
                onKeyDown={handleEnter}
                onChange={handleChange}
                onFocus={handleFocusEvent}
                onBlur={handleBlurEvent}
              />
            </Stack>
            <Stack>
              <TextField
                label={t("details_a3.market_analysis_card.notes")}
                fullWidth
                name="notes"
                id="details_a3.market_analysis_card_notes"
                multiline
                rows={active.notes ? 6 : 4}
                sx={{
                  "& label": {
                    color: theme.palette.info.dark,
                  },
                  "& fieldset": {
                    borderColor: theme.palette.grey[300],
                  },
                }}
                value={transaction?.market?.notes}
                onKeyDown={handleEnter}
                onChange={handleChange}
                onFocus={handleFocusEvent}
                onBlur={handleBlurEvent}
              />
            </Stack>
          </>
        )}
      </Card>
    </Section>
  );
});
