import { styled } from "@mui/material/styles";

export const StyledForm = styled("form")((props) => {
  const maxHeight = props.className === "vertical" ? "100%" : "484px";
  return {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "end",
    maxHeight: maxHeight,
  };
});

export const MessengerContainer = styled("div")((props) => ({ theme }) => {
  const height = props.className === "vertical" ? "100%" : "150px";
  return {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "8px",
    overflowY: "scroll",
    height: height,
  };
});

export const MessageContainer = styled("div")<{ align: "flex-start" | "flex-end" }>(
  ({ align }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: align,
  })
);

export const MessageBubble = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey.A200,
  width: "50%",
  borderRadius: "8px",
  padding: "8px",
}));

export const MessageHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const MessageBottomContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const Name = styled("div")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
}));

export const Hour = styled("div")(() => ({
  fontSize: "12px",
}));

export const Header = styled("div")(({ theme }) => ({
  textAlign: "left",
  fontWeight: "bold",
  padding: "0 5px 5px 0",
  fontSize: "1rem",
  color: theme.palette.info.dark,
  display: "flex",
}));
