import React, { createContext, useEffect, useMemo, useReducer } from "react";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";

import Content from "../../../containers/TopBar/Content/Content";
import TopBar from "../../../containers/TopBar/Topbar";
import { DashboardLayout } from "../../../libs/Layout";

import { LoadingIndicator } from "../../../elements/frontend/src/components";
import { useUsers } from "../../../elements/frontend/src/hooks";

// Dashboard Context
const initialDashboardData = {
  company_name: "",
  address: {
    street: "",
    postal: "",
    city: "",
    country: "", // DEU
  },
  contact: {
    first_name: "",
    last_name: "",
    telephone: "",
  },
  advisor: {
    categories: [],
    sub_categories: [],
    phases: [],
    region: [],
  },
  profile: {
    foundation: "",
    locations: "",
    consultant: "",
    trans_number: "",
  },
  form_state: {
    initial: true,
    valid: false,
  },
  inital_content: {
    steps: [
      { label: "Onboarding", stepId: "ONBOARDING" },
      { label: "Persönliches Gespräch", stepId: "PERSONAL_TALK" },
    ],
    currentStep: "ONBOARDING",
    lastStep: "ONBOARDING",
    completedSteps: [],
  },
  calendly: {
    appointments: [],
  },
  advisor_content: {
    steps: [
      { label: "Überblick", stepId: "OVERVIEW" },
      { label: "Status", stepId: "STATUS" },
      { label: "Details", stepId: "DETAILS" },
    ],
    currentStep: "OVERVIEW",
    showStatus: false,
    showDetails: false,
    readOnly: false,
    registered: "", // "self", "advisor"
    registeredLoading: true, // for loading effect in StatusProcessFlowTable component
    document: {
      date: "",
      dateText: "",
      file: "",
    },
    susa: {
      date: "",
      dateText: "",
      file: "",
    },
  },
  // registered: "", // "self", "advisor"
  // registeredLoading: true, // for loading effect in StatusProcessFlowTable component

  client_content: {
    steps: [
      { label: "Überblick", stepId: "OVERVIEW" },
      { label: "Status", stepId: "STATUS" },
      { label: "Details", stepId: "DETAILS" },
    ],
    currentStep: "DETAILS",
    showStatus: true,
    showDetails: true,
    readOnly: false,
    registered: "advisor",
    registeredLoading: true, // for loading effect in StatusProcessFlowTable component
    document: {
      date: "",
      dateText: "",
      file: "",
    },
    susa: {
      date: "",
      dateText: "",
      file: "",
    },
  },
  scrollTo: "",
  detailsContentLoading: true,
};

// export type DashboardContextProps = {
//   registered: string
// }

export const DashbordContext = createContext(initialDashboardData);

export const DashboardDispatch = createContext((state) => state);

const reducerDashboard = (state, action) => {
  return { ...state, ...action.payload };
};

const Home = () => {
  // const { t } = useTranslation(["platform/common"]);

  const [dashboardContext, dashboardDispatch] = useReducer(reducerDashboard, initialDashboardData);
  const currentUser = useSelector(({ currentUser }) => currentUser);

  // console.log("currentUser ", currentUser);
  // console.log("dashboardContext ", dashboardContext);

  // const { getTransactionsByAdvisorId } = useTransactions();
  const { getUsers } = useUsers();

  useEffect(() => {
    getUsers().then((response) => {
      const advisorUserOptions = response.users
        .filter((user) => !user.hasOwnProperty("client_id"))
        .map((user) => ({
          name:
            user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.alias,
          user_id: user.user_id,
          root: user.root,
          telephone: user.telephone,
        }));

      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          advisor_content: {
            ...dashboardContext.advisor_content,
            advisorUsers: response.users,
            advisorUserOptions,
          },
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for the data saved in scope "advisor"
  const accountAdvisorData = useMemo(() => {
    if (!currentUser.customer.metadata) return {};
    const scopeAdvisor = currentUser.customer.metadata.find((item) => item.scope === "advisor");
    if (scopeAdvisor) {
      return scopeAdvisor.data;
    }
    return {};
  }, [currentUser.customer.metadata]);

  // for the data saved in scope "profile"
  const accountProfileData = useMemo(() => {
    if (!currentUser.customer.metadata) return {};
    const scopeProfile = currentUser.customer.metadata.find((item) => item.scope === "profile");
    if (scopeProfile) {
      return scopeProfile.data;
    }
    return {};
  }, [currentUser.customer.metadata]);

  // for the data saved in scope "calendly"
  const accountCalendlyData = useMemo(() => {
    if (!currentUser.customer.metadata) return {};
    const scopeCalendly = currentUser.customer.metadata.find((item) => item.scope === "calendly");
    if (scopeCalendly) {
      return scopeCalendly.data;
    }
    return {};
  }, [currentUser.customer.metadata]);

  useEffect(() => {
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        company_name: currentUser.customer?.name,
        customer_id: currentUser.customer?.customer_id,
        contact: {
          first_name: currentUser.customer?.contact.first_name || "",
          last_name: currentUser.customer?.contact.last_name || "",
          telephone: currentUser.customer?.contact.telephone || "",
        },
        address: {
          street: currentUser.customer?.address.line_1 || "",
          postal: currentUser.customer?.address.postal || "",
          city: currentUser.customer?.address.city || "",
          country: currentUser.customer?.address.country || "",
        },
        advisor: accountAdvisorData
          ? {
              categories: accountAdvisorData?.categories || [],
              sub_categories: accountAdvisorData?.sub_categories || [],
              phases: accountAdvisorData?.phases || [
                ...currentUser.datastore?.phases.map((item) => item.value),
              ],
              region: accountAdvisorData?.region || ["DEU"],
            }
          : {},
        profile: accountProfileData
          ? {
              foundation: accountProfileData?.foundation || "",
              locations: accountProfileData?.locations || "",
              consultant: accountProfileData?.consultant || "",
              trans_number: accountProfileData?.trans_number || "",
            }
          : {},
        inital_content:
          accountProfileData && accountCalendlyData
            ? {
                ...dashboardContext.inital_content,
                currentStep: accountProfileData?.currentStep || "ONBOARDING",
                lastStep: accountProfileData?.lastStep || "ONBOARDING",
                completedSteps: accountProfileData?.completedSteps || [],
              }
            : {},
        calendly: accountCalendlyData
          ? {
              appointments: accountCalendlyData.appointments || [],
            }
          : {},
        transactions: currentUser.transactions,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountAdvisorData,
    accountCalendlyData,
    accountProfileData,
    currentUser.customer,
    currentUser.datastore?.phases,
  ]);

  return (
    <>
      <DashboardLayout>
        <DashboardDispatch.Provider value={dashboardDispatch}>
          <DashbordContext.Provider value={dashboardContext}>
            <TopBar />
            <Content />
          </DashbordContext.Provider>
        </DashboardDispatch.Provider>
      </DashboardLayout>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
    </>
  );
};

export default Home;
