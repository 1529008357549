import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    gridArea: "content",
    width: "100%",
    background: "#000428",
    // eslint-disable-next-line no-dupe-keys
    background: "-webkit-linear-gradient(to top, #004e92, #000428)",
    // eslint-disable-next-line no-dupe-keys
    background: "linear-gradient(to top, #004e92, #000428)",
  };
});
