import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { lazy } from "@loadable/component";

import { LoadingIndicator } from "../../elements/frontend/src/components";
import Logout from "./Logout";

import { DocumentViewer } from "../../elements/frontend/src/components/DocumentViewer";
import InvestmentDossierView from "../../elements/frontend/src/components/InvestorTable/InvestmentDossierView";
import { InvestmetTeaserView } from "../../elements/frontend/src/components/InvestorTable/InvestmetTeaserView";

const Home = lazy(() => import("./Home"));

const Private = () => {
  return (
    <Routes>
      <Route exact path="/investment-dossier/:transactionId" element={<InvestmentDossierView />} />
      <Route exact path="/investment-teaser/:transactionId" element={<InvestmetTeaserView />} />
      <Route exact path="/:transactionId/documentViewer/:documentId" element={<DocumentViewer />} />
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        exact
        path="/:transactionId/:step"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="logout"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <Logout />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <Navigate to="/" replace />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Private;
