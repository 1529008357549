import React, { useContext } from "react";

import { Sidebar } from "../../../../elements/frontend/src/components";

import ContentInitial from "./ContentInitial/ContentInitial";
import { Wrapper } from "./styled.initial";
import { DashboardDispatch, DashbordContext } from "../../../../views/Private/Home";

const InitialContent = () => {
  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  // to change the UI according to click event. User can navigate betweeen menu components
  const changeCurrentStep = (stepId: string) => {
    if (stepId) {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          inital_content: {
            ...dashboardContext.inital_content,
            currentStep: stepId,
          },
        },
      });
    }
  };

  return (
    <Wrapper>
      <Sidebar
        contentData={dashboardContext.inital_content}
        changeCurrentStep={changeCurrentStep}
      />
      <ContentInitial />
    </Wrapper>
  );
};

export default InitialContent;
