import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import { lighten } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useClients } from "../../../../elements/frontend/src/hooks";
import { LoadingIndicator } from "../../../../elements/frontend/src/components";
import { DialogComponent } from "../../../../elements/frontend/src/components/DialogComponent/Dialog";
import {
  ContentItem,
  ContentWrapper,
  DeleteContainer,
  Counter,
} from "./styled.clientManagementDialog";
import { Client } from "../../../../elements/frontend/src/Store/api";
import { useAppSelector } from "../../../../elements/frontend/src/Store/hooks/useAppSelector";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

interface ContentProps {
  handleDialogClose: () => void;
  data: any;
  selectedClient: any;
  handleSelectedClient: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, client: Client) => void;
  handleAddClient: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Content = ({ data, selectedClient, handleSelectedClient, handleAddClient }: ContentProps) => {
  const { t } = useTranslation(["advisor/common"]);
  const theme = useTheme();
  const { deleteClient } = useClients();
  const [dialogData, setDialogData] = useState<Client[]>();
  const [modalOpen, setModalOpen] = useState(false);
  // const [deleteMessage, setDeleteMessage] = useState("");

  useEffect(() => {
    if (data) {
      const clients = new Array(6).fill({}).map((_, i) => (data[i] ? { ...data[i] } : {}));
      setDialogData(clients);
    }
  }, [data]);

  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const changeSelectedClient = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    client: Client
  ) => {
    e.stopPropagation();
    handleSelectedClient(e, client);
  };

  const handleDeleteClient = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    deleteClient(id)
      .then(() => {
        // setDeleteMessage(`Client deleted`);
        setModalOpen(true);
        setDialogData((prevState) => prevState?.filter((item) => item.client_id !== id));
      })
      .catch((error: any) => {
        // console.error("Error deleting client:", error);
      });
    e.stopPropagation();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  return (
    <>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <DialogContent
        sx={{
          padding: "0 10px 10px",
          height: "100%",
          maxHeight: "55vh",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 20px",
          },
        }}
      >
        <ContentWrapper>
          {dialogData?.map((client, index, array) => (
            <ContentItem
              key={index}
              //@ts-ignore
              selected={
                client.client_id && client.client_id === selectedClient?.client_id ? true : false
              }
              onClick={client.client_id ? (e) => changeSelectedClient(e, client) : handleAddClient}
            >
              {client.client_id ? (
                <PhotoSizeSelectActualOutlinedIcon
                  sx={{
                    fontSize: "150px",
                    color:
                      client.client_id === selectedClient?.client_id
                        ? lighten(theme.palette.primary.main, 0.6)
                        : theme.palette.grey[300],
                  }}
                />
              ) : (
                <AddOutlinedIcon sx={{ fontSize: "150px", color: theme.palette.grey[300] }} />
              )}
              {client.client_id ? (
                <Typography sx={{ color: "black" }}>{client.name}</Typography> // Set the color to black
              ) : (
                t("advisor/common:content.client_selection.client_management.no_client")
              )}
              {client.client_id && (
                <DeleteContainer
                  //@ts-ignore
                  selected={
                    client.client_id && client.client_id === selectedClient?.client_id
                      ? true
                      : false
                  }
                >
                  <DeleteIcon
                    fontSize="small"
                    onClick={(e) => handleDeleteClient(e, client.client_id)}
                    sx={{ color: "red" }} // Set the color to red
                  />
                </DeleteContainer>
              )}
              {client.client_id && (
                <Counter
                  //@ts-ignore
                  selected={
                    client.client_id && client.client_id === selectedClient?.client_id
                      ? true
                      : false
                  }
                >{`${index + 1} / ${array.length}`}</Counter>
              )}
            </ContentItem>
          ))}
        </ContentWrapper>
      </DialogContent>

      {/* Modal for deletion message */}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Der Mandant wurde gelöscht
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
            onClick={handleCloseModal}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ClientManagementDialogProps {
  dialogOpen: boolean;
  handleDialogClose: () => void;
  clients: any;
  selectedClient: any;
  handleSelectedClient: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, client: Client) => void;
  handleAddClient: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ClientManagementDialog = ({
  dialogOpen,
  handleDialogClose,
  clients,
  selectedClient,
  handleSelectedClient,
  handleAddClient,
}: ClientManagementDialogProps) => {
  const { t } = useTranslation(["advisor/common"]);

  const dialogContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (dialogContentRef.current && !dialogContentRef.current.contains(e.target as Node)) {
        handleDialogClose();
      }
    };

    if (dialogOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dialogOpen, handleDialogClose]);

  return (
    <DialogComponent
      dialogKey={"client-management-dialog"}
      title={t("advisor/common:content.client_selection.client_management.title")}
      content={
        <div ref={dialogContentRef}>
          <Content
            handleDialogClose={handleDialogClose}
            data={clients}
            selectedClient={selectedClient}
            handleSelectedClient={handleSelectedClient}
            handleAddClient={handleAddClient}
          />
        </div>
      }
      dialogOpen={dialogOpen}
      handleDialogClose={handleDialogClose}
      maxWidth={"md"}
    />
  );
};
