import React from "react";
import { useTranslation } from "react-i18next";

import { TableWrapper } from "./ContactTable.style";
import { ClientContact } from "../../Store/api";
interface ContactTableProps {
  contactData: ClientContact;
}

export const ContactTable = (props: ContactTableProps) => {
  const { contactData } = props;
  const { t } = useTranslation();

  return (
    <TableWrapper>
      <caption>{t("transaction_tables.company_contact.caption")}</caption>
      <tbody>
        <tr>
          <th>{t("transaction_tables.company_contact.company_contact")}</th>
          <td>
            {contactData?.first_name && contactData?.last_name
              ? `${contactData?.first_name} ${contactData?.last_name}`
              : "-"}
          </td>
        </tr>
        <tr>
          <th>{t("transaction_tables.company_contact.phone")}</th>
          <td>{`${contactData?.telephone || "-"}`}</td>
        </tr>
        <tr>
          <th>{t("transaction_tables.company_contact.email")}</th>
          <td>{`${contactData?.email || "-"}`}</td>
        </tr>
      </tbody>
    </TableWrapper>
  );
};
