import { useState, useEffect } from "react";
import { useAllInvestors } from "./useAllInvestors";
import { useInvitedInvestors } from "./useInvitedInvestors";
import { useParams } from "react-router-dom";
import { useTransactions } from "../../elements/frontend/src/hooks";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { useCustomers } from "../../elements/frontend/src/hooks";
import { Customer } from "../../elements/frontend/src/Store/api";

export default function useInvestorMatching() {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [transaction, setTransaction] = useState<any>(undefined);
  const params = useParams();
  const transactionId = params.transactionId;
  const [expandCategories, setExpandCategories] = useState<string>("");
  const [advisor, setAdvisor] = useState<Customer | undefined>(undefined)
  const { getTransactionById } = useTransactions();
  const { getCustomerById } = useCustomers()

  const customerId = currentUser.user.customer_id || undefined

  useEffect(() => {
    const resolveAdvisor = async (id: string) => {
      const response = await getCustomerById(id)
      setAdvisor(response.customer)
    }
    if (customerId && advisor === undefined) {
      resolveAdvisor(customerId)
    }
  }, [customerId, advisor, setAdvisor, getCustomerById])

  useEffect(() => {
    if (transaction === undefined) {
      const fetchTransaction = async () => {
        const trans = await getTransactionById(transactionId);
        setTransaction(trans.transaction);
      };
      fetchTransaction();
    }
  }, [transactionId, getTransactionById, transaction, setTransaction]);

  const { investors, loadingInvestors, addCustomInvestor } = useAllInvestors({
    transaction,
    advisor,
    setAdvisor,
  });

  const { invitations, loadingInvitations, isInvited, isWhiteListed, inviteInvestor, unInviteInveestor } =
    useInvitedInvestors({
      transactionId,
      transaction,
      advisor
    });

  return {
    inviteInvestor,
    investors,
    loadingInvestors,
    setExpandCategories,
    invitations,
    loadingInvitations,
    expandCategories,
    isInvited,
    isWhiteListed,
    addCustomInvestor,
    unInviteInveestor,
  }
}
