import { useEffect, useState } from "react";
import { useTransactions } from "../../elements/frontend/src/hooks";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";

export function useInvitedInvestors({ transactionId, transaction, advisor }: any) {
  const { updateTransaction } = useTransactions();
  const [invitations, setInvtations] = useState<InvestorProps[]>([]);
  useEffect(() => {
    if (invitations.length === 0) return;
    const saveInvestors = async () => {
      const body = {
        investors: [] /* invitations.map(inv => ({
          customer_id: inv.id,
        })) */,
        metadata: [
          {
            scope: "investment",
            data: {
              investors: invitations,
            },
          },
        ],
      };
      await updateTransaction(transactionId, body);
    };
    saveInvestors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations, transactionId]);

  useEffect(() => {
    const savedInvitations = transaction?.metadata.find(
      (metadata: any) => metadata.scope === "investment"
    )?.data?.investors;
    if (savedInvitations) {
      setInvtations(savedInvitations);
    }
  }, [transaction]);

  const isInvited = (id: string) => {
    if (Array.isArray(invitations)) {
      const exists = invitations.filter((inv: InvestorProps) => inv.id === id);
      return exists.length > 0;
    }
  };

  const inviteInvestor = (investor: InvestorProps) => {
    if (isInvited(investor.id)) return;
    setInvtations((prev) => {
      return [...prev, investor];
    });
  };

  const isWhiteListed = (id: string) => {
    if (advisor) {
      const advisorMeta = advisor.metadata.find((item: any) => item.scope === "advisor").data;
      const whitelist = advisorMeta.investor_whitelist || [];
      return whitelist.includes(id);
    }
  };

  const unInviteInveestor = (investor: InvestorProps) => {
    if (advisor) {
      if (isWhiteListed(investor.id)) {
        const confirm = window.confirm(`Möchten sie ${investor.name} wirklich ausladen?`);
        if (confirm === true) {
          setInvtations((prev) => {
            return prev.filter((inv) => inv.id !== investor.id);
          });
        }
      }
    }
  };

  return {
    inviteInvestor,
    invitations,
    loadingInvitations: false,
    isInvited,
    isWhiteListed,
    unInviteInveestor,
  };
}
