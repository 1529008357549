import { Card, Section, Phone} from "../../../elements/frontend/src/components";
//import { Phone } from "../../../elements/frontend/src/components/Phone";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "../interfaces";
interface ContactProps {
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>, mainField: string) => void;
  //handlePhoneChange: (value: any) => void;
  dashboardContext: any;
}

export const Contact: React.FC<ContactProps> = ({
  handleDataChange,
  //handlePhoneChange,
  dashboardContext,
}) => {
  const { t } = useTranslation(["advisor/common"]);
  const { control /* , watch */ } = useFormContext<OnboardingFormValues>();
  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("advisor/common:content.contact.headline")}>
        <Card>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <Controller
              name="first_name"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <TextField
                  name="first_name"
                  label={t("advisor/common:content.contact.first_name")}
                  value={dashboardContext.contact.first_name || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) =>
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "contact")
                  }
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-firstname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />

            <Controller
              name="last_name"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <TextField
                  name="last_name"
                  label={t("advisor/common:content.contact.last_name")}
                  value={dashboardContext.contact.last_name || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) =>
                    handleDataChange(e as React.ChangeEvent<HTMLInputElement>, "contact")
                  }
                  error={!!error}
                  helperText={!!error && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-lastname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 2, mb: 3 }}>
            <Controller
              name="telephone"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <Phone
                  //telephone={dashboardContext.contact.telephone || "+49"}
                  telephone={value}
                  label={t("advisor/common:content.contact.phone")}
                  phoneChange={onChange}
                  //phoneChange={handlePhoneChange}
                  mobile={true}
                  error={!!error?.message}
                  errorText={error?.message ? error?.message : ""}
                  registering={false}
                  idLabel={"contact"}
                />
              )}
            />
          </Stack>
        </Card>
      </Section>
    </>
  );
};
