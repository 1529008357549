import { styled } from "@mui/material/styles";
import { rgba } from "polished";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    gridArea: "topbar",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: `5px 5px 10px ${rgba(theme.palette.primary.main, 0.15)}`,
    padding: "20px 10px",
    [theme.breakpoints.up("md")]: {
      padding: "20px",
    },
    background: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 10000,
  };
});
