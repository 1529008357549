import React, { useEffect, useState, useCallback, useRef } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useTheme } from "@mui/material/styles";

import { FadeIn } from "../../../elements/frontend/src/components";
import { useAdvisors } from "../../../elements/frontend/src/hooks";

// import { DashboardDispatch } from "../../../views/Private/Home";
import { Wrapper, ListWrapper, List, ListItem } from "./styled.contentSelect";
import { ClientManagementDialog } from "./ClientManagementDialog/ClientManagementDialog";
import { CreateNewClientDialog } from "./CreateNewClientDialog/CreateNewClientDialog";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { Client } from "../../../elements/frontend/src/Store/api";

const ContentSelect = () => {
  const { t } = useTranslation(["advisor/common"]);
  const theme = useTheme();
  const { getAllClientsAdvisor } = useAdvisors();
  const navigate = useNavigate();

  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const params = useParams();
  const { transactionId } = params;
  const hasParams = Object.keys(params).length > 0;

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const [clients, setClients] = useState<Client[]>([]);
  const [isClientSelectDialogOpen, setIsClientSelectDialogOpen] = useState(false);
  const [isCreateNewClientDialogOpen, setIsCreateNewClientDialogOpen] = useState(false);
  const [openClientSelection, setOpenClientSelection] = useState(false);
  const [selectedClient, setSelectedClient] = useState<boolean|Client>();

  // const dashboardDispatch = useContext(DashboardDispatch);

  const menuRef = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    if (isMenuVisible) {
      let handler = (e: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
          setIsMenuVisible(false);
        }
      };
      document.addEventListener("mousedown", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  }, [isMenuVisible]);

  useEffect(() => {
    getAllClientsAdvisor().then((response) => {
      setClients(response.clients);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //to open ClientManagementDialog
  const handleClientSelectDialogOpen = (e: any) => {
    e.stopPropagation();
    setIsClientSelectDialogOpen(true);
  };
  //to close ClientManagementDialog
  const handleClientSelectDialogClose = () => {
    setIsClientSelectDialogOpen(false);
    setIsMenuVisible(false);
  };

  //to open CreateNewClientDialog
  const handleCreateNewClientDialogOpen = (e: any) => {
    e.stopPropagation();
    setOpenClientSelection(false);
    setIsClientSelectDialogOpen(false);
    setIsCreateNewClientDialogOpen(true);
  };
  //to close CreateNewClientDialog
  const handleCreateNewClientDialogClose = () => {
    setIsCreateNewClientDialogOpen(false);
  };

  // to open clients dropdown menu
  const handleOpenClientMenuDropdown = (e: any) => {
    e.stopPropagation();
    setOpenClientSelection(!openClientSelection);
    setIsMenuVisible(!isMenuVisible);
  };

  // to select client from list
  const handleSelectedClient = useCallback(
    (e: any, client: Client ) => {
      if (e) {
        e.stopPropagation();
      }

      const { transaction_id } = currentUser?.transactions?.find(
        (item: { client_id: string }) => item.client_id === client.client_id
      );

      setSelectedClient(client);
      setIsClientSelectDialogOpen(false);
      setOpenClientSelection(false);
      e && navigate(`/${transaction_id}/status`);
    },
    [currentUser.transactions, navigate]
  );

  useEffect(() => {
    if (hasParams) {
      const transaction = currentUser.transactions.filter(
        (t: { transaction_id: string | undefined; }) => t.transaction_id === transactionId
      )[0];
      const client_id = transaction ? transaction.client_id : false;
      const client = currentUser.clients.filter((c: { client_id: string; }) => c.client_id === client_id)[0];

      if (client) {
        handleSelectedClient(false, client);
      }
    } else {
      setSelectedClient(false);
    }
  }, [
    params,
    currentUser.clients,
    currentUser.transactions,
    hasParams,
    selectedClient,
    transactionId,
    handleSelectedClient,
  ]);

  return (
    <Wrapper ref={menuRef}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClientSelectDialogOpen}
        sx={{ fontWeight: "bold" }}
      >
        {selectedClient
          ? (selectedClient as Client)?.name
          : `   ${t("advisor/common:content.client_selection.clients")}         `}
        <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 1 }} />
        <span
          onClick={handleOpenClientMenuDropdown}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px",
            height: "20px",
          }}
        >
          <KeyboardArrowDownIcon />
        </span>
      </Button>
      {openClientSelection && isMenuVisible && (
        <FadeIn>
          <ListWrapper>
            <List>
              <ListItem onClick={handleCreateNewClientDialogOpen}>{`+ ${t(
                "advisor/common:content.client_selection.create_client.text"
              )}`}</ListItem>
              {clients?.map((client: Client, index:number) => (
                <ListItem key={index} onClick={(e) => handleSelectedClient(e, client)}>
                  {client?.name}
                  {(selectedClient as Client).client_id === client.client_id && (
                    <DoneOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                  )}
                </ListItem>
              ))}
            </List>
          </ListWrapper>
        </FadeIn>
      )}
      {/* Dialog for Client Management */}
      <ClientManagementDialog
        dialogOpen={isClientSelectDialogOpen}
        handleDialogClose={handleClientSelectDialogClose}
        clients={clients}
        selectedClient={selectedClient}
        handleSelectedClient={handleSelectedClient}
        handleAddClient={handleCreateNewClientDialogOpen}
      />
      {/* Dialog for new Client creation */}
      <CreateNewClientDialog
        dialogOpen={isCreateNewClientDialogOpen}
        handleDialogClose={handleCreateNewClientDialogClose}
      />
    </Wrapper>
  );
};

export default ContentSelect;
