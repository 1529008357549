import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { useMediaQuery } from "@mui/material";
import TConfig from "../../../../../config";
import { isValidLengthString } from "../../common";
import { Phone } from "../Phone/Phone";
import { DialogComponent, LoadingIndicator, Card } from "../";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useUsers } from "../../hooks";
import { Section } from "../Section/Section";

interface ContentProps {
  handleDialogClose: () => void;
}

const Content = ({ handleDialogClose }: ContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // user roles are only in company app
  const isCompany = TConfig.defaults.PRODUCT_ID === "company";
  const dispatch = useAppDispatch();

  const { updateUser } = useUsers();

  const currentUser: any = useAppSelector(({ currentUser }) => currentUser);

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  // state for user_profile

  const [userProfile, setUserProfile] = useState({
    first_name: currentUser?.user?.first_name || "",
    last_name: currentUser?.user?.last_name || "",
    alias: currentUser?.user?.alias || "",
    telephone: currentUser?.user?.telephone || "+49",
    role:
      currentUser?.user?.metadata?.find((item: any) => item.scope === "profile")?.data?.role || "",
    free_role_text:
      currentUser?.user?.metadata?.find((item: any) => item.scope === "profile")?.data
        ?.free_role_text || "", // if the role selection is "Other"
    form_state: {
      initial: true,
      valid: false,
    },
  });

  // rolesObject to assign text from locales
  const rolesObject = t("top_bar.user_profile_dialog.user_roles", {
    returnObjects: true,
  });

  // to check whether input fields are valid or not
  const isValid = (name: string, initial = true) => {
    if (initial && userProfile.form_state.initial) return true;
    switch (name) {
      case "first_name":
      case "last_name":
      case "alias":
      case "role":
      case "free_role_text":
        return isValidLengthString(userProfile[name], 1);
      case "telephone":
        return isValidLengthString(userProfile[name], 5);
      default:
        return false;
    }
  };

  // handle change event in input fields
  const handleDataChange = (e: { target: { name: any; value: any } }) => {
    const field = e.target.name;
    const value = e.target.value;

    setUserProfile({
      ...userProfile,
      [field]: value,
    });
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value: string, initial = true) => {
    if (initial && userProfile.form_state.initial) return true;
    const phone = parsePhoneNumber(value);
    return phone?.isValid();
  };

  // handle change event telephone
  const handlePhoneChange = (value: any) => {
    setUserProfile({
      ...userProfile,
      telephone: value,
    });
  };

  // to update user data
  const handleUpdateUser = async () => {
    try {
      // run loading indicator
      dispatch(setCurrentUser({ loading: true }));

      if (userProfile.form_state.initial) {
        setUserProfile({
          ...userProfile,
          form_state: {
            ...userProfile.form_state,
            initial: false,
          },
        });
      }

      let allFieldsAreValid = false;

      const allCommonFieldsAreValid =
        isValid("first_name", false) &&
        isValid("last_name", false) &&
        isValid("alias", false) &&
        isValid("telephone", false);

      if (!allCommonFieldsAreValid) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      // if the product_id is company
      if (isCompany) {
        // check the validation of "role"
        allFieldsAreValid = allCommonFieldsAreValid && isValid("role", false);

        // if the role is except "other", then check validation
        if (!allFieldsAreValid) {
          dispatch(setCurrentUser({ loading: false }));
          return;
        }

        // if the role is "other", then we need another validation of "free_role_text"
        if (allFieldsAreValid && userProfile.role === "other") {
          allFieldsAreValid =
            allCommonFieldsAreValid && isValid("role", false) && isValid("free_role_text", false);

          if (!allFieldsAreValid) {
            dispatch(setCurrentUser({ loading: false }));
            return;
          }
        }
      } else {
        allFieldsAreValid = allCommonFieldsAreValid;
      }

      if (allFieldsAreValid !== userProfile.form_state.valid) {
        setUserProfile({
          ...userProfile,
          form_state: {
            initial: false,
            valid: allFieldsAreValid,
          },
        });
      }

      if (userProfile.telephone) {
        const phone = parsePhoneNumber(userProfile.telephone);
        const isValidPhoneNumber = phone?.isValid();
        const formattedNumber = phone?.formatInternational();

        if (isValidPhoneNumber) {
          setUserProfile({
            ...userProfile,
            telephone: formattedNumber as string,
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      // if product_id is "company", then we need to add user role in metadata scope="roles"
      let objToUpdate;

      if (isCompany) {
        objToUpdate = {
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
          alias: userProfile.alias,
          telephone: userProfile.telephone,
          metadata: [
            {
              scope: "profile",
              data: {
                role: userProfile.role,
                free_role_text: userProfile.role === "other" ? userProfile.free_role_text : "",
              },
            },
          ],
        };
      } else {
        objToUpdate = {
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
          alias: userProfile.alias,
          telephone: userProfile.telephone,
        };
      }

      const response = await updateUser(currentUser?.user?.user_id, objToUpdate);

      if (response) {
        window.location.reload();
      }
    } catch (e) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return (
    <>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <DialogContent
        sx={{
          padding: "0 10px 10px",
          height: "100%",
          maxHeight: "55vh",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 20px",
          },
        }}
      >
        <Box>
          <Section title={""} headlineColor={"dark"} isDialog={true}>
            <Card>
              {isCompany && (
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="ownership">
                      {t("top_bar.user_management_dialog.table.role")}
                    </InputLabel>
                    <Select
                      labelId="role"
                      id="user_management_dialog_role"
                      name="role"
                      value={userProfile.role || ""}
                      label={t("top_bar.user_management_dialog.table.role")}
                      onChange={handleDataChange}
                      error={!isValid("role")}
                    >
                      {currentUser?.datastore?.userRoles?.map(
                        (option: any, i: React.Key | null | undefined) => (
                          <MenuItem key={i} value={option.value}>
                            {(rolesObject as any)[option.value]}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {!isValid("role") && (
                      <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                    )}
                  </FormControl>

                  {userProfile.role && userProfile.role === "other" && (
                    <TextField
                      label={t("top_bar.user_profile_dialog.contact.other_role")}
                      name="free_role_text"
                      id="user_management_dialog_free_role_text"
                      value={userProfile.free_role_text || ""}
                      size="small"
                      required
                      fullWidth
                      onChange={handleDataChange}
                      error={!isValid("free_role_text")}
                      helperText={!isValid("free_role_text") && t("misc.required_field")}
                      inputProps={{
                        autoComplete: "new-free_role_text",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                </Stack>
              )}
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mt: 2 }}
              >
                <TextField
                  label={t("top_bar.user_profile_dialog.contact.first_name")}
                  name="first_name"
                  id="user_management_dialog_first_name"
                  value={userProfile.first_name || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={handleDataChange}
                  error={!isValid("first_name")}
                  helperText={!isValid("first_name") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-firstname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("top_bar.user_profile_dialog.contact.last_name")}
                  name="last_name"
                  id="user_management_dialog_last_name"
                  value={userProfile.last_name || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={handleDataChange}
                  error={!isValid("last_name")}
                  helperText={!isValid("last_name") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-lastname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mt: 2 }}
              >
                <TextField
                  label={t("top_bar.user_profile_dialog.contact.alias")}
                  name="alias"
                  id="user_management_dialog_alias"
                  value={userProfile.alias || ""}
                  size="small"
                  required
                  fullWidth
                  onChange={handleDataChange}
                  error={!isValid("alias")}
                  helperText={!isValid("alias") && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-alias",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <Phone
                  telephone={userProfile.telephone || "+49"}
                  label={t("top_bar.user_profile_dialog.contact.phone")}
                  phoneChange={handlePhoneChange}
                  mobile={true}
                  error={!isValid("telephone") || !checkPhoneNumber(userProfile.telephone)}
                  errorText={
                    !isValid("telephone")
                      ? t("misc.required_field")
                      : !checkPhoneNumber(userProfile.telephone)
                      ? t("error.invalid_phoneNumber")
                      : ""
                  }
                  registering={false}
                  idLabel={"user_profile_dialog"}
                />
              </Stack>
            </Card>
          </Section>
        </Box>
      </DialogContent>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="flex-end"
        alignItems={isMobile ? "flex-end" : "center"}
        spacing={2}
        sx={{
          width: "100%",
          padding: "0 10px 10px",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 24px",
          },
        }}
      >
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size={isMobile ? "small" : "medium"}
            sx={{
              mr: { sx: 1, md: 3 },
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
            onClick={handleDialogClose}
          >
            {t("misc.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
            onClick={handleUpdateUser}
          >
            {t("misc.save")}
          </Button>
        </DialogActions>
      </Stack>
    </>
  );
};

interface UserProfileProps {
  dialogOpen: boolean;
  handleDialogClose: () => void;
}

export const UserProfileDialog = ({ dialogOpen, handleDialogClose }: UserProfileProps) => {
  const { t } = useTranslation();

  return (
    <DialogComponent
      dialogKey={"user-profile-dialog"}
      title={t("top_bar.user_profile_dialog.headline")}
      content={<Content handleDialogClose={handleDialogClose} />}
      dialogOpen={dialogOpen}
      handleDialogClose={handleDialogClose}
      maxWidth={"md"}
    />
  );
};
