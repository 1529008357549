import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/system/Stack";
//import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { StatusProcessFlowTable } from "../../elements/frontend/src/components/StatusProcessFlowTable/StatusProcessFlowTable";

import { Section } from "../../elements/frontend/src/components/Section/Section";

import {
  Card,
  LoadingIndicator,
  //StatusDueDiligenceTable,
  DocumentsTable,
  StatusBaseInfoTable,
} from "../../elements/frontend/src/components";

import { DashbordContext } from "../../views/Private/Home";
//import { TableWrapper } from "./styled.status";
import TConfig from "../../config";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { useParams } from "react-router-dom";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";
//import { SupervisorNotices } from "../../elements/frontend/src/components/SupervisorNotices/SupervisorNotices";
import { Messenger } from "../../elements/frontend/src/components/Messenger/Messenger";
import { ContactTable } from "../../elements/frontend/src/components/ContactTable/ContactTable";
import { Transaction } from "../../elements/frontend/src/Store/api";

const Status = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();
  const params = useParams();
  const transactionId: string | undefined = params.transactionId;
  const dashboardContext: any = useContext(DashbordContext);

  const transactionByIdQuery = useGetTransactionByIdQuery({
    transactionId: transactionId as string,
  });
  const transaction: any = transactionByIdQuery?.data;
  const isTransactionLoading = transactionByIdQuery.isLoading;

  const [updateTransaction] = useUpdateTransactionMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateTransactionState = async (newTransaction: Transaction) => {
    await updateTransaction({
      transactionId: newTransaction.transaction_id,
      body: newTransaction,
    });
    dispatch(setCurrentUser({ loading: false }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
        margin: "auto",
      }}
    >
      <Section title={t("platform/common:content.status.headline")}>
        <Card>
          {isTransactionLoading && <LoadingIndicator type={"COMPONENT"} />}
          {!isTransactionLoading && (
            <>
              {/* 1st Box */}
              <Box
                sx={{
                  boxShadow: `2px 2px 4px ${theme.palette.grey[300]}`,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "5px",
                  padding: "15px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    variant="h5"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "1rem", md: "1.3rem" },
                      color: theme.palette.info.dark,
                    }}
                  >
                    {transaction?.client_name}
                  </Typography>
                  <Button
                    variant="contained"
                    color="success"
                    href={
                      TConfig.shared_links.PIPEDRIVE_URL +
                      transaction?.client_contact?.pipedrive_deal_id
                    }
                    target="_blank"
                    rel="noreferrer"
                    disabled={!transaction?.client_contact?.pipedrive_deal_id}
                    endIcon={<SendIcon fontSize="small" />}
                    sx={{ fontWeight: "bold", fontSize: "13px", padding: "5px 10px" }}
                  >
                    {t("transaction_tables.pipedrive")}
                  </Button>
                </Stack>
                <Grid container>
                  <Grid item xs={12} lg={6} maxWidth={"70%"}>
                    <Stack
                      sx={{
                        margin: { xs: "0 0 10px 0", lg: "0 10px 0 0" },
                      }}
                    >
                      {/* Company Contact Table */}
                      <ContactTable contactData={transaction?.client_contact} />
                      {/* <ContactTable contactData={transaction?.client_contact || {}} /> */}
                      {/* Due Diligence Table */}
                      <StatusBaseInfoTable
                        transactionId={transaction?.transaction_id}
                        updateTransactionState={updateTransactionState}
                        statusOffer={false}
                        /*  transactionId={transaction?.transaction_id || ''}*/
                      />
                      {/*  <StatusDueDiligenceTable /> */}
                      {/* Stammdaten Table */}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6} maxWidth={"20rem"}>
                    <Stack
                      sx={{ margin: { xs: "0 0 0 0", lg: "0 0 0 10px" }, height: "100%" }}
                      alignItems="stretch"
                    >
                      {/* Process Flow Table and Component */}
                      {transaction !== undefined && (
                        <StatusProcessFlowTable
                          transaction={transaction}
                          updateTransactionState={updateTransactionState}
                          registered={dashboardContext?.registered}
                          registeredLoading={dashboardContext?.registeredLoading}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6} maxWidth={"100%"}>
                  <Stack
                    sx={{ margin: { xs: "0 0 0 0", lg: "0 0 0 10px" }, height: "100%" }}
                    alignItems="stretch"
                  >
                    <Box
                      sx={{
                        marginTop: "20px",
                        flex: 1,
                      }}
                    >
                      {transactionId !== undefined && (
                        <Messenger
                          transactionId={transactionId}
                          productId="advisor"
                          statusOffer={false}
                        />
                      )}
                    </Box>
                  </Stack>
                </Grid>

                {/* Deal Documents Table */}
                <DocumentsTable
                  //@ts-ignore
                  originalFiles={transaction?.files ? [...transaction?.files] : []}
                  product={"advisor"}
                  page={"status"}
                />
              </Box>
            </>
          )}
        </Card>
      </Section>
    </Box>
  );
};

export default Status;
