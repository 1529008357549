import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { rgba } from "polished";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/system/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { LoadingIndicator } from "../";
import { useClients, useTransactions } from "../../hooks";

import { TableWrapper } from "./styled.tableComp";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { ProcessSteps, Steps } from "../../Store/api";
import { StatusProcessFlowTableProps, StepInterface, TreeNode, Node } from "./interface";

export const StatusProcessFlowTable: React.FC<StatusProcessFlowTableProps> = ({
  transaction,
  updateTransactionState,
  registered,
  registeredLoading,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { updateTransaction } = useTransactions();
  const { getClient, updateClient } = useClients();
  const colSpanValue = 2;
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();

  // to expand Treeview items in Treeview Component
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    setExpanded([transaction?.state?.status?.current_stage]);
  }, [transaction?.state?.status?.current_stage]);

  // to manage to expand Treeview
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  // stages for Treeview
  // if stages changes in data_store any way, need to update stagesObject in locales
  const stages = useMemo(() => {
    let stagesArray: any[] = [];
    if (registered === "self") {
      stagesArray = currentUser?.datastore?.transactionTree?.map((item: any) => item.value);
    } else if (registered === "advisor") {
      stagesArray = currentUser?.datastore?.mandantTree?.map((item: any) => item.value);
    }
    return stagesArray;
  }, [currentUser?.datastore?.mandantTree, currentUser?.datastore?.transactionTree, registered]);
 // console.log("stages", stages);
  
  // stagesObject to assign text as heading for Treeview from locales
  const stagesObject: any = t("transaction_tables.process_flow.stages", {
    returnObjects: true,
  });
  // console.log("stagesObject", stagesObject);

  // transaction steps from locales
  const stepsObject: any = t("transaction_tables.process_flow.steps", {
    returnObjects: true,
  });
// console.log("stepsObject", stepsObject);

  const getComplementationDate = (stepName: string) => {
    if (!transaction) return;

    if (stages.includes(stepName)) {
      const stages = transaction.state?.stages as unknown as StepInterface;
      if (stages[stepName]?.completed) {
        const localeDate = new Date(stages[stepName].timestamp).toLocaleDateString();
        return `   -   ${localeDate}`;
      }
      return "";
    }
    const steps = transaction.state?.steps as unknown as StepInterface;
    //console.log("steps", steps);
    if (steps[stepName]?.completed) {
      const localeDate = new Date(steps[stepName].timestamp).toLocaleDateString();
      return `   -   ${localeDate}`;
    }
    return "";
  };

  // in order to create stages and steps tree for Treeview component
  const processTree = useMemo(() => {
    const data = [];
    if (registered === "self") {
      for (const level1 of currentUser.datastore.transactionTree) {
        const obj: TreeNode = {
          id: level1.value,
          name: level1.value,
          children: [],
        };
        for (const level2 of level1.tree ?? []) {
          obj?.children?.push({
            id: level2.value,
            name: level2.value,
          });
        }
        data.push(obj);
      }
    } else if (registered === "advisor") {
      for (const level1 of currentUser.datastore.mandantTree) {
        const obj: TreeNode = {
          id: level1.value,
          name: level1.value,
          children: [],
        };
        for (const level2 of level1.tree ?? []) {
          obj?.children?.push({
            id: level2.value,
            name: level2.value,
          });
        }
        data.push(obj);
      }
    }
    return data;
  }, [currentUser.datastore.mandantTree, currentUser.datastore.transactionTree, registered]);
// console.log("processTree", processTree);

  // to calculate the days from company client registration up to now for Table
  const daysFromLastStep = (last_step_timestamp: number) => {
    const difference = new Date().getTime() - last_step_timestamp;
    const days = Math.round(difference / 86400000);
    return days === 0 || days === 1 ? "1 Tag" : `${days} Tage`; // need to fix this assignment later !!!!!!!!!
  };

  // to handle change event in Treeview checkboxes
  async function handleChange(e: React.ChangeEvent<HTMLInputElement>, field: string) {
    try {
      dispatch(setCurrentUser({ loading: true }));

      const checked = e.target.checked;

      if (field === "transaction_approved" && checked) {
        const client = await getClient(transaction?.client_id);
        const profile = client?.metadata?.find(
          (item: { [key: string]: string }) => item.scope === "profile"
        );

        const updatedClient = {
          metadata: [
            {
              scope: "profile",
              data: {
                dashboard_completedSteps: profile?.data?.dashboard_completedSteps
                  ? [...profile?.data?.dashboard_completedSteps, ProcessSteps.TRANSACTION_CHECK]
                  : ["ONBOARDING", "DATEN_IMPORT", "PERSONAL_TALK", "TRANSACTION_CHECK"],
                dashboard_currentStep: ProcessSteps.CLIENT_AGREEMENT,
                dashboard_lastStep: ProcessSteps.TRANSACTION_CHECK,
              },
            },
          ],
        };

        await updateClient(transaction?.client_id, updatedClient);
      }
      if (field === "contract_company_signed" && checked) {
        const client = await getClient(transaction?.client_id);
        const profile = client?.metadata?.find(
          (item: { [key: string]: string }) => item.scope === "profile"
        );
        const updatedClient = {
          metadata: [
            {
              scope: "profile",
              data: {
                dashboard_completedSteps: profile?.data?.dashboard_completedSteps
                  ? [...profile?.data?.dashboard_completedSteps, ProcessSteps.CLIENT_AGREEMENT]
                  : [
                      "ONBOARDING",
                      "DATEN_IMPORT",
                      "PERSONAL_TALK",
                      "TRANSACTION_CHECK",
                      "CLIENT_AGREEMENT",
                    ],
                dashboard_currentStep: ProcessSteps.DETAILS,
                dashboard_lastStep: ProcessSteps.CLIENT_AGREEMENT,
              },
            },
          ],
        };

        await updateClient(transaction?.client_id, updatedClient);
      } 

      if (field === "lawyer_matching" && checked) {
        const client = await getClient(transaction?.client_id);
        const profile = client?.metadata?.find(
          (item: { [key: string]: string }) => item.scope === "profile"
        );
        const updatedClient = {
          metadata: [
            {
              scope: "profile",
              data: {
                dashboard_completedSteps: profile?.data?.dashboard_completedSteps
                  ? [...profile?.data?.dashboard_completedSteps, ProcessSteps.LAWYER_MATCHING]
                  : [
                      "ONBOARDING",
                      "DATEN_IMPORT",
                      "PERSONAL_TALK",
                      "TRANSACTION_CHECK",
                      "CLIENT_AGREEMENT",
                      "LAWYER_MATCHING",
                    ],
                dashboard_currentStep: ProcessSteps.DUE_DILIGENCE,
                dashboard_lastStep: ProcessSteps.LAWYER_MATCHING,
              },
            },
          ],
        };

        await updateClient(transaction?.client_id, updatedClient);
      }
      
      else if (field === "lawyer_matching" && !checked) {
        //const client = await getClient(transaction?.client_id);
        //const profile = client?.metadata?.find((item) => item.scope === "profile");

        const updatedClient = {
          metadata: [
            {
              scope: "profile",
              data: {
                dashboard_completedSteps: [
                  "ONBOARDING",
                  "DATEN_IMPORT",
                  "PERSONAL_TALK",
                  "TRANSACTION_CHECK",
                  "LAWYER_MATCHING",
                ],
                dashboard_currentStep: ProcessSteps.CLIENT_AGREEMENT,
                dashboard_lastStep: ProcessSteps.LAWYER_MATCHING,
              },
            },
          ],
        };

        await updateClient(transaction?.client_id, updatedClient);
      }
      const objectToUpdate = {
        ...transaction,
        metadata: [
          {
            scope: "state",
            data: {
              [field]: checked,
            },
          },
        ],
      };
      const transactionId = transaction?.transaction_id;

      updateTransaction(transactionId, objectToUpdate).then((response) => {
        if (response) {
          // update transaction in StatusTransaction state
          updateTransactionState(response.transaction);
        }
      });
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  }

  const RenderTreeWithCheckboxes = (nodes: any) => {
    return (
      <TreeItem
        // size="small"
        style={{ fontSize: "small" }}
        key={nodes.id}
        nodeId={nodes.id}
        className={transaction?.state?.status?.current_stage === nodes.id ? "bgGrey" : undefined}
        sx={{
          "& .MuiTreeItem-content:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiTreeItem-content.Mui-selected": {
            backgroundColor: "transparent !important",
          },
          "& .MuiTreeItem-content.Mui-selected:hover": {
            backgroundColor: "transparent !important",
          },
          "&.bgGrey": {
            backgroundColor: rgba(theme.palette.grey[300], 0.4),
          },
        }}
        label={
          <FormControlLabel
            control={
              <Checkbox
                name={nodes.name}
                size="small"
                checked={
                  stages.includes(nodes.name)
                    ? nodes?.children?.every(
                        (item: Node) =>
                          transaction?.state?.steps[item.name as keyof Steps]?.completed === true
                      )
                    : transaction?.state?.steps[nodes.name as keyof Steps]?.completed
                }
                indeterminate={
                  nodes?.children?.every(
                    (item: Node) =>
                      transaction?.state?.steps[item.name as keyof Steps]?.completed === false
                  )
                    ? false
                    : nodes?.children
                        ?.map(
                          (item: Node) =>
                            transaction?.state?.steps[item.name as keyof Steps]?.completed
                        )
                        .some(
                          (item: { completed?: boolean }) =>
                            item?.completed !== true && item?.completed !== undefined
                        )
                }
                onChange={(e) => {
                  if (stages.includes(nodes.name)) return;
                  handleChange(e, nodes.name);
                }}
                className="checkbox"
                sx={{
                  color: theme.palette.primary.main,
                  "&.Mui-checked": {
                    color: theme.palette.primary.main,
                  },
                  "&.MuiCheckbox-root": {
                    padding: "5px",
                  },
                }}
              />
            }
            label={
              stages.includes(nodes.name)
                ? `${stagesObject[nodes.name]}${getComplementationDate(nodes.name)}`
                : `${stepsObject[nodes.name]}${getComplementationDate(nodes.name)}`
            }
            key={nodes.id}
            sx={{
              backgroundColor: rgba(theme.palette.grey[200], 0.5),
              border: `0.5px solid ${rgba(theme.palette.primary.main, 0.1)}`,
              borderRadius: "3px",
              width: "100%",
              margin: "2px 0",
              fontSize: "15px",
              "& .MuiTypography-root": {
                fontSize: "15px",
              },
              "&:hover": {
                backgroundColor: rgba(theme.palette.primary.main, 0.1),
              },
            }}
          />
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node: any) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      {registeredLoading && <LoadingIndicator type={"COMPONENT"} />}
      {!registeredLoading && (
        <>
          <TableWrapper>
            <caption>{t("transaction_tables.process_flow.caption")}</caption>
            <tbody>
              <tr>
                <th style={{ width: "auto" }}>{t("transaction_tables.process_flow.last_step")}</th>
                <td>{stepsObject[transaction?.state?.status?.last_step]}</td>
                <td colSpan={colSpanValue}>
                  {t("transaction_tables.process_flow.not_changed_since")}{" "}
                  {daysFromLastStep(transaction?.state?.status?.last_step_timestamp)}
                </td>
                {/* <td>{daysFromLastStep(transaction?.state?.status?.last_step_timestamp)}</td> */}
              </tr>
              {/* <tr>
                <th>{t("transaction_tables.process_flow.not_changed_since")}</th>
              </tr> */}
            </tbody>
          </TableWrapper>

          <Box
            sx={{
              mt: 2,
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              expanded={expanded}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeToggle={handleToggle}
              sx={{
                maxHeight: "553px",
                width: "100%",
                overflowY: "auto",
              }}
            >
              {processTree.map((nodes) => RenderTreeWithCheckboxes(nodes))}
            </TreeView>
          </Box>
        </>
      )}
    </>
  );
};
