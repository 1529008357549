import Link from "@mui/material/Link";
import { useState } from "react";

import { EditableSelect, TextFieldComponent } from "./helper.js";

import React from "react";
import { CategoriesDropdown } from "../CategoriesDropdown/CategoriesDropdown";

export const BaseInfoTable = ({ value }) => {
  const {
    transaction,
    item,
    isAdvisorClient,
    dashboardContext,
    handleSelectChangeEvent,
    theme,
    selectedCategoryIds,
    selectedSubCategoryIds,
    handleCategoriesSelect,
  } = value;

  const [initialLoad, setInitialLoad] = useState(true);

  const steps = transaction?.state?.steps;
  const advisor_matching = steps?.advisor_matching?.completed;
  const advisor_accepted = steps?.advisor_accepted?.completed;
  const contract_advisor_signed = steps?.contract_advisor_signed?.completed;
  const contract_company_signed = steps?.contract_company_signed?.completed;
  const disableAdvisorAssign =
    (advisor_matching && advisor_accepted && contract_advisor_signed) || !contract_company_signed;

  return (
    <>
      {(dashboardContext.advisorOptions && item.name === "supervisor") ||
      (dashboardContext.advisorOptions && item.name === "advisor") ? (
        isAdvisorClient ? (
          <TextFieldComponent
            val={{
              value: item?.value,
              name: item?.name,
            }}
          />
        ) : (
          <EditableSelect
            val={{
              value: item?.value,
              name: item?.name,
            }}
            options={
              item?.name === "supervisor"
                ? dashboardContext.supervisorOptions
                : dashboardContext.advisorOptions
            }
            disabled={item.name === "advisor" && disableAdvisorAssign}
            handlerChange={handleSelectChangeEvent}
          />
        )
      ) : item?.name === "branch" ? (
        <CategoriesDropdown
          selectedCategoryIds={selectedCategoryIds}
          handleCategoriesSelect={handleCategoriesSelect}
          selectedSubCategoryIds={selectedSubCategoryIds}
          withSubcategories={true}
          initial={initialLoad}
          setInitial={setInitialLoad}
        />
      ) : item?.name === "website" ? (
        <Link
          href={`https://${item.value}`}
          target="_blank"
          sx={{
            color: theme.palette.info.main,
            "&:hover": {
              color: theme.palette.info.dark,
            },
          }}
        >
          {item?.value}
        </Link>
      ) : (
        item?.value
      )}
    </>
  );
};
